import { doorDesignNames, doorDesignExtraNames } from 'consts/consts';
import getDoorElements from 'utils/doors/doors';

export const createSelectedDoorType = (store) => {
  const {
    doorActivePatternDirection, doorPassivePatternDirection,
    doorBlendeHeight,
    selectedBaseGlassPattern,
    doorActiveWallOpeningWidthExtra,
  } = store.canvas;

  const {
    doorActiveWallOpeningWidth,
    doorPassiveWallOpeningWidth,
    doorWallOpeningHeight,
    selectedDoorWingType,
    selectedDoorOpeningDirection,
  } = store.selectedOptions;

  const selectedDoorType = [
    doorDesignNames[store.selectedOptions.selectedDoorDesign],
    selectedDoorWingType !== 'e668fcdb-b4b5-4857-b106-6d500275d6a7' ? '-ketszarnyu' : '',
    doorDesignExtraNames[store.selectedOptions.selectedDoorDesignExtra],
  ].join('');

  const doorSizes = {
    doorActiveWallOpeningWidth,
    doorPassiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
    doorPassivePatternDirection,
    doorActiveWallOpeningWidthExtra,
    doorBlendeHeight,
    selectedBaseGlassPattern,
    selectedOpeningDirection: selectedDoorOpeningDirection,
  };

  return getDoorElements(selectedDoorType, doorSizes);
};
