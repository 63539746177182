import {
  CONFIGURATOR_SELECT_STEP,
} from 'consts/actionTypes';
import initialState from './initialState';

const configuratorReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIGURATOR_SELECT_STEP:
      return {
        ...state,
        currentStep: action.payload.step,
        maxStep: Math.max(action.payload.step, state.maxStep),
      };

    default:
      return state;
  }
};

export default configuratorReducer;
