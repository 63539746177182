import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falbanFutoKetszarnyu = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorPassiveWallOpeningWidth,
    doorWallOpeningHeight,
    selectedOpeningDirection,
  } = state;

  // Aktv ajtó
  const activeDoorWidth = doorActiveWallOpeningWidth - 15;

  // Passzív ajtó
  const passiveDoorWidth = doorPassiveWallOpeningWidth - 2;

  // Ajtólap külméret
  const doorWidth = activeDoorWidth + passiveDoorWidth;

  // Ajtólap külméret | X + 10
  const doorHeight = doorWallOpeningHeight + 10;

  // Ajtólap magassága
  const doorShapeHeight = doorHeight - 7;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret, Y + 125) - (Ajtótok falcméret, Y + 5)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.falElottFuto.frameThickness[0]) - (doorActiveWallOpeningWidth - config.falElottFuto.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 390) - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  const doorLeftPosition = (2400 - doorWidth) / 2;

  // Ajtótok belméret | (Ajtótok falcméret, Y + 5) - (Ajtótok belméret, Y - 22)
  // Az a kicsi izé pöcök a tok és az ajtó között :D
  const doorCaseInnerWidth = (doorActiveWallOpeningWidth + 5) - (doorActiveWallOpeningWidth - 22);

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    width: activeDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.falbanFuto.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness + passiveDoorWidth
      : doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness,
    fill: '#8a6930',
  });

  const doorShapePassive = new fabric.Rect({
    name: 'doorShapePassive',
    doorElement: 'doorShapePassive',
    width: passiveDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.falbanFuto.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness
      : doorLeftPosition + frameThickness + activeDoorWidth,
    top: doorTopPosition + frameThickness,
    fill: '#8a6930',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falbanFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falbanFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falbanFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  // Inner Cases

  const doorCaseInnerTop = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth },
      { x: doorCaseInnerWidth, y: doorWidth - doorCaseInnerWidth },
      { x: doorCaseInnerWidth, y: doorCaseInnerWidth },
    ], {
      name: 'doorCaseInnerTop',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falbanFuto.layerOrders.doorCaseInnerLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness),
      top: doorTopPosition + frameThickness,
      angle: 90,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: 0,
        offsetY: 5,
        blur: 14,
      },
    },
  );

  const doorCaseInnerLeft = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + ((doorCaseInnerWidth / 2) - 20) },
      { x: doorCaseInnerWidth, y: doorHeight + ((doorCaseInnerWidth / 2) - 20) },
      { x: doorCaseInnerWidth, y: doorCaseInnerWidth },
    ], {
      name: 'doorCaseInnerLeft',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falbanFuto.layerOrders.doorCaseInnerLayerIndex,
      left: doorLeftPosition + frameThickness,
      top: doorTopPosition + frameThickness,
      angle: 0,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: 5,
        offsetY: 0,
        blur: 14,
      },
    },
  );

  const doorCaseInnerRight = new fabric.Polygon(
    [
      { x: doorCaseInnerWidth, y: 0 },
      { x: doorCaseInnerWidth, y: doorHeight + ((doorCaseInnerWidth / 2) - 20) },
      { x: 0, y: doorHeight + ((doorCaseInnerWidth / 2) - 20) },
      { x: 0, y: doorCaseInnerWidth },
    ], {
      name: 'doorCaseInnerRight',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falbanFuto.layerOrders.doorCaseInnerLayerIndex,
      left: doorLeftPosition + doorWidth + doorCaseInnerWidth,
      top: doorTopPosition + frameThickness,
      angle: 0,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: -5,
        offsetY: 0,
        blur: 14,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falbanFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falbanFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falbanFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowTop',
    layerIndex: config.falbanFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: doorTopPosition,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const twoDoorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'twoDoorInnerShadowTop',
    layerIndex: 4,
    opacity: 1,
    height: doorHeight,
    width: doorWidth,
    top: doorTopPosition,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorHeight) - 7,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowLeft',
    layerIndex: config.falbanFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: doorTopPosition + frameThickness,
    left: doorLeftPosition,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowRight',
    layerIndex: config.falbanFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: doorTopPosition + frameThickness,
    left: doorLeftPosition + doorWidth + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition,
    width: doorWidth + (frameThickness * 2),
    height: doorHeight + frameThickness,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, doorShapePassive,
    leftFrame, rightFrame, topFrame,
    doorCaseInnerTop, doorCaseInnerLeft, doorCaseInnerRight,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow, twoDoorInnerShadowTop,
  ];
};

export default falbanFutoKetszarnyu;
