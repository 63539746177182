import { fabric } from 'fabric';
import Canvas from './canvas';
import Patterns from './patterns';

if (fabric.isWebglSupported()) {
  fabric.textureSize = fabric.maxTextureSize * 2;
}

export const frontCanvas = new Canvas();
export const patterns = new Patterns();
