import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Radio } from 'antd';
import {
  getConfiguratorComponent,
  selectWallColor,
  selectFloorColor,
  selectLocation,
} from 'actions/canvasActions';
import {
  TAXONOMY_TERM_DOOR_LOCATION,
  TAXONOMY_TERM_AVAILABLE_COLORS,
} from 'consts/consts';
import Spinner from 'components/Spinner/ComponentSpinner';
import TypeSelector from 'components/TypeSelector/TypeSelector';
import ColorPalette from 'components/ColorPalette/ColorPalette';

const WALL = 'wall';
const FLOOR = 'floor';

class Step01 extends Component {
  state = {
    selectedPaintingItem: 'wall',
  };

  componentDidMount() {
    const { dispatchGetConfiguratorInstanceComponent } = this.props;
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_DOOR_LOCATION, '&include=fch_floor_multiply,fch_floor_screen,fch_objects,fch_wall_multiply,fch_wall_screen');
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_AVAILABLE_COLORS, '&include=fch_horizontal_image,fch_vertical_image,fch_preview_image');
  }

  selectLocation = (selectedLocation) => {
    const { dispatchSelectLocation } = this.props;
    dispatchSelectLocation(selectedLocation);
  }

  render() {
    const {
      doorLocationOptions, selectedLocation,
      availableColors, selectedWallColor, selectedFloorColor,
      dispatchSelectWallColor, dispatchSelectFloorColor, t,
    } = this.props;
    const { selectedPaintingItem } = this.state;

    if (!Object.keys(doorLocationOptions).length) {
      return <Spinner />;
    }

    const ralColors = Object.keys(availableColors).length !== 0
      ? Object.keys(availableColors).filter((key) => (availableColors[key].attributes.name.includes('RAL'))).map((key) => (availableColors[key]))
      : null;

    return (
      <div className="steps step-01">
        <TypeSelector
          index={2}
          title={t('stepper.step_1.page_buttons_label.choose_context')}
          choices={Object.keys(doorLocationOptions).map((key) => ({
            value: key,
            label: doorLocationOptions[key].attributes.name,
            iconName: doorLocationOptions[key].attributes.fch_icon_name,
          }))}
          withIcon
          selectedChoice={selectedLocation}
          onChange={this.selectLocation}
        />

        {
          ralColors
          && (
            <div className="painting-chooser-container">
              <Radio.Group
                value={selectedPaintingItem}
                buttonStyle="solid"
                onChange={(e) => {
                  this.setState({
                    selectedPaintingItem: e.target.value,
                  });
                }}
              >
                <Radio.Button value={WALL} style={{ minWidth: 70, textAlign: 'center' }}>{t('stepper.step_1.btn.wall')}</Radio.Button>
                <Radio.Button value={FLOOR} style={{ minWidth: 70, textAlign: 'center' }}>{t('stepper.step_1.btn.floor')}</Radio.Button>
              </Radio.Group>

              <ColorPalette
                changeSelectedColor={(type, value) => {
                  if (selectedPaintingItem === WALL) {
                    dispatchSelectWallColor(value);
                  } else {
                    dispatchSelectFloorColor(value);
                  }
                }}
                colorList={ralColors.map((color) => ({
                  key: color.attributes.name,
                  value: color.id,
                }))}
                selected={selectedPaintingItem === WALL ? selectedWallColor : selectedFloorColor}
                type="wall"
              />
            </div>
          )
        }
      </div>
    );
  }
}

Step01.propTypes = {
  doorLocationOptions: PropTypes.shape({}).isRequired,
  availableColors: PropTypes.shape({}).isRequired,
  selectedLocation: PropTypes.string.isRequired,
  selectedWallColor: PropTypes.string.isRequired,
  selectedFloorColor: PropTypes.string.isRequired,
  dispatchSelectWallColor: PropTypes.func.isRequired,
  dispatchSelectFloorColor: PropTypes.func.isRequired,
  dispatchSelectLocation: PropTypes.func.isRequired,
  dispatchGetConfiguratorInstanceComponent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  doorLocationOptions: store.availableOptions.doorAvailableLocation,
  availableColors: store.availableOptions.doorAvailableColors,
  selectedLocation: store.selectedOptions.selectedLocation,
  selectedWallColor: store.selectedOptions.selectedWallColor,
  selectedFloorColor: store.selectedOptions.selectedFloorColor,
});

const mapDispatchToProps = {
  dispatchSelectWallColor: selectWallColor,
  dispatchSelectFloorColor: selectFloorColor,
  dispatchSelectLocation: selectLocation,
  dispatchGetConfiguratorInstanceComponent: getConfiguratorComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step01));
