import { host, history } from 'services/config';
/* eslint no-underscore-dangle: 0 */

export default {
  setupInterceptors: (api, setHeader) => {
    api.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401) {
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            return api
              .post(`${host}/user/refresh`)
              .then((response) => {
                sessionStorage.setItem('token', response.data.access_token);
                setHeader(response.data.access_token);
                originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
                return api(originalRequest);
              });
          }
          sessionStorage.removeItem('token');
          history.push('/login');
        }
        return Promise.reject(error);
      },
    );
  },
};
