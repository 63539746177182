import React from 'react';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import ROUTES from 'routes';
import './App.scss';

const App = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    <Route exact path="/dashboard" component={ROUTES.Dashboard} />
    <Route exact path="/configurator" component={ROUTES.Configurator} />
    <Route exact path="/configurator/:id" component={ROUTES.Configurator} />
    <Route exact path="/login" component={ROUTES.Login} />
    <Route component={ROUTES.Page404} />
  </Switch>
);

export default App;
