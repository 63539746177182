import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Page404 extends Component {
  componentDidMount() {
    document.title = 'Page404 | Full ajtótervező';
  }

  render() {
    const { t } = this.props;
    return (
      <div className="page page-404">
        <p>{t('pages.404.page_buttons_label.error')}</p>
      </div>
    );
  }
}

Page404.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Page404);
