export default {
  canvasDoorAccessory: {
    type: 'image',
    version: '3.4.0',
    originX: 'left',
    originY: 'top',
    left: 0,
    top: 0,
    width: 206,
    height: 206,
    fill: 'rgb(0,0,0)',
    stroke: null,
    strokeWidth: 0,
    strokeDashArray: null,
    strokeLineCap: 'butt',
    strokeDashOffset: 0,
    strokeLineJoin: 'miter',
    strokeMiterLimit: 4,
    scaleX: 0.8,
    scaleY: 0.8,
    angle: 0,
    flipX: false,
    flipY: false,
    opacity: 1,
    shadow: null,
    visible: true,
    clipTo: null,
    backgroundColor: '',
    fillRule: 'nonzero',
    paintFirst: 'fill',
    globalCompositeOperation: 'source-over',
    transformMatrix: null,
    skewX: 0,
    skewY: 0,
    crossOrigin: 'Anonymous',
    cropX: 0,
    cropY: 0,
    src: 'https://configurator.backend.full.co.hu/sites/default/files/photos/Thema_PZ.png?ovfc6?98db5u',
    filters: [],
    layerIndex: 100,
  },
};
