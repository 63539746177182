import { takeEvery, put } from 'redux-saga/effects';
import {
  SET_PROPERTIES,
  CONFIGURATOR_SELECT_WORKING_WING,
} from 'consts/actionTypes';

export function* selectWorkingWing(action) {
  try {
    yield put({
      type: SET_PROPERTIES,
      payload: action.payload,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export default [
  takeEvery(CONFIGURATOR_SELECT_WORKING_WING, selectWorkingWing),
];
