import { combineReducers } from 'redux';
import canvas from './canvas/canvasReducer';
import availableOptions from './availableOptions/availableOptionsReducer';
import selectedOptions from './selectedOptions/selectedOptionsReducer';
import configurator from './configurator/configuratorReducer';
import app from './app/appReducer';

const rootReducer = combineReducers({
  canvas,
  availableOptions,
  selectedOptions,
  configurator,
  app,
});

export default rootReducer;
