import {
  APP_CHANGE_LANGUAGE,
} from 'consts/actionTypes';

export const changeLanguage = (language) => ({
  type: APP_CHANGE_LANGUAGE,
  payload: {
    language,
  },
});
