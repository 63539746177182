import { fabric } from 'fabric';
import { host } from 'services/config';
import { call } from 'redux-saga/effects';

export const getLocationImage = (roomLayer, name, layerIndex, left, layerFilter) => (
  new Promise((resolve, reject) => {
    try {
      fabric.Image.fromURL(`${host}${roomLayer}`, (img) => {
        const handleImage = img;
        handleImage.name = name;
        handleImage.layerIndex = layerIndex;
        handleImage.crossOrigin = 'Anonymous';
        handleImage.scale(4.3);

        handleImage.set({
          left,
        });

        if (layerFilter) {
          const filter = new fabric.Image.filters.BlendColor({
            color: layerFilter.color,
            mode: layerFilter.mode,
          });

          handleImage.filters.push(filter);
          handleImage.applyFilters();
        }

        resolve(handleImage);
      }, {
        crossOrigin: 'Anonymous',
      });
    } catch (error) {
      reject(error);
    }
  })
);

export function* getInitRoomImages(wallColor, floorColor, locationImageUrls) {
  const { fchFloorMultiply, fchFloorScreen, fchObjects, fchWallMultiply, fchWallScreen } = locationImageUrls;
  const getFchWallScreenImage = yield call(
    getLocationImage,
    fchWallScreen, 'wallScreen', 1, -750, { color: wallColor, mode: 'add' },
  );
  const getFchWallMultiplyImage = yield call(
    getLocationImage,
    fchWallMultiply, 'wallMultiply', 2, -750, { color: wallColor, mode: 'multiply' },
  );
  const getFchFloorScreenImage = yield call(
    getLocationImage,
    fchFloorScreen, 'floorScreen', 3, -750, { color: floorColor, mode: 'add' },
  );
  const getFchFloorMultiplyImage = yield call(
    getLocationImage,
    fchFloorMultiply, 'floorMultiply', 4, -750, { color: floorColor, mode: 'multiply' },
  );
  const getFchLocationImage = yield call(
    getLocationImage,
    fchObjects, 'locationObject', 92, -750, null,
  );

  return {
    getFchWallScreenImage,
    getFchWallMultiplyImage,
    getFchFloorScreenImage,
    getFchFloorMultiplyImage,
    getFchLocationImage,
  };
}
