export default {
  translation: {
    pages: {
      home: {
        step1: 'Válassza ki a helyiséget, ahová az ajtó kerül!',
        step2: 'Tervezze meg az ajtót, válasszon felületet, design-t és kiegészítőket!',
        step3: 'Töltse le a kész ajtó tervét!',
        button: 'Új ajtó létrehozása',
      },
      configurator: {
        nextButton: 'Folytatás',
        endButton: 'Befejezés',
      },
      login: {
        page_buttons_label: {
          login: 'Log in please',
        },
      },
      404: {
        page_buttons_label: {
          error: `Hello beautiful user you are in the wrong place at the wrong time.
          Do not give up just work harder and harder and harder`,
        },
      },
    },
    stepper: {
      common: {
        wingchooser: {
          active: 'Aktív',
          passive: 'Passzív',
        },
      },
      step_1: {
        title: 'Környezet',
        children: {
          style: 'Stílus',
          context: 'Környezet',
          wall: 'Fal',
          floor: 'Padló',
        },
        btn: {
          wall: 'fal',
          floor: 'padló',
        },
        page_buttons_label: {
          choose_construction: 'Válasszon',
          choose_context: 'Válassza meg a környzetet!',
          write_here_the_color: 'Válasszon színt!',
        },
      },
      step_2: {
        title: 'Kialakítás',
        children: {
          construction: 'Kialakítás',
          opening_type: 'Nyitás',
          door_type: 'Ajtó típusa',
          extra: 'Extra',
          size: 'Méret',
          wall_width: 'Falvastagság',
          dimension: 'Méret',
        },
        door_dimensions: {
          active_width: 'Aktív szélesség',
          passive_width: 'Passzív szélesség',
          door_height: 'Ajtó magasság',
        },
        page_buttons_label: {
          choose_type: 'Válasszon kivitelt',
          choose_door_type: 'Válasszon ajtó típust',
          choose_door_implementation: 'Válasszon ajtó kivitelezést',
          add_size: 'Méretek beállítása',
          door_opening_direction: 'Nyitás iránya',
          choose_wing: 'Nyíló szárny',
          choose_opening_type: 'Nyitási típus',
          component_door_design: 'Állószárny típusa',
          component_door_design_extra: 'Állószárny típusa extra',
          width: 'Szélesség',
          height: 'Magasság',
          wall_width: 'Falvastagság',
        },
      },
      step_3: {
        title: 'Felület',
        children: {
          model: 'Modell',
          layout: 'Felület',
          intarzia: 'Intarzia',
          active_model: 'Modell (aktív)',
          active_layout: 'Felület (aktív)',
          passive_model: 'Modell (passzív)',
          passive_layout: 'Felület (passzív)',
        },
        page_buttons_label: {
          pattern_type: 'Minta típusa',
          pattern: 'Minta',
        },
      },
      step_4: {
        title: 'Üvegezés',
        children: {
          base_glass: 'Világító üvegezés minta',
          active_glass_family: 'Üvegezés típus (aktív)',
          active_glass_type: 'Üvegezés forma (aktív)',
          active_glass_pattern: 'Üvegezés minta (aktív)',
          passive_glass_family: 'Üvegezés (passzív)',
          passive_glass_type: 'Üveg típus (passzív)',
          passive_glass_pattern: 'Üveg minta (passzív)',
        },
        non_glazing_pattern: 'Nem üvegezett',
        page_buttons_label: {
          door_base_glazing_pattern: 'Világító üvegezés minta',
          door_glazing_type: 'Üvegezés típus',
          door_glazing_pattern: 'Üvegezés minta',
          door_glazing_shape: 'Üvegezés forma',
          door_glazing_is_require: 'Üvegezés',
        },
      },
      step_5: {
        title: 'Színek',
        children: {
          door_case: 'Ajtótok',
          active_door_sheet: 'Ajtólap (aktív)',
          active_metal_stripe: 'Fémcsík (aktív)',
          active_door_inlay: 'Intarzia (aktív)',
          active_door_inlay2: 'Intarzia2 (aktív)',
          passive_door_sheet: 'Ajtólap (passzív)',
          passive_metal_stripe: 'Fémcsík (passzív)',
          passive_door_inlay: 'Intarzia (passzív)',
          passive_door_inlay2: 'Intarzia2 (passzív)',
        },
        page_buttons_label: {
          metal_stripe: 'Fémcsík szín',
          door_case_theme: 'Ajtótok minta',
          door_sheet_theme: 'Ajtólap minta',
          door_inlay_theme: 'Intarzia minta',
          door_inlay2_theme: 'Intarzia 2 minta',
          door_glazing: 'Üvegezés',
          choose_a_pattern: 'Válasszon mintát',
        },
      },
      step_6: {
        title: 'Kiegészítők',
        children: {
          handle: 'Kilincs',
          fan: 'Szellőző',
        },
        page_buttons_label: {},
      },
      step_7: {
        title: 'Összesítés',
        children: {
          piece: 'Összesítés',
        },
        page_buttons_label: {
          download_pdf: 'PDF letöltése',
          download_jpg: 'JPG letöltése',
          create_link: 'Ajtó megosztása',
        },
      },
    },
  },
};
