import {
  CANVAS_STORE_OBJECTS,
  CONFIGURATOR_GET_PDF_REQUESTED,
  CONFIGURATOR_GET_PDF_SUCCEEDED,
  CONFIGURATOR_GET_PDF_FAILED,
  CONFIGURATOR_GET_JPG_REQUESTED,
  CONFIGURATOR_GET_JPG_SUCCEEDED,
  CONFIGURATOR_GET_JPG_FAILED,
  CONFIGURATOR_GET_URL_REQUESTED,
  CONFIGURATOR_GET_URL_SUCCEEDED,
  CONFIGURATOR_GET_URL_FAILED,
  DOOR_LOAD_BEGIN,
  DOOR_LOAD_END,
} from 'consts/actionTypes';
import initialState from './initialState';

const canvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case CANVAS_STORE_OBJECTS: {
      const { canvasId, objects } = action.payload;
      return {
        ...state,
        [canvasId]: {
          ...state[canvasId],
          objects,
        },
      };
    }
    case DOOR_LOAD_BEGIN:
      return {
        ...state,
        isFetchingDoor: true,
      };

    case DOOR_LOAD_END:
      return {
        ...state,
        isFetchingDoor: false,
      };

    case CONFIGURATOR_GET_PDF_REQUESTED:
      return {
        ...state,
        isFetchingPdf: true,
      };

    case CONFIGURATOR_GET_PDF_SUCCEEDED:
    case CONFIGURATOR_GET_PDF_FAILED:
      return {
        ...state,
        isFetchingPdf: false,
      };

    case CONFIGURATOR_GET_JPG_REQUESTED:
      return {
        ...state,
        isFetchingJpg: true,
      };

    case CONFIGURATOR_GET_JPG_SUCCEEDED:
    case CONFIGURATOR_GET_JPG_FAILED:
      return {
        ...state,
        isFetchingJpg: false,
      };

    case CONFIGURATOR_GET_URL_REQUESTED:
      return {
        ...state,
        isFetchingUrl: true,
      };

    case CONFIGURATOR_GET_URL_SUCCEEDED:
      return {
        ...state,
        isFetchingUrl: false,
        doorId: action.payload.id,
      };

    case CONFIGURATOR_GET_URL_FAILED:
      return {
        ...state,
        isFetchingUrl: false,
      };

    default:
      return state;
  }
};

export default canvasReducer;
