import falcoltAlap from 'utils/doors/falcoltAlap';
// import falcoltAlapV from 'utils/doors/falcoltAlapV';
import falcoltFixBlende from 'utils/doors/falcoltFixBlende';
import falcoltOsztottBlende from 'utils/doors/falcoltOsztottBlende';
import falcoltSoroltOldalvilagito from 'utils/doors/falcoltSoroltOldalvilagito';
import falcoltSoroltFelulvilagito from 'utils/doors/falcoltSoroltFelulvilagito';
import falcoltTokosztosFelulvilagito from 'utils/doors/falcoltTokosztosFelulvilagito';
import falcoltKetszarnyu from 'utils/doors/falcoltKetszarnyu';
import falcoltKetszarnyuFixBlende from 'utils/doors/falcoltKetszarnyuFixBlende';
import falcoltKetszarnyuOsztottBlende from 'utils/doors/falcoltKetszarnyuOsztottBlende';
import falcoltKetszarnyuSoroltOldalvilagito from 'utils/doors/falcoltKetszarnyuSoroltOldalvilagito';
import falcoltKetszarnyuSoroltFelulvilagito from 'utils/doors/falcoltKetszarnyuSoroltFelulvilagito';
import falcoltKetszarnyuTokosztosFelulvilagito from 'utils/doors/falcoltKetszarnyuTokosztosFelulvilagito';

import stumpfAlap from 'utils/doors/stumpfAlap';
import stumpfFixBlende from 'utils/doors/stumpfFixBlende';
import stumpfOsztottBlende from 'utils/doors/stumpfOsztottBlende';
import stumpftSoroltOldalvilagito from 'utils/doors/stumpftSoroltOldalvilagito';
import stumpfSoroltFelulvilagito from 'utils/doors/stumpfSoroltFelulvilagito';
import stumpfTokosztosFelulvilagito from 'utils/doors/stumpfTokosztosFelulvilagito';
import stumpfKetszarnyu from 'utils/doors/stumpfKetszarnyu';
import stumpfKetszarnyuFixBlende from 'utils/doors/stumpfKetszarnyuFixBlende';
import stumpfKetszarnyuOsztottBlende from 'utils/doors/stumpfKetszarnyuOsztottBlende';
import stumpfKetszarnyuSoroltOldalvilagito from 'utils/doors/stumpfKetszarnyuSoroltOldalvilagito';
import stumpfKetszarnyuSoroltFelulvilagito from 'utils/doors/stumpfKetszarnyuSoroltFelulvilagito';
import stumpfKetszarnyuTokosztosFelulvilagito from 'utils/doors/stumpfKetszarnyuTokosztosFelulvilagito';

import tokbanyiloAlap from 'utils/doors/tokbanyiloAlap';
import tokbanyiloFixBlende from 'utils/doors/tokbanyiloFixBlende';
import tokbanyiloOsztottBlende from 'utils/doors/tokbanyiloOsztottBlende';
import tokbanyiloTokosztosFelulvilagito from 'utils/doors/tokbanyiloTokosztosFelulvilagito';
import tokbanyiloKetszarnyu from 'utils/doors/tokbanyiloKetszarnyu';
import tokbanyiloKetszarnyuFixBlende from 'utils/doors/tokbanyiloKetszarnyuFixBlende';
import tokbanyiloKetszarnyuOsztottBlende from 'utils/doors/tokbanyiloKetszarnyuOsztottBlende';
import tokbanyiloKetszarnyuTokosztosFelulvilagito from 'utils/doors/tokbanyiloKetszarnyuTokosztosFelulvilagito';

import falElottFutoAlap from 'utils/doors/falElottFutoAlap';
import falElottFutoFixBlende from 'utils/doors/falElottFutoFixBlende';
import falElottFutoSoroltOldalvilagito from 'utils/doors/falElottFutoSoroltOldalvilagito';
import falElottFutoSoroltFelulvilagito from 'utils/doors/falElottFutoSoroltFelulvilagito';
import falElottFutoKetszarnyu from 'utils/doors/falElottFutoKetszarnyu';
import falElottFutoKetszarnyuFixBlende from 'utils/doors/falElottFutoKetszarnyuFixBlende';
import falElottFutoKetszarnyuSoroltOldalvilagito from 'utils/doors/falElottFutoKetszarnyuSoroltOldalvilagito';
import falElottFutoKetszarnyuSoroltFelulvilagito from 'utils/doors/falElottFutoKetszarnyuSoroltFelulvilagito';

import falbanFutoAlap from 'utils/doors/falbanFutoAlap';
import falbanFutoFixBlende from 'utils/doors/falbanFutoFixBlende';
import falbanFutoKetszarnyu from 'utils/doors/falbanFutoKetszarnyu';
import falbanFutoKetszarnyuFixBlende from 'utils/doors/falbanFutoKetszarnyuFixBlende';

const getDoorElements = (doorType, state) => {
  switch (doorType) {
    case 'falcolt-alap':
      return falcoltAlap(state);

    case 'falcolt-fix-blende':
      return falcoltFixBlende(state);

    case 'falcolt-osztott-blende':
      return falcoltOsztottBlende(state);

    case 'falcolt-sorolt-oldalvilagito':
      return falcoltSoroltOldalvilagito(state);

    case 'falcolt-sorolt-felulvilagito':
      return falcoltSoroltFelulvilagito(state);

    case 'falcolt-tokosztos-felulvilagito':
      return falcoltTokosztosFelulvilagito(state);

    case 'falcolt-ketszarnyu-alap':
      return falcoltKetszarnyu(state);

    case 'falcolt-ketszarnyu-fix-blende':
      return falcoltKetszarnyuFixBlende(state);

    case 'falcolt-ketszarnyu-osztott-blende':
      return falcoltKetszarnyuOsztottBlende(state);

    case 'falcolt-ketszarnyu-sorolt-oldalvilagito':
      return falcoltKetszarnyuSoroltOldalvilagito(state);

    case 'falcolt-ketszarnyu-sorolt-felulvilagito':
      return falcoltKetszarnyuSoroltFelulvilagito(state);

    case 'falcolt-ketszarnyu-tokosztos-felulvilagito':
      return falcoltKetszarnyuTokosztosFelulvilagito(state);

    case 'stumpf-alap':
      return stumpfAlap(state);

    case 'stumpf-fix-blende':
      return stumpfFixBlende(state);

    case 'stumpf-osztott-blende':
      return stumpfOsztottBlende(state);

    case 'stumpf-sorolt-oldalvilagito':
      return stumpftSoroltOldalvilagito(state);

    case 'stumpf-sorolt-felulvilagito':
      return stumpfSoroltFelulvilagito(state);

    case 'stumpf-tokosztos-felulvilagito':
      return stumpfTokosztosFelulvilagito(state);

    case 'stumpf-ketszarnyu-alap':
      return stumpfKetszarnyu(state);

    case 'stumpf-ketszarnyu-fix-blende':
      return stumpfKetszarnyuFixBlende(state);

    case 'stumpf-ketszarnyu-osztott-blende':
      return stumpfKetszarnyuOsztottBlende(state);

    case 'stumpf-ketszarnyu-sorolt-oldalvilagito':
      return stumpfKetszarnyuSoroltOldalvilagito(state);

    case 'stumpf-ketszarnyu-tokosztos-felulvilagito':
      return stumpfKetszarnyuTokosztosFelulvilagito(state);

    case 'stumpf-ketszarnyu-sorolt-felulvilagito':
      return stumpfKetszarnyuSoroltFelulvilagito(state);

    case 'tokbanyilo-alap':
      return tokbanyiloAlap(state);

    case 'tokbanyilo-fix-blende':
      return tokbanyiloFixBlende(state);

    case 'tokbanyilo-osztott-blende':
      return tokbanyiloOsztottBlende(state);

    case 'tokbanyilo-tokosztos-felulvilagito':
      return tokbanyiloTokosztosFelulvilagito(state);

    case 'tokbanyilo-ketszarnyu-alap':
      return tokbanyiloKetszarnyu(state);

    case 'tokbanyilo-ketszarnyu-fix-blende':
      return tokbanyiloKetszarnyuFixBlende(state);

    case 'tokbanyilo-ketszarnyu-osztott-blende':
      return tokbanyiloKetszarnyuOsztottBlende(state);

    case 'tokbanyilo-ketszarnyu-tokosztos-felulvilagito':
      return tokbanyiloKetszarnyuTokosztosFelulvilagito(state);

    case 'fal-elott-futo-alap':
      return falElottFutoAlap(state);

    case 'fal-elott-futo-fix-blende':
      return falElottFutoFixBlende(state);

    case 'fal-elott-futo-sorolt-oldalvilagito':
      return falElottFutoSoroltOldalvilagito(state);

    case 'fal-elott-futo-sorolt-felulvilagito':
      return falElottFutoSoroltFelulvilagito(state);

    case 'fal-elott-futo-ketszarnyu-alap':
      return falElottFutoKetszarnyu(state);

    case 'fal-elott-futo-ketszarnyu-fix-blende':
      return falElottFutoKetszarnyuFixBlende(state);

    case 'fal-elott-futo-ketszarnyu-sorolt-oldalvilagito':
      return falElottFutoKetszarnyuSoroltOldalvilagito(state);

    case 'fal-elott-futo-ketszarnyu-sorolt-felulvilagito':
      return falElottFutoKetszarnyuSoroltFelulvilagito(state);

    case 'falban-futo-alap':
      return falbanFutoAlap(state);

    case 'falban-futo-fix-blende':
      return falbanFutoFixBlende(state);

    case 'falban-futo-ketszarnyu-alap':
      return falbanFutoKetszarnyu(state);

    case 'falban-futo-ketszarnyu-fix-blende':
      return falbanFutoKetszarnyuFixBlende(state);

    default:
      return [];
  }
};

export default getDoorElements;
