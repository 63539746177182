class Patterns {
  constructor() {
    this.patterns = {};
  }

  setPatterns(patternArray) {
    patternArray.forEach((pattern) => {
      this.patterns[pattern.name] = pattern.value;
    });
  }

  getPattern(id) {
    return this.patterns[id];
  }

  setImages(patternArray) {
    patternArray.forEach((pattern) => {
      this.patterns[pattern.name] = pattern.value;
    });
  }

  getImage(id) {
    return this.patterns[id];
  }

  getImageNames() {
    return Object.keys(this.patterns);
  }
}

export default Patterns;
