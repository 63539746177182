import { RIGHT_OPENING_DIRECTION } from 'consts/consts';

export const setDoorAccessoryPosition = (accessory, alignObject, openingDirection, isExceptionDoorDesign) => {
  const doorAccessory = accessory;
  if (openingDirection !== RIGHT_OPENING_DIRECTION) {
    doorAccessory.set('flipX', true);
    doorAccessory.left = alignObject.left + alignObject.width - accessory.width - 15;
  } else {
    doorAccessory.left = alignObject.left + 15;
  }

  if (isExceptionDoorDesign) {
    if (openingDirection === RIGHT_OPENING_DIRECTION) {
      doorAccessory.set('flipX', true);
      doorAccessory.left = alignObject.left + alignObject.width - accessory.width - 15;
    } else {
      doorAccessory.set('flipX', false);
      doorAccessory.left = alignObject.left + 15;
    }
  }

  doorAccessory.top = alignObject.top + (alignObject.height - 1098);

  return doorAccessory;
};

/**
 * Reflects the given shape against the shape of the active door.
 * In fact, this is almost a reflection through the axis Y.
 *
 * @param shape
 * @param obj
 * @param alignObject
 * @param alignObjectPassive
 * @param selectedOptionsStore
 * @param shapeName
 * @returns {*}
 */
export const setDoorPatternPosition = (shape, obj, alignObject, alignObjectPassive, selectedOptionsStore, shapeName) => {
  const doorShape = shape;

  // Reflects the pattern image shape against the shape of the active door.
  if (shapeName === 'patternImage') {
    if (selectedOptionsStore.selectedDoorOpeningDirection === RIGHT_OPENING_DIRECTION) {
      if (obj.left === 0) {
        doorShape.left += (alignObjectPassive.width - obj.width);
      } else {
        doorShape.left = alignObjectPassive.left + (alignObjectPassive.width - obj.left - obj.width);
      }
    } else {
      if (obj.left === 0) { // eslint-disable-line
        doorShape.left = alignObjectPassive.left + (alignObjectPassive.width - obj.left - obj.width);
      } else {
        doorShape.left = (alignObjectPassive.left + alignObjectPassive.width) - (obj.left + obj.width);
      }
    }

    return doorShape;
  }

  // Reflects the pattern metal string(s) shape against the shape of the active door.
  if (shapeName === 'patternMetalStripe') {
    if (obj.left === 0) {
      return doorShape;
    }

    if (selectedOptionsStore.selectedDoorOpeningDirection === RIGHT_OPENING_DIRECTION) {
      doorShape.left = alignObject.left - obj.left;
    } else {
      doorShape.left = alignObjectPassive.left + alignObjectPassive.width - obj.left;
    }

    return doorShape;
  }

  return doorShape;
};
