export default {
  '5c1d9c3d-c7f4-4538-9b08-ddd603df26e6': {
    type: 'paragraph--dimension',
    id: '5c1d9c3d-c7f4-4538-9b08-ddd603df26e6',
    attributes: {
      drupal_internal__id: 12,
      drupal_internal__revision_id: 12,
      langcode: 'en',
      status: true,
      created: '2019-07-12T08:39:23+00:00',
      parent_id: '16',
      parent_type: 'node',
      parent_field_name: 'fch_dimension',
      behavior_settings: [],
      default_langcode: true,
      revision_translation_affected: true,
      fch_x_dimension: 0,
      fch_y_dimension: 0,
      fch_z_dimension: 0,
    },
    relationships: {
      paragraph_type: {
        data: {
          type: 'paragraphs_type--paragraphs_type',
          id: '1193df88-fa68-4c1b-883c-46e1918ef94f',
        },
        links: {
          self: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/5c1d9c3d-c7f4-4538-9b08-ddd603df26e6/relationships/paragraph_type',
          },
          related: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/5c1d9c3d-c7f4-4538-9b08-ddd603df26e6/paragraph_type',
          },
        },
      },
    },
    links: {
      self: {
        href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/5c1d9c3d-c7f4-4538-9b08-ddd603df26e6',
      },
    },
  },
  'abf7d2e1-f643-448b-a027-ad7c4377bdbe': {
    type: 'paragraph--dimension',
    id: 'abf7d2e1-f643-448b-a027-ad7c4377bdbe',
    attributes: {
      drupal_internal__id: 13,
      drupal_internal__revision_id: 13,
      langcode: 'en',
      status: true,
      created: '2019-07-12T08:39:23+00:00',
      parent_id: '17',
      parent_type: 'node',
      parent_field_name: 'fch_dimension',
      behavior_settings: [],
      default_langcode: true,
      revision_translation_affected: true,
      fch_x_dimension: 1,
      fch_y_dimension: 0,
      fch_z_dimension: 0,
    },
    relationships: {
      paragraph_type: {
        data: {
          type: 'paragraphs_type--paragraphs_type',
          id: '1193df88-fa68-4c1b-883c-46e1918ef94f',
        },
        links: {
          self: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/abf7d2e1-f643-448b-a027-ad7c4377bdbe/relationships/paragraph_type',
          },
          related: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/abf7d2e1-f643-448b-a027-ad7c4377bdbe/paragraph_type',
          },
        },
      },
    },
    links: {
      self: {
        href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/abf7d2e1-f643-448b-a027-ad7c4377bdbe',
      },
    },
  },
  '2e8deee9-e087-4adc-ba0a-237f3c8a4945': {
    type: 'paragraph--dimension',
    id: '2e8deee9-e087-4adc-ba0a-237f3c8a4945',
    attributes: {
      drupal_internal__id: 14,
      drupal_internal__revision_id: 14,
      langcode: 'en',
      status: true,
      created: '2019-07-12T08:39:23+00:00',
      parent_id: '18',
      parent_type: 'node',
      parent_field_name: 'fch_dimension',
      behavior_settings: [],
      default_langcode: true,
      revision_translation_affected: true,
      fch_x_dimension: 1,
      fch_y_dimension: 0,
      fch_z_dimension: 0,
    },
    relationships: {
      paragraph_type: {
        data: {
          type: 'paragraphs_type--paragraphs_type',
          id: '1193df88-fa68-4c1b-883c-46e1918ef94f',
        },
        links: {
          self: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/2e8deee9-e087-4adc-ba0a-237f3c8a4945/relationships/paragraph_type',
          },
          related: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/2e8deee9-e087-4adc-ba0a-237f3c8a4945/paragraph_type',
          },
        },
      },
    },
    links: {
      self: {
        href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/2e8deee9-e087-4adc-ba0a-237f3c8a4945',
      },
    },
  },
  '0cfddc68-fb6c-4eb7-aae3-7a10d2e6f400': {
    type: 'paragraph--dimension',
    id: '0cfddc68-fb6c-4eb7-aae3-7a10d2e6f400',
    attributes: {
      drupal_internal__id: 15,
      drupal_internal__revision_id: 15,
      langcode: 'en',
      status: true,
      created: '2019-07-12T08:39:23+00:00',
      parent_id: '19',
      parent_type: 'node',
      parent_field_name: 'fch_dimension',
      behavior_settings: [],
      default_langcode: true,
      revision_translation_affected: true,
      fch_x_dimension: 0,
      fch_y_dimension: 1,
      fch_z_dimension: 0,
    },
    relationships: {
      paragraph_type: {
        data: {
          type: 'paragraphs_type--paragraphs_type',
          id: '1193df88-fa68-4c1b-883c-46e1918ef94f',
        },
        links: {
          self: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/0cfddc68-fb6c-4eb7-aae3-7a10d2e6f400/relationships/paragraph_type',
          },
          related: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/0cfddc68-fb6c-4eb7-aae3-7a10d2e6f400/paragraph_type',
          },
        },
      },
    },
    links: {
      self: {
        href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/0cfddc68-fb6c-4eb7-aae3-7a10d2e6f400',
      },
    },
  },
  '4b16aca5-017e-4707-abc7-b65634e6728e': {
    type: 'paragraph--dimension',
    id: '4b16aca5-017e-4707-abc7-b65634e6728e',
    attributes: {
      drupal_internal__id: 16,
      drupal_internal__revision_id: 16,
      langcode: 'en',
      status: true,
      created: '2019-07-12T08:39:23+00:00',
      parent_id: '20',
      parent_type: 'node',
      parent_field_name: 'fch_dimension',
      behavior_settings: [],
      default_langcode: true,
      revision_translation_affected: true,
      fch_x_dimension: 1,
      fch_y_dimension: 0,
      fch_z_dimension: 0,
    },
    relationships: {
      paragraph_type: {
        data: {
          type: 'paragraphs_type--paragraphs_type',
          id: '1193df88-fa68-4c1b-883c-46e1918ef94f',
        },
        links: {
          self: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/4b16aca5-017e-4707-abc7-b65634e6728e/relationships/paragraph_type',
          },
          related: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/4b16aca5-017e-4707-abc7-b65634e6728e/paragraph_type',
          },
        },
      },
    },
    links: {
      self: {
        href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/4b16aca5-017e-4707-abc7-b65634e6728e',
      },
    },
  },
  '1edc21df-d5e1-42b9-8e26-66fa42433bd0': {
    type: 'paragraph--dimension',
    id: '1edc21df-d5e1-42b9-8e26-66fa42433bd0',
    attributes: {
      drupal_internal__id: 17,
      drupal_internal__revision_id: 17,
      langcode: 'en',
      status: true,
      created: '2019-07-12T08:39:23+00:00',
      parent_id: '21',
      parent_type: 'node',
      parent_field_name: 'fch_dimension',
      behavior_settings: [],
      default_langcode: true,
      revision_translation_affected: true,
      fch_x_dimension: 0,
      fch_y_dimension: 0,
      fch_z_dimension: 0,
    },
    relationships: {
      paragraph_type: {
        data: {
          type: 'paragraphs_type--paragraphs_type',
          id: '1193df88-fa68-4c1b-883c-46e1918ef94f',
        },
        links: {
          self: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/1edc21df-d5e1-42b9-8e26-66fa42433bd0/relationships/paragraph_type',
          },
          related: {
            href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/1edc21df-d5e1-42b9-8e26-66fa42433bd0/paragraph_type',
          },
        },
      },
    },
    links: {
      self: {
        href: 'https://configurator.backend.full.co.hu/jsonapi/paragraph/dimension/1edc21df-d5e1-42b9-8e26-66fa42433bd0',
      },
    },
  },
};
