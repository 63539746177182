import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const stumpfTokosztosFelulvilagito = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
    doorBlendeHeight,
    selectedBaseGlassPattern,
  } = state;

  const doorWallOpeningHeightExtra = doorBlendeHeight;

  // Felülvilágító - Pontos üvegméret X2 - 39
  const topWindowHeight = doorWallOpeningHeightExtra - 39;

  // Üvegszorító léc szélesség felső világítóhoz
  const glassClampingWidth = (doorWallOpeningHeightExtra - 38) - (doorWallOpeningHeightExtra - 60);

  // Tokosztó
  const caseSplitter = glassClampingWidth * 3;

  // Ajtólap külméret | Y - 60
  const doorWidth = doorActiveWallOpeningWidth - config.stumpf.doorWidthOuterSize;

  // Ajtólap külméret | X - 40
  const doorHeight = (doorWallOpeningHeight - config.stumpf.doorHeightOuterSize) + topWindowHeight + caseSplitter;

  // Ajtólap magassága
  const doorShapeHeight = doorHeight - topWindowHeight - caseSplitter - 7;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 68) - (Ajtótok falcméret - 52)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.stumpf.frameThickness[0]) - (doorActiveWallOpeningWidth - config.stumpf.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 415) - topWindowHeight - caseSplitter - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  // 2400 = canvas normál méret
  const doorLeftPosition = (2400 - doorActiveWallOpeningWidth) / 2;

  // Layer sorrendek - Eltérő a config!!!
  const doorShapeLayerIndex = 6;
  const doorInnerShadowLayerIndex = 8;
  const frameLayerIndex = 10;
  const doorOuterShadowLayerIndex = 9;

  const windowShapeLayerIndex = 8;
  const glassClampingLayerIndex = 9;
  const caseSplitterLayerIndex = 9;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    patternDirection: doorActivePatternDirection,
    width: doorActivePatternDirection === 'H' ? doorShapeHeight : doorWidth,
    height: doorActivePatternDirection === 'H' ? doorWidth : doorShapeHeight,
    layerIndex: doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorActivePatternDirection === 'H' ? doorLeftPosition + frameThickness + doorWidth : doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness + topWindowHeight + caseSplitter,
    fill: '#8a6930',
    angle: doorActivePatternDirection === 'H' ? 90 : 0,
  });

  const caseSplitterShape = new fabric.Rect({
    name: 'caseSplitterShape',
    doorElement: 'caseSplitterShape',
    width: caseSplitter,
    height: -doorWidth,
    layerIndex: caseSplitterLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness + topWindowHeight,
    fill: '#8a6930',
    angle: 90,
  });

  const windowShape = new fabric.Rect({
    name: 'windowShape',
    doorElement: 'windowShape',
    width: doorWidth,
    height: topWindowHeight,
    layerIndex: windowShapeLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness,
    fill: selectedBaseGlassPattern || '#4f4f4f',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#674a18',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  // Üvegszorító felülvilágítóhoz

  const topGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: (doorWidth - (glassClampingWidth)) + 20 },
      { x: glassClampingWidth, y: (doorWidth - (glassClampingWidth * 2)) + 20 },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'topGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness + 1,
      top: doorTopPosition + frameThickness + 1,
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: 2,
        blur: 10,
      },
    },
  );

  const bottomGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: glassClampingWidth },
      { x: 0, y: (doorWidth - glassClampingWidth) },
      { x: glassClampingWidth, y: doorWidth },
      { x: glassClampingWidth, y: 0 },
    ], {
      name: 'bottomGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition + (frameThickness / 2) + topWindowHeight + 10,
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: -2,
        blur: 10,
      },
    },
  );

  const leftGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: topWindowHeight },
      { x: glassClampingWidth, y: (topWindowHeight - glassClampingWidth) },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'leftGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + frameThickness,
      top: doorTopPosition + (glassClampingWidth * 2) + 15,
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 2,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  const rightGlassClamping = new fabric.Polygon(
    [
      { x: glassClampingWidth, y: 0 },
      { x: glassClampingWidth, y: (topWindowHeight - glassClampingWidth) + 15 },
      { x: 0, y: (topWindowHeight - (glassClampingWidth * 2)) + 15 },
      { x: 0, y: glassClampingWidth },
    ], {
      name: 'rightGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: ((doorLeftPosition + doorWidth) + (frameThickness)) - (glassClampingWidth),
      top: doorTopPosition + (glassClampingWidth * 2) + 25,
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: -2,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowTop',
    layerIndex: doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowLeft',
    layerIndex: doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowRight',
    layerIndex: doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowTop',
    layerIndex: doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: doorTopPosition + topWindowHeight + caseSplitter,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorHeight) - 7,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowLeft',
    layerIndex: doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: doorTopPosition + frameThickness + topWindowHeight + caseSplitter,
    left: doorLeftPosition,
    height: doorShapeHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowRight',
    layerIndex: doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorShapeHeight,
    top: doorTopPosition + frameThickness + topWindowHeight + caseSplitter,
    left: doorLeftPosition + doorWidth + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition,
    width: doorWidth + (frameThickness * 2),
    height: doorHeight + frameThickness,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, windowShape,
    leftFrame, rightFrame, topFrame,
    caseSplitterShape,
    topGlassClamping, bottomGlassClamping, leftGlassClamping, rightGlassClamping,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow,
  ];
};

export default stumpfTokosztosFelulvilagito;
