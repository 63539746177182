import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const tokbanyiloFixBlende = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
  } = state;

  // Ajtólap külméret | Y - 96
  const doorWidth = doorActiveWallOpeningWidth - config.tokbanyilo.doorWidthOuterSize;

  // Ajtólap külméret | X - 55
  const doorHeight = doorWallOpeningHeight - config.tokbanyilo.doorHeightOuterSize;

  // Ajtólap magassága
  const doorShapeHeight = doorHeight - 7;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 32) - (Ajtótok tokborítás belméret - 88)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.tokbanyilo.frameThickness[0]) - (doorActiveWallOpeningWidth - config.tokbanyilo.frameThickness[1])) / 2; // eslint-disable-line

  // Belmagasság
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const roomInnerHeight = (frameThickness + 430) - (doorWallOpeningHeight - 2100);

  // Ajtó felső pozíciója
  const doorTopPosition = roomInnerHeight;

  // Ajtó bal pozíciója
  // 2400 = canvas normál méret
  const doorLeftPosition = (2400 - doorActiveWallOpeningWidth) / 2;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    patternDirection: doorActivePatternDirection,
    width: doorActivePatternDirection === 'H' ? doorShapeHeight : doorWidth,
    height: doorActivePatternDirection === 'H' ? doorWidth : doorShapeHeight,
    layerIndex: config.tokbanyilo.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorActivePatternDirection === 'H' ? doorLeftPosition + frameThickness + doorWidth : doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness,
    fill: '#8a6930',
    angle: doorActivePatternDirection === 'H' ? 90 : 0,
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: (doorHeight + roomInnerHeight) + (frameThickness * 2) },
      { x: frameThickness, y: (doorHeight + roomInnerHeight) + (frameThickness * 2) },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.tokbanyilo.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: 0,
      angle: 0,
      fill: '#674a18',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: roomInnerHeight + (frameThickness * 2) },
      { x: doorWidth + frameThickness, y: roomInnerHeight + (frameThickness * 2) },
      { x: doorWidth + frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.tokbanyilo.layerOrders.frameLayerIndex,
      left: doorLeftPosition + (frameThickness / 2),
      top: 0,
      angle: 0,
      fill: '#674a18',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: (doorHeight + roomInnerHeight) + (frameThickness * 2) },
      { x: frameThickness, y: (doorHeight + roomInnerHeight) + (frameThickness * 2) },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.tokbanyilo.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: 0,
      angle: 0,
      fill: '#674a18',
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.tokbanyilo.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: 0,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.tokbanyilo.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: 0,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness + roomInnerHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.tokbanyilo.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness + roomInnerHeight,
    width: frameThickness,
    top: 0,
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowTop',
    layerIndex: config.tokbanyilo.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: doorTopPosition,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: doorTopPosition + doorShapeHeight,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowLeft',
    layerIndex: config.tokbanyilo.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: doorTopPosition + frameThickness,
    left: doorLeftPosition,
    height: doorShapeHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowRight',
    layerIndex: config.tokbanyilo.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorShapeHeight,
    top: doorTopPosition + frameThickness,
    left: doorLeftPosition + doorWidth + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: 0,
    width: doorWidth + (frameThickness * 2),
    height: (doorShapeHeight + roomInnerHeight) + frameThickness,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, leftFrame, rightFrame, topFrame,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow,
  ];
};

export default tokbanyiloFixBlende;
