import { fabric } from 'fabric';

if (fabric.isWebglSupported()) {
  fabric.textureSize = fabric.maxTextureSize * 2;
}

class Canvas {
  constructor() {
    this.canvas = null;
  }

  setCanvas(canvasId) {
    this.canvas = new fabric.Canvas(canvasId, {
      backgroundColor: 'gainsboro',
      renderOnAddRemove: false,
      enableRetinaScaling: false,
      skipOffscreen: false,
    });
  }

  getCanvas() {
    return this.canvas;
  }
}

export default Canvas;
