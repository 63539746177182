import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falcoltSoroltOldalvilagito = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
    doorActiveWallOpeningWidthExtra,
    selectedBaseGlassPattern,
  } = state;

  // Ajtólap külméret | Y - 35
  const doorWidth = doorActiveWallOpeningWidth - config.falcolt.doorWidthOuterSize;

  // Ajtólap külméret | X - 25
  const doorHeight = doorWallOpeningHeight - config.falcolt.doorHeightOuterSize;

  // Látszó üvegméret | Y2 - 128
  const windowShapeWidth = doorActiveWallOpeningWidthExtra - 128;

  // Pontos üvegméret | X - 91 !!! Falnyílás magassággal számolok mert ki lesz takarva !!!
  const windowShapeHeight = doorWallOpeningHeight;

  // Üvegszorító léc szélesség oldalsó világítóhoz
  const glassClampingWidth = (doorActiveWallOpeningWidthExtra - 128) - (doorActiveWallOpeningWidthExtra - 150);

  // Ajtólap felső pozíciója
  const doorShapePositionTop = (doorWallOpeningHeight - config.falcolt.doorShapePositionTop[0]) - (doorWallOpeningHeight - config.falcolt.doorShapePositionTop[1]); // eslint-disable-line

  // Ajtólap magassága
  const doorShapeHeight = (doorHeight - doorShapePositionTop) + 5;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 67) - (Ajtótok tokmag külméret - 30)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.falcolt.frameThickness[0]) - (doorActiveWallOpeningWidth - config.falcolt.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 425) - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  // 2400 = canvas normál méret
  const doorLeftPosition = (2400 - (doorActiveWallOpeningWidth + windowShapeWidth)) / 2;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    patternDirection: doorActivePatternDirection,
    width: doorActivePatternDirection === 'H' ? doorShapeHeight : doorWidth,
    height: doorActivePatternDirection === 'H' ? doorWidth : doorShapeHeight,
    layerIndex: config.falcolt.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorActivePatternDirection === 'H' ? doorLeftPosition + frameThickness + doorWidth : doorLeftPosition + frameThickness,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    fill: '#8a6930',
    angle: doorActivePatternDirection === 'H' ? 90 : 0,
  });

  const windowShape = new fabric.Rect({
    name: 'windowShape',
    doorElement: 'windowShape',
    width: windowShapeWidth,
    height: windowShapeHeight - (frameThickness / 2),
    layerIndex: config.falcolt.layerOrders.windowShapeLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + doorWidth + (frameThickness * 3),
    top: doorTopPosition + (frameThickness / 2),
    fill: selectedBaseGlassPattern || '#4f4f4f',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#674a18',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  // Oldalvilágító keretek

  const topSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: windowShapeWidth + (frameThickness * 2) },
      { x: frameThickness, y: windowShapeWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 66,
      top: doorTopPosition,
      angle: 90,
      fill: '#463210',
    },
  );

  const bottomSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: windowShapeWidth + frameThickness },
      { x: frameThickness, y: windowShapeWidth + (frameThickness * 2) },
      { x: frameThickness, y: 0 },
    ], {
      name: 'bottomSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 66,
      top: (doorTopPosition + doorWallOpeningHeight) - 24,
      angle: 90,
      fill: '#463210',
    },
  );

  const leftSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 0,
      fill: '#463210',
    },
  );

  const rightSideWindowFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + (frameThickness / 3),
      top: doorTopPosition,
      angle: 0,
      fill: '#463210',
    },
  );

  // Üvegszorítók az oldalsó világítóhoz

  const topSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: (windowShapeWidth - (glassClampingWidth)) + 30 },
      { x: glassClampingWidth, y: (windowShapeWidth - (glassClampingWidth * 2)) + 30 },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'topSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 20,
      top: doorTopPosition + (glassClampingWidth * 2),
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: -5,
        blur: 10,
      },
    },
  );

  const bottomSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: glassClampingWidth },
      { x: 0, y: (windowShapeWidth - glassClampingWidth) + 10 },
      { x: glassClampingWidth, y: windowShapeWidth + 10 },
      { x: glassClampingWidth, y: 0 },
    ], {
      name: 'bottomSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 20,
      top: (doorTopPosition + doorWallOpeningHeight) - (glassClampingWidth * 2),
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: 5,
        blur: 10,
      },
    },
  );

  const leftSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: windowShapeHeight - 65 },
      { x: glassClampingWidth, y: (windowShapeHeight - 65) - glassClampingWidth },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'leftSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2) + (glassClampingWidth * 2),
      top: doorTopPosition + (glassClampingWidth * 2),
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: -5,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  const rightSideGlassClamping = new fabric.Polygon(
    [
      { x: glassClampingWidth, y: 0 },
      { x: glassClampingWidth, y: (windowShapeHeight - glassClampingWidth) - 45 },
      { x: 0, y: (windowShapeHeight - (glassClampingWidth * 2)) - 45 },
      { x: 0, y: glassClampingWidth },
    ], {
      name: 'rightSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.glassClampingLayerIndex,
      left: ((doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra) + (frameThickness / 3)) - (glassClampingWidth),
      top: doorTopPosition + (glassClampingWidth * 2),
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: 5,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: (doorWidth + frameThickness + doorActiveWallOpeningWidthExtra) + 15,
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + (frameThickness / 3),
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    name: 'doorInnerShadowTop',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorShapeHeight) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    name: 'doorInnerShadowLeft',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    name: 'doorInnerShadowRight',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + doorWidth,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition + doorShapePositionTop + 5,
    width: (doorWidth + frameThickness + doorActiveWallOpeningWidthExtra) + 20,
    height: (doorShapeHeight + frameThickness) - 5,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, leftFrame, rightFrame, topFrame, windowShape,
    topSideWindowFrame, leftSideWindowFrame, rightSideWindowFrame, bottomSideWindowFrame,
    leftSideGlassClamping, rightSideGlassClamping, topSideGlassClamping, bottomSideGlassClamping,
    doorFrameOuterShadowLeft, doorFrameOuterShadowTop, doorFrameOuterShadowRight,
    doorInnerShadowLeft, doorInnerShadowTop, doorInnerShadowRight, doorInnerShadowBottom,
    doorFrameShadow,
  ];
};

export default falcoltSoroltOldalvilagito;
