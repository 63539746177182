import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { find } from 'lodash';
import { Button, InputNumber } from 'antd';
import {
  getConfiguratorComponent,
  selectDoorFamily,
  selectDoorWingType,
  selectDoorOpeningDirection,
  selectDoorDesign,
  selectDoorDesignExtra,
  changeDoorDimensions,
} from 'actions/canvasActions';
import {
  NODE_DOOR_FAMILY,
  TAXONOMY_TERM_DOOR_WING_TYPE,
  TAXONOMY_TERM_DOOR_OPENING_TYPE,
  NODE_DOOR_DESIGN,
  NODE_DOOR_DESIGN_EXTRA,
  TAXONOMY_TERM_DOOR_OPENING_DIRECTION,
  NODE_DOOR_ACCESSORIES,
} from 'consts/consts';
import Spinner from 'components/Spinner/ComponentSpinner';
import TypeSelector from 'components/TypeSelector/TypeSelector';

class Step02 extends Component {
  constructor(props) {
    super(props);

    const {
      doorActiveWallOpeningWidth,
      doorWallOpeningHeight,
      doorPassiveWallOpeningWidth,
    } = this.props;

    this.state = {
      doorActiveWallOpeningWidth,
      doorWallOpeningHeight,
      doorPassiveWallOpeningWidth,
    };
  }

  componentDidMount() {
    const { dispatchGetConfiguratorInstanceComponent } = this.props;
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_FAMILY);
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_DOOR_WING_TYPE, '&include=fch_dimension');
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_DOOR_OPENING_TYPE);
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_DESIGN, '&include=fch_dimension');
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_DESIGN_EXTRA, '&include=fch_dimension');
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_DOOR_OPENING_DIRECTION);
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_ACCESSORIES, '&include=fch_attached_image');
  }

  handleChange = (dimension, value) => {
    this.setState({
      [dimension]: value,
    });
  }

  selectDoorFamily = (selectedDoorFamily) => {
    const { dispatchSelectDoorFamily } = this.props;
    dispatchSelectDoorFamily(selectedDoorFamily);
  }

  selectDoorWingType = (doorWingType) => {
    const { dispatchSelectDoorWingType } = this.props;
    dispatchSelectDoorWingType(doorWingType);
  }

  selectDoorOpening = (doorOpening) => {
    const { dispatchSelectDoorOpeningDirection } = this.props;
    dispatchSelectDoorOpeningDirection(doorOpening);
  }

  selectDoorDesign = (doorType) => {
    const { dispatchSelectDoorDesign } = this.props;
    dispatchSelectDoorDesign(doorType);
  }

  selectDoorDesignExtra = (doorDesignExtra) => {
    const { dispatchSelectDoorDesignExtra } = this.props;
    dispatchSelectDoorDesignExtra(doorDesignExtra);
  }

  changeDoorDimensions = () => {
    const { dispatchChangeDoorDimensions } = this.props;
    dispatchChangeDoorDimensions(this.state);
  }

  render() {
    const {
      doorFamilyOptions, selectedDoorFamily, t,
      doorWingTypeOptions, doorOpeningDirectionOptions, doorDesignOptions, doorDesignExtraOptions,
      selectedDoorWingType, selectedDoorOpeningDirection, selectedDoorDesign, selectedDoorDesignExtra,
    } = this.props;

    const {
      doorActiveWallOpeningWidth, doorWallOpeningHeight, doorPassiveWallOpeningWidth,
    } = this.state;

    if (
      !Object.keys(doorWingTypeOptions).length
      || !Object.keys(doorOpeningDirectionOptions).length
      || !Object.keys(doorDesignOptions).length
      || !Object.keys(doorDesignExtraOptions).length
    ) {
      return <Spinner />;
    }

    return (
      <div className="steps step-02">
        <TypeSelector
          index={1}
          title={t('stepper.step_2.page_buttons_label.choose_type')}
          choices={Object.keys(doorFamilyOptions).map((key) => ({
            value: key,
            label: doorFamilyOptions[key].attributes.title,
          }))}
          withIcon
          selectedChoice={selectedDoorFamily}
          onChange={this.selectDoorFamily}
        />

        <TypeSelector
          index={1}
          title={t('stepper.step_2.page_buttons_label.choose_wing')}
          choices={Object.keys(doorWingTypeOptions).map((key) => ({
            value: key,
            label: doorWingTypeOptions[key].attributes.name,
          }))}
          selectedChoice={selectedDoorWingType}
          onChange={this.selectDoorWingType}
        />

        <TypeSelector
          index={2}
          title={t('stepper.step_2.page_buttons_label.choose_door_type')}
          choices={Object.keys(doorDesignOptions).map((key) => ({
            value: key,
            label: doorDesignOptions[key].attributes.title,
          }))}
          selectedChoice={selectedDoorDesign}
          onChange={this.selectDoorDesign}
        />

        <TypeSelector
          index={3}
          title={t('stepper.step_2.page_buttons_label.choose_door_implementation')}
          choices={Object.keys(doorDesignExtraOptions).map((key) => ({
            value: key,
            disabled: !find(doorDesignOptions[selectedDoorDesign].relationships.fch_design_extra.data, { id: key }),
            label: doorDesignExtraOptions[key].attributes.title,
          }))}
          selectedChoice={selectedDoorDesignExtra}
          onChange={this.selectDoorDesignExtra}
        />

        <div>
          <div>
            <div>{t('stepper.step_2.door_dimensions.active_width')}</div>
            <InputNumber
              step={50}
              min={400}
              max={1200}
              value={doorActiveWallOpeningWidth}
              onChange={(value) => { this.handleChange('doorActiveWallOpeningWidth', value); }}
            />
          </div>
          <div>
            <div>{t('stepper.step_2.door_dimensions.door_height')}</div>
            <InputNumber
              step={50}
              min={1200}
              max={2500}
              value={doorWallOpeningHeight}
              onChange={(value) => { this.handleChange('doorWallOpeningHeight', value); }}
            />
          </div>
          {
            selectedDoorWingType !== 'e668fcdb-b4b5-4857-b106-6d500275d6a7'
            && (
              <div>
                <div>{t('stepper.step_2.door_dimensions.passive_width')}</div>
                <InputNumber
                  step={50}
                  min={400}
                  max={1200}
                  value={doorPassiveWallOpeningWidth}
                  onChange={(value) => { this.handleChange('doorPassiveWallOpeningWidth', value); }}
                />
              </div>
            )
          }
          <Button
            className="add-size"
            type="primary"
            onClick={this.changeDoorDimensions}
          >
            {t('stepper.step_2.page_buttons_label.add_size')}
          </Button>
        </div>

        <TypeSelector
          index={4}
          title={t('stepper.step_2.page_buttons_label.door_opening_direction')}
          choices={Object.keys(doorOpeningDirectionOptions).map((key) => ({
            value: key,
            label: doorOpeningDirectionOptions[key].attributes.name,
          }))}
          selectedChoice={selectedDoorOpeningDirection}
          onChange={this.selectDoorOpening}
        />
      </div>
    );
  }
}

Step02.propTypes = {
  doorFamilyOptions: PropTypes.shape({}).isRequired,
  doorWingTypeOptions: PropTypes.shape({}).isRequired,
  doorOpeningDirectionOptions: PropTypes.shape({}).isRequired,
  doorDesignOptions: PropTypes.shape({}).isRequired,
  doorDesignExtraOptions: PropTypes.shape({}).isRequired,
  doorActiveWallOpeningWidth: PropTypes.number.isRequired,
  doorWallOpeningHeight: PropTypes.number.isRequired,
  doorPassiveWallOpeningWidth: PropTypes.number.isRequired,
  selectedDoorFamily: PropTypes.string.isRequired,
  selectedDoorWingType: PropTypes.string.isRequired,
  selectedDoorOpeningDirection: PropTypes.string.isRequired,
  selectedDoorDesign: PropTypes.string.isRequired,
  selectedDoorDesignExtra: PropTypes.string.isRequired,
  dispatchSelectDoorFamily: PropTypes.func.isRequired,
  dispatchSelectDoorWingType: PropTypes.func.isRequired,
  dispatchSelectDoorOpeningDirection: PropTypes.func.isRequired,
  dispatchSelectDoorDesign: PropTypes.func.isRequired,
  dispatchSelectDoorDesignExtra: PropTypes.func.isRequired,
  dispatchChangeDoorDimensions: PropTypes.func.isRequired,
  dispatchGetConfiguratorInstanceComponent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  doorFamilyOptions: store.availableOptions.doorFamily,
  doorWingTypeOptions: store.availableOptions.doorWingType,
  doorOpeningDirectionOptions: store.availableOptions.doorOpeningDirection,
  doorDesignOptions: store.availableOptions.doorDesign,
  doorDesignExtraOptions: store.availableOptions.doorDesignExtra,
  selectedDoorFamily: store.selectedOptions.selectedDoorFamily,
  selectedDoorWingType: store.selectedOptions.selectedDoorWingType,
  selectedDoorOpeningDirection: store.selectedOptions.selectedDoorOpeningDirection,
  selectedDoorType: store.selectedOptions.selectedDoorType,
  selectedDoorDesign: store.selectedOptions.selectedDoorDesign,
  selectedDoorDesignExtra: store.selectedOptions.selectedDoorDesignExtra,
  doorActiveWallOpeningWidth: store.selectedOptions.doorActiveWallOpeningWidth,
  doorWallOpeningHeight: store.selectedOptions.doorWallOpeningHeight,
  doorPassiveWallOpeningWidth: store.selectedOptions.doorPassiveWallOpeningWidth,
});

const mapDispatchToProps = {
  dispatchSelectDoorFamily: selectDoorFamily,
  dispatchSelectDoorWingType: selectDoorWingType,
  dispatchSelectDoorOpeningDirection: selectDoorOpeningDirection,
  dispatchSelectDoorDesign: selectDoorDesign,
  dispatchSelectDoorDesignExtra: selectDoorDesignExtra,
  dispatchChangeDoorDimensions: changeDoorDimensions,
  dispatchGetConfiguratorInstanceComponent: getConfiguratorComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step02));
