/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { convertRalToHex } from 'utils/utils';
import { host } from 'services/config';
import './pattern-palette.scss';


const PatternPalette = ({ colorOptions, patternOptions, attachedImages, selectedOption, onSelect }) => (
  <div className="pattern-palette">
    {
      patternOptions.length !== 0 && Object.keys(attachedImages).length !== 0
      && patternOptions.filter((option) => (Object.prototype.hasOwnProperty.call(option.relationships, 'fch_preview_image') !== null)).map((option) => (
        <Button
          className="pattern-palette-option"
          key={option.id}
          onClick={() => {
            onSelect(option.id);
          }}
          style={{
            width: 120,
            minWidth: 120,
            height: 120,
            margin: 12,
            background: `url("${host}${attachedImages[option.relationships.fch_preview_image.data.id].attributes.uri.url}")`,
            borderRadius: '4px',
            border: `2px solid ${option.id === selectedOption ? '#2049FC' : 'transparent'}`,
          }}
        />
      ))
    }
    {
      colorOptions.map((option) => (
        <Button
          className="pattern-palette-option"
          key={option.id}
          onClick={() => {
            onSelect(option.id);
          }}
          style={{
            width: 120,
            minWidth: 120,
            height: 120,
            margin: 12,
            backgroundColor: convertRalToHex(option.attributes.name),
            borderRadius: '4px',
            border: `2px solid ${option.id === selectedOption ? '#2049FC' : 'transparent'}`,
          }}
        />
      ))
    }
  </div>
);

PatternPalette.propTypes = {
  colorOptions: PropTypes.arrayOf(PropTypes.shape()),
  patternOptions: PropTypes.arrayOf(PropTypes.shape()),
  selectedOption: PropTypes.string,
  attachedImages: PropTypes.shape(),
  onSelect: PropTypes.func,
};

PatternPalette.defaultProps = {
  colorOptions: [],
  patternOptions: [],
  attachedImages: {},
  selectedOption: null,
  onSelect: () => {},
};

export default PatternPalette;
