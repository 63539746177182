import { takeEvery, put, call } from 'redux-saga/effects';
import {
  SET_PROPERTIES,
  CONFIGURATOR_SELECT_DOOR_PATTERN_INSTANCE,
} from 'consts/actionTypes';
import { ACTIVE_WING } from 'consts/consts';
import { renderCanvas } from './canvasSagas';

export function* selectDoorPatternInstance(action) {
  const { selectedDoorPattern, selectedDoorPatternInstance, selectedWorkingWing } = action.payload;
  const pattern = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorPatternActive' : 'selectedDoorPatternPassive';
  const patternInstance = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorPatternInstanceActive' : 'selectedDoorPatternInstancePassive';

  yield put({
    type: SET_PROPERTIES,
    payload: {
      [pattern]: selectedDoorPattern,
      [patternInstance]: selectedDoorPatternInstance,
    },
  });

  yield call(renderCanvas);
}

export default [
  takeEvery(CONFIGURATOR_SELECT_DOOR_PATTERN_INSTANCE, selectDoorPatternInstance),
];
