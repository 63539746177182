import { takeEvery, put, call, select } from 'redux-saga/effects';
import { find } from 'lodash';
import {
  SET_PROPERTIES,
  CONFIGURATOR_SELECT_DOOR_DESIGN,
  CONFIGURATOR_SELECT_DOOR_DESIGN_EXTRA,
  CONFIGURATOR_SELECT_DOOR_OPENING_DIRECTION,
  CONFIGURATOR_SELECT_DOOR_WING_TYPE,
  CONFIGURATOR_CHANGE_DOOR_DIMENSIONS,
  SELECT_OPTION,
} from 'consts/actionTypes';
import {
  ACTIVE_WING,
  slidingDoorFamilies,
} from 'consts/consts';
import { renderCanvas } from './canvasSagas';

const getAvailableOptionsStore = (store) => store.availableOptions;
const getSelectedOptionsStore = (store) => store.selectedOptions;

export function* selectDoorDesign(action) {
  const { doorDesign } = yield select(getAvailableOptionsStore);
  const { selectedDoorDesign, selectedDoorDesignExtra } = yield select(getSelectedOptionsStore);

  // CHECK DOOR ACCESSORY
  if (slidingDoorFamilies.includes(action.payload.selectedDoorDesign) !== slidingDoorFamilies.includes(selectedDoorDesign)) {
    const fallbackAccessory = slidingDoorFamilies.includes(action.payload.selectedDoorDesign)
      ? 'c9a07c9d-abdf-491b-84d6-9dca46c3884d'
      : '6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0';

    yield put({
      type: SELECT_OPTION,
      payload: {
        property: 'selectedDoorAccessory',
        value: fallbackAccessory,
      },
    });
  }

  // CHECK DOOR DESIGN EXTRA
  // fallback to base option if extra design is not valid
  if (!find(doorDesign[action.payload.selectedDoorDesign].relationships.fch_design_extra.data, { id: selectedDoorDesignExtra })) {
    yield put({
      type: SELECT_OPTION,
      payload: {
        property: 'selectedDoorDesignExtra',
        value: '2748d41f-7591-4dc3-9719-643a907fd04b',
      },
    });
  }

  yield put({
    type: SELECT_OPTION,
    payload: {
      property: 'selectedDoorDesign',
      value: action.payload.selectedDoorDesign,
    },
  });

  yield call(renderCanvas);
}

export function* selectDoorDesignExtra(action) {
  yield put({
    type: SELECT_OPTION,
    payload: {
      property: 'selectedDoorDesignExtra',
      value: action.payload.selectedDoorDesignExtra,
    },
  });

  yield call(renderCanvas);
}

export function* selectDoorOpeningDirection(action) {
  yield put({
    type: SELECT_OPTION,
    payload: {
      property: 'selectedDoorOpeningDirection',
      value: action.payload.selectedDoorOpeningDirection,
    },
  });

  yield call(renderCanvas);
}

export function* selectDoorWingType(action) {
  const { selectedDoorWingType } = action.payload;
  const { selectedWorkingWing } = yield select(getSelectedOptionsStore);

  yield put({
    type: SET_PROPERTIES,
    payload: {
      selectedDoorWingType,
      selectedWorkingWing: selectedDoorWingType === 'e668fcdb-b4b5-4857-b106-6d500275d6a7' ? ACTIVE_WING : selectedWorkingWing,
    },
  });

  yield call(renderCanvas);
}

export function* changeDoorDimension(action) {
  const { doorGlazing } = yield select(getAvailableOptionsStore);
  const { selectedDoorGlazingActive, selectedDoorGlazingPassive } = yield select(getSelectedOptionsStore);
  const { dimensions } = action.payload;
  const payload = {
    ...dimensions,
  };

  if (selectedDoorGlazingActive) {
    const doorGlazingActive = doorGlazing[selectedDoorGlazingActive] || null;
    if (
      (doorGlazingActive && doorGlazingActive.attributes.fch_min_width > dimensions.doorActiveWallOpeningWidth)
      || (doorGlazingActive && doorGlazingActive.attributes.fch_min_height > dimensions.doorWallOpeningHeight)
    ) {
      payload.selectedDoorGlazingActive = null;
      payload.selectedDoorGlazingTypeActive = null;
    }
  }

  if (selectedDoorGlazingPassive) {
    const doorGlazingPassive = doorGlazing[selectedDoorGlazingPassive] || null;
    if (
      (doorGlazingPassive && doorGlazingPassive.attributes.fch_min_width > dimensions.doorPassiveWallOpeningWidth)
      || (doorGlazingPassive && doorGlazingPassive.attributes.fch_min_height > dimensions.doorWallOpeningHeight)
    ) {
      payload.selectedDoorGlazingPassive = null;
      payload.selectedDoorGlazingTypePassive = null;
    }
  }

  yield put({
    type: SET_PROPERTIES,
    payload,
  });

  yield call(renderCanvas);
}

export default [
  takeEvery(CONFIGURATOR_SELECT_DOOR_WING_TYPE, selectDoorWingType),
  takeEvery(CONFIGURATOR_SELECT_DOOR_DESIGN, selectDoorDesign),
  takeEvery(CONFIGURATOR_SELECT_DOOR_DESIGN_EXTRA, selectDoorDesignExtra),
  takeEvery(CONFIGURATOR_SELECT_DOOR_OPENING_DIRECTION, selectDoorOpeningDirection),
  takeEvery(CONFIGURATOR_CHANGE_DOOR_DIMENSIONS, changeDoorDimension),
];
