import React from 'react';
import PropTypes from 'prop-types';

export const StepperText = ({ title, contentText, noDot }) => {
  const changeComponentColor = contentText == null ? 'not-filled' : 'filled';
  const removeDot = noDot ? 'remove-dot' : '';

  return (
    <div className={`stepper-component stepper-component-text ${changeComponentColor} ${removeDot}`}>
      <div className="stepper-component-title">{title}</div>
      <div className="stepper-component-content">
        <span>{contentText}</span>
      </div>
    </div>
  );
};

StepperText.defaultProps = {
  title: '',
  contentText: '',
  noDot: false,
};

StepperText.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  noDot: PropTypes.bool,
};

export default StepperText;
