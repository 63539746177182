import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falElottFutoSoroltFelulvilagito = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
    doorBlendeHeight,
    selectedOpeningDirection,
    selectedBaseGlassPattern,
  } = state;

  const isRightOpening = selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4';

  const doorWallOpeningHeightExtra = doorBlendeHeight;

  // Felülvilágító - Pontos üvegméret X2 - 128
  const topWindowHeight = doorWallOpeningHeightExtra - 128;

  // Üvegszorító léc szélesség felső világítóhoz
  const glassClampingWidth = (doorWallOpeningHeightExtra - 128) - (doorWallOpeningHeightExtra - 150);

  // Ajtólap külméret | Y - 20
  const doorWidth = doorActiveWallOpeningWidth - config.falElottFuto.doorWidthOuterSize;

  // Ajtólap külméret | X - 25
  const doorHeight = doorWallOpeningHeight - config.falElottFuto.doorHeightOuterSize;

  // Ajtólap felső pozíciója
  const doorShapePositionTop = (doorWallOpeningHeight - 26) - (doorWallOpeningHeight - 15);

  // Ajtólap magassága
  const doorShapeHeight = (doorHeight - doorShapePositionTop) + 5;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 67) - (Ajtótok tokmag külméret - 30)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.falElottFuto.frameThickness[0]) - (doorActiveWallOpeningWidth - config.falElottFuto.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 425) - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  const doorLeftPosition = (2400 - doorActiveWallOpeningWidth) / 2;

  // Karnis belméret | 2 x (Y + 26)
  const corniceWidth = 2 * (doorActiveWallOpeningWidth + 26);

  // Karnis magasság
  const corniceHeight = 140;

  // Karnis top pozíciója
  const cornicePositionTop = doorTopPosition - (corniceHeight / 2);

  // Layer sorrendek - Eltérő config!!!
  const windowShapeLayerIndex = 7;
  const windowFrameLayerIndex = 8;
  const glassClampingLayerIndex = 9;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    patternDirection: doorActivePatternDirection,
    width: doorActivePatternDirection === 'H' ? doorShapeHeight : doorWidth,
    height: doorActivePatternDirection === 'H' ? doorWidth : doorShapeHeight,
    layerIndex: config.falElottFuto.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorActivePatternDirection === 'H' ? doorLeftPosition + frameThickness + doorWidth : doorLeftPosition + frameThickness,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    fill: '#8a6930',
    angle: doorActivePatternDirection === 'H' ? 90 : 0,
  });

  const corniceShape = new fabric.Rect({
    name: 'corniceShape',
    doorElement: 'corniceShape',
    width: corniceHeight,
    height: corniceWidth,
    layerIndex: config.falElottFuto.layerOrders.corniceShapeLayerIndex,
    strokeWidth: 0,
    angle: 90,
    left: isRightOpening ? (doorLeftPosition - frameThickness) + corniceWidth : (doorLeftPosition) + doorShape.width + (3 * frameThickness),
    top: cornicePositionTop,
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 0,
      offsetY: 10,
      blur: 14,
    },
    fill: '#8a6930',
  });

  const windowShape = new fabric.Rect({
    name: 'windowShape',
    doorElement: 'windowShape',
    width: doorWidth,
    height: topWindowHeight,
    layerIndex: windowShapeLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + frameThickness,
    top: (doorTopPosition - topWindowHeight) - frameThickness,
    fill: selectedBaseGlassPattern || '#4f4f4f',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  // Felülvilágító keret

  const topWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) + 2 },
      { x: frameThickness, y: doorWidth + frameThickness + 2 },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2) + 2,
      top: ((doorTopPosition - topWindowHeight) - (frameThickness * 2)) + 1,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const bottomWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: doorWidth + frameThickness + 2 },
      { x: frameThickness, y: doorWidth + (frameThickness * 2) + 2 },
      { x: frameThickness, y: 0 },
    ], {
      name: 'bottomWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2) + 2,
      top: (doorTopPosition - frameThickness) + 1,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const leftWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: topWindowHeight + (frameThickness * 2) },
      { x: frameThickness, y: topWindowHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: windowFrameLayerIndex,
      left: doorLeftPosition,
      top: ((doorTopPosition - topWindowHeight) - (frameThickness * 2)) + 1,
      angle: 0,
      fill: '#8a6930',
    },
  );

  const rightWindowFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: topWindowHeight + (frameThickness * 2) },
      { x: 0, y: topWindowHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: ((doorTopPosition - topWindowHeight) - (frameThickness * 2)) + 1,
      angle: 0,
      fill: '#8a6930',
    },
  );

  // Üvegszorító felülvilágítóhoz

  const topGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: (doorWidth - (glassClampingWidth)) + 20 },
      { x: glassClampingWidth, y: (doorWidth - (glassClampingWidth * 2)) + 20 },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'topGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition - topWindowHeight - frameThickness,
      angle: 90,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: -2,
        blur: 10,
      },
    },
  );

  const bottomGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: glassClampingWidth },
      { x: 0, y: (doorWidth - glassClampingWidth) },
      { x: glassClampingWidth, y: doorWidth },
      { x: glassClampingWidth, y: 0 },
    ], {
      name: 'bottomGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: (doorTopPosition - frameThickness - glassClampingWidth) + 3,
      angle: 90,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: 2,
        blur: 10,
      },
    },
  );

  const leftGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: topWindowHeight },
      { x: glassClampingWidth, y: (topWindowHeight - glassClampingWidth) },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'leftGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + frameThickness,
      top: doorTopPosition - topWindowHeight - frameThickness,
      angle: 0,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: -2,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  const rightGlassClamping = new fabric.Polygon(
    [
      { x: glassClampingWidth, y: 0 },
      { x: glassClampingWidth, y: topWindowHeight },
      { x: 0, y: topWindowHeight - glassClampingWidth },
      { x: 0, y: glassClampingWidth },
    ], {
      name: 'rightGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: ((doorLeftPosition + doorWidth) + (frameThickness)) - (glassClampingWidth),
      top: doorTopPosition - topWindowHeight - frameThickness,
      angle: 0,
      fill: '#8a6930',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: 2,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: (doorWidth + (frameThickness * 2)) - 4,
    top: doorTopPosition - topWindowHeight - (frameThickness * 2),
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition - topWindowHeight - (frameThickness * 2),
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorShapeHeight + topWindowHeight + (frameThickness * 3) + (doorShapePositionTop * 2),
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorShapeHeight + topWindowHeight + (frameThickness * 3) + (doorShapePositionTop * 2),
    width: frameThickness,
    top: doorTopPosition - topWindowHeight - (frameThickness * 2),
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  const corniceShapeOuterShadowTop = new fabric.Rect({
    name: 'corniceShapeOuterShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth,
    top: cornicePositionTop,
    left: corniceShape.left - corniceShape.height, // height and not width! (angle = 90)
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 0,
      offsetY: -5,
      blur: 10,
    },
  });

  const corniceShapeOuterShadowLeft = new fabric.Rect({
    name: 'corniceShapeOuterShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth / 2,
    top: cornicePositionTop,
    left: corniceShape.left - corniceShape.height, // height and not width! (angle = 90)
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const corniceShapeOuterShadowRight = new fabric.Rect({
    name: 'corniceShapeOuterShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth / 2,
    top: cornicePositionTop,
    left: corniceShape.left - (corniceShape.height / 2), // height and not width! (angle = 90)
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    name: 'doorInnerShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorShapeHeight) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    name: 'doorInnerShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    name: 'doorInnerShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + doorWidth,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: (doorTopPosition + doorShapePositionTop) - topWindowHeight - (frameThickness * 2),
    width: doorWidth + (frameThickness * 2),
    height: doorShapeHeight + topWindowHeight + (frameThickness * 3),
    opacity: config.doorFrameShadow.opacity,
    fill: '#818286',
  });

  const corniceFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'corniceFrameShadow',
    layerIndex: 121,
    left: corniceShape.left - corniceShape.height, // height and not width! (angle = 90),
    top: cornicePositionTop,
    width: corniceWidth,
    height: corniceHeight,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  corniceFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    corniceShape, doorShape, leftFrame, rightFrame,
    topFrame, topWindowFrame, bottomWindowFrame, leftWindowFrame,
    rightWindowFrame, topGlassClamping, bottomGlassClamping, leftGlassClamping,
    rightGlassClamping,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow, corniceFrameShadow, corniceShapeOuterShadowLeft,
    corniceShapeOuterShadowRight, corniceShapeOuterShadowTop, windowShape,
  ];
};

export default falElottFutoSoroltFelulvilagito;
