import { takeEvery, call } from 'redux-saga/effects';
import { APP_CHANGE_LANGUAGE } from 'consts/actionTypes';
import { changeLanguage } from 'services/language';

export function* changeLanguageSaga(action) {
  yield call(changeLanguage, action.payload.language);
}

export const appSagas = [
  takeEvery(APP_CHANGE_LANGUAGE, changeLanguageSaga),
];
