import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const stumpfKetszarnyu = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorPassiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActiveWallOpeningWidthExtra,
    selectedOpeningDirection,
    selectedBaseGlassPattern,
  } = state;

  // Aktv ajtó
  const activeDoorWidth = doorActiveWallOpeningWidth - 25;

  // Passzív ajtó
  const passiveDoorWidth = doorPassiveWallOpeningWidth - 38;

  // Ajtólap külméret
  const doorWidth = activeDoorWidth + passiveDoorWidth;

  // Ajtólap külméret | X - 40
  const doorHeight = doorWallOpeningHeight - 40;

  // Látszó üvegméret | Y2 - 128
  const windowShapeWidth = doorActiveWallOpeningWidthExtra - 128;

  // Pontos üvegméret | X - 91 !!! Falnyílás magassággal számolok mert ki lesz takarva !!!
  const windowShapeHeight = doorWallOpeningHeight;

  // Üvegszorító léc szélesség oldalsó világítóhoz
  const glassClampingWidth = (doorActiveWallOpeningWidthExtra - 128) - (doorActiveWallOpeningWidthExtra - 150);

  // Ajtólap magassága
  const doorShapeHeight = doorHeight - 7;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 68) - (Ajtótok falcméret - 52)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.stumpf.frameThickness[0]) - (doorActiveWallOpeningWidth - config.stumpf.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 415) - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  const doorLeftPosition = (2400 - doorWidth - windowShapeWidth) / 2;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    width: activeDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.stumpf.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness + passiveDoorWidth
      : doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness,
    fill: '#8a6930',
  });

  const doorShapePassive = new fabric.Rect({
    name: 'doorShapePassive',
    doorElement: 'doorShapePassive',
    width: passiveDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.stumpf.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness
      : doorLeftPosition + frameThickness + activeDoorWidth,
    top: doorTopPosition + frameThickness,
    fill: '#8a6930',
  });

  const windowShape = new fabric.Rect({
    name: 'windowShape',
    doorElement: 'windowShape',
    width: windowShapeWidth,
    height: windowShapeHeight - (frameThickness / 2),
    layerIndex: config.stumpf.layerOrders.windowShapeLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + doorWidth + (frameThickness * 3),
    top: doorTopPosition + (frameThickness / 2),
    fill: selectedBaseGlassPattern || '#4f4f4f',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  // Oldalvilágító keretek

  const topSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: windowShapeWidth + (frameThickness * 2) },
      { x: frameThickness, y: windowShapeWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 110,
      top: doorTopPosition,
      angle: 90,
      fill: '#463210',
    },
  );

  const bottomSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: windowShapeWidth + frameThickness },
      { x: frameThickness, y: windowShapeWidth + (frameThickness * 2) },
      { x: frameThickness, y: 0 },
    ], {
      name: 'bottomSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 110,
      top: (doorTopPosition + doorWallOpeningHeight) - 40,
      angle: 90,
      fill: '#463210',
    },
  );

  const leftSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.windowFrameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 0,
      fill: '#463210',
    },
  );

  const rightSideWindowFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.windowFrameLayerIndex,
      left: (doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + frameThickness) - 12,
      top: doorTopPosition,
      angle: 0,
      fill: '#463210',
    },
  );

  // Üvegszorítók az oldalsó világítóhoz
  const topSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: (windowShapeWidth - (glassClampingWidth)) + 30 },
      { x: glassClampingWidth, y: (windowShapeWidth - (glassClampingWidth * 2)) + 30 },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'topSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 55,
      top: doorTopPosition + (glassClampingWidth * 2.5),
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: -5,
        blur: 10,
      },
    },
  );

  const bottomSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: glassClampingWidth },
      { x: 0, y: (windowShapeWidth - glassClampingWidth) + 10 },
      { x: glassClampingWidth, y: windowShapeWidth + 10 },
      { x: glassClampingWidth, y: 0 },
    ], {
      name: 'bottomSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 55,
      top: (doorTopPosition + doorWallOpeningHeight) - (glassClampingWidth * 2.5),
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: 5,
        blur: 10,
      },
    },
  );

  const leftSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: windowShapeHeight - 90 },
      { x: glassClampingWidth, y: (windowShapeHeight - 90) - glassClampingWidth },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'leftSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2) + (glassClampingWidth * 2.5),
      top: doorTopPosition + (glassClampingWidth * 2.5),
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: -5,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  const rightSideGlassClamping = new fabric.Polygon(
    [
      { x: glassClampingWidth, y: 0 },
      { x: glassClampingWidth, y: (windowShapeHeight - glassClampingWidth) - 65 },
      { x: 0, y: (windowShapeHeight - (glassClampingWidth * 2)) - 65 },
      { x: 0, y: glassClampingWidth },
    ], {
      name: 'rightSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.stumpf.layerOrders.glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + (frameThickness / 2),
      top: doorTopPosition + (glassClampingWidth * 2.5),
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: 5,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.stumpf.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: (doorWidth + frameThickness + doorActiveWallOpeningWidthExtra) + 40,
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.stumpf.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.stumpf.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: (doorHeight + frameThickness) - 10,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + (frameThickness / 1.3),
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowTop',
    layerIndex: config.stumpf.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: doorTopPosition,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const twoDoorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'twoDoorInnerShadowTop',
    layerIndex: 4,
    opacity: 1,
    height: doorHeight,
    width: doorWidth,
    top: doorTopPosition,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: doorTopPosition + doorShapeHeight,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowLeft',
    layerIndex: config.stumpf.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: doorTopPosition + frameThickness,
    left: doorLeftPosition,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorInnerShadowRight',
    layerIndex: config.stumpf.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: doorTopPosition + frameThickness,
    left: doorLeftPosition + doorWidth + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition,
    width: (doorWidth + frameThickness + doorActiveWallOpeningWidthExtra) + 50,
    height: doorHeight + frameThickness,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, doorShapePassive, windowShape, leftFrame, rightFrame, topFrame,
    topSideWindowFrame, bottomSideWindowFrame, leftSideWindowFrame, rightSideWindowFrame,
    topSideGlassClamping, bottomSideGlassClamping, leftSideGlassClamping, rightSideGlassClamping,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow, twoDoorInnerShadowTop,
  ];
};

export default stumpfKetszarnyu;
