export default {
  'RAL 1000': '#BEBD7F',
  'RAL 1001': '#C2B078',
  'RAL 1002': '#C6A664',
  'RAL 1003': '#E5BE01',
  'RAL 1004': '#CDA434',
  'RAL 1005': '#A98307',
  'RAL 1006': '#E4A010',
  'RAL 1007': '#DC9D00',
  'RAL 1011': '#8A6642',
  'RAL 1012': '#C7B446',
  'RAL 1013': '#EAE6CA',
  'RAL 1014': '#E1CC4F',
  'RAL 1015': '#E6D690',
  'RAL 1016': '#EDFF21',
  'RAL 1017': '#F5D033',
  'RAL 1018': '#F8F32B',
  'RAL 1019': '#9E9764',
  'RAL 1020': '#999950',
  'RAL 1021': '#F3DA0B',
  'RAL 1023': '#FAD201',
  'RAL 1024': '#AEA04B',
  'RAL 1026': '#FFFF00',
  'RAL 1027': '#9D9101',
  'RAL 1028': '#F4A900',
  'RAL 1032': '#D6AE01',
  'RAL 1033': '#F3A505',
  'RAL 1034': '#EFA94A',
  'RAL 1035': '#6A5D4D',
  'RAL 1036': '#705335',
  'RAL 1037': '#F39F18',
  'RAL 2000': '#ED760E',
  'RAL 2001': '#C93C20',
  'RAL 2002': '#CB2821',
  'RAL 2003': '#FF7514',
  'RAL 2004': '#F44611',
  'RAL 2005': '#FF2301',
  'RAL 2007': '#FFA420',
  'RAL 2008': '#F75E25',
  'RAL 2009': '#F54021',
  'RAL 2010': '#D84B20',
  'RAL 2011': '#EC7C26',
  'RAL 2012': '#E55137',
  'RAL 2013': '#C35831',
  'RAL 3000': '#AF2B1E',
  'RAL 3001': '#A52019',
  'RAL 3002': '#A2231D',
  'RAL 3003': '#9B111E',
  'RAL 3004': '#75151E',
  'RAL 3005': '#5E2129',
  'RAL 3007': '#412227',
  'RAL 3009': '#642424',
  'RAL 3011': '#781F19',
  'RAL 3012': '#C1876B',
  'RAL 3013': '#A12312',
  'RAL 3014': '#D36E70',
  'RAL 3015': '#EA899A',
  'RAL 3016': '#B32821',
  'RAL 3017': '#E63244',
  'RAL 3018': '#D53032',
  'RAL 3020': '#CC0605',
  'RAL 3022': '#D95030',
  'RAL 3024': '#F80000',
  'RAL 3026': '#FE0000',
  'RAL 3027': '#C51D34',
  'RAL 3028': '#CB3234',
  'RAL 3031': '#B32428',
  'RAL 3032': '#721422',
  'RAL 3033': '#B44C43',
  'RAL 4001': '#6D3F5B',
  'RAL 4002': '#922B3E',
  'RAL 4003': '#DE4C8A',
  'RAL 4004': '#641C34',
  'RAL 4005': '#6C4675',
  'RAL 4006': '#A03472',
  'RAL 4007': '#4A192C',
  'RAL 4008': '#924E7D',
  'RAL 4009': '#A18594',
  'RAL 4010': '#CF3476',
  'RAL 4011': '#8673A1',
  'RAL 4012': '#6C6874',
  'RAL 5000': '#354D73',
  'RAL 5001': '#1F3438',
  'RAL 5002': '#20214F',
  'RAL 5003': '#1D1E33',
  'RAL 5004': '#18171C',
  'RAL 5005': '#1E2460',
  'RAL 5007': '#3E5F8A',
  'RAL 5008': '#26252D',
  'RAL 5009': '#025669',
  'RAL 5010': '#0E294B',
  'RAL 5011': '#231A24',
  'RAL 5012': '#3B83BD',
  'RAL 5013': '#1E213D',
  'RAL 5014': '#606E8C',
  'RAL 5015': '#2271B3',
  'RAL 5017': '#063971',
  'RAL 5018': '#3F888F',
  'RAL 5019': '#1B5583',
  'RAL 5020': '#1D334A',
  'RAL 5021': '#256D7B',
  'RAL 5022': '#252850',
  'RAL 5023': '#49678D',
  'RAL 5024': '#5D9B9B',
  'RAL 5025': '#2A6478',
  'RAL 5026': '#102C54',
  'RAL 6000': '#316650',
  'RAL 6001': '#287233',
  'RAL 6002': '#2D572C',
  'RAL 6003': '#424632',
  'RAL 6004': '#1F3A3D',
  'RAL 6005': '#2F4538',
  'RAL 6006': '#3E3B32',
  'RAL 6007': '#343B29',
  'RAL 6008': '#39352A',
  'RAL 6009': '#31372B',
  'RAL 6010': '#35682D',
  'RAL 6011': '#587246',
  'RAL 6012': '#343E40',
  'RAL 6013': '#6C7156',
  'RAL 6014': '#47402E',
  'RAL 6015': '#3B3C36',
  'RAL 6016': '#1E5945',
  'RAL 6017': '#4C9141',
  'RAL 6018': '#57A639',
  'RAL 6019': '#BDECB6',
  'RAL 6020': '#2E3A23',
  'RAL 6021': '#89AC76',
  'RAL 6022': '#25221B',
  'RAL 6024': '#308446',
  'RAL 6025': '#3D642D',
  'RAL 6026': '#015D52',
  'RAL 6027': '#84C3BE',
  'RAL 6028': '#2C5545',
  'RAL 6029': '#20603D',
  'RAL 6032': '#317F43',
  'RAL 6033': '#497E76',
  'RAL 6034': '#7FB5B5',
  'RAL 6035': '#1C542D',
  'RAL 6036': '#193737',
  'RAL 6037': '#008F39',
  'RAL 6038': '#00BB2D',
  'RAL 7000': '#78858B',
  'RAL 7001': '#8A9597',
  'RAL 7002': '#7E7B52',
  'RAL 7003': '#6C7059',
  'RAL 7004': '#969992',
  'RAL 7005': '#646B63',
  'RAL 7006': '#6D6552',
  'RAL 7008': '#6A5F31',
  'RAL 7009': '#4D5645',
  'RAL 7010': '#4C514A',
  'RAL 7011': '#434B4D',
  'RAL 7012': '#4E5754',
  'RAL 7013': '#464531',
  'RAL 7015': '#434750',
  'RAL 7016': '#293133',
  'RAL 7021': '#23282B',
  'RAL 7022': '#332F2C',
  'RAL 7023': '#686C5E',
  'RAL 7024': '#474A51',
  'RAL 7026': '#2F353B',
  'RAL 7030': '#8B8C7A',
  'RAL 7031': '#474B4E',
  'RAL 7032': '#B8B799',
  'RAL 7033': '#7D8471',
  'RAL 7034': '#8F8B66',
  'RAL 7035': '#D7D7D7',
  'RAL 7036': '#7F7679',
  'RAL 7037': '#7D7F7D',
  'RAL 7038': '#B5B8B1',
  'RAL 7039': '#6C6960',
  'RAL 7040': '#9DA1AA',
  'RAL 7042': '#8D948D',
  'RAL 7043': '#4E5452',
  'RAL 7044': '#CAC4B0',
  'RAL 7045': '#909090',
  'RAL 7046': '#82898F',
  'RAL 7047': '#D0D0D0',
  'RAL 7048': '#898176',
  'RAL 8000': '#826C34',
  'RAL 8001': '#955F20',
  'RAL 8002': '#6C3B2A',
  'RAL 8003': '#734222',
  'RAL 8004': '#8E402A',
  'RAL 8007': '#59351F',
  'RAL 8008': '#6F4F28',
  'RAL 8011': '#5B3A29',
  'RAL 8012': '#592321',
  'RAL 8014': '#382C1E',
  'RAL 8015': '#633A34',
  'RAL 8016': '#4C2F27',
  'RAL 8017': '#45322E',
  'RAL 8019': '#403A3A',
  'RAL 8022': '#212121',
  'RAL 8023': '#A65E2E',
  'RAL 8024': '#79553D',
  'RAL 8025': '#755C48',
  'RAL 8028': '#4E3B31',
  'RAL 8029': '#763C28',
  'RAL 9001': '#FDF4E3',
  'RAL 9002': '#E7EBDA',
  'RAL 9003': '#F4F4F4',
  'RAL 9004': '#282828',
  'RAL 9005': '#0A0A0A',
  'RAL 9006': '#A5A5A5',
  'RAL 9007': '#8F8F8F',
  'RAL 9010': '#FFFFFF',
  'RAL 9011': '#1C1C1C',
  'RAL 9016': '#F6F6F6',
  'RAL 9017': '#1E1E1E',
  'RAL 9018': '#D7D7D7',
  'RAL 9022': '#9C9C9C',
  'RAL 9023': '#828282',
};
