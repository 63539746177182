import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

class TextButton extends React.Component {
  handleClick = () => {
    const { selectChoice, choice } = this.props;
    selectChoice(choice.value);
  }

  render() {
    const { isSelected, withIcon, choice } = this.props;
    const selectedClass = isSelected ? ' selected' : '';
    const withIconClass = withIcon ? ' with-icon' : '';

    return (
      <Button
        className={`type-selector-button text-selector${selectedClass}${withIconClass}`}
        onClick={this.handleClick}
        disabled={choice.disabled}
      >
        {
          withIcon
          && (
            <i className={`icon ${choice.iconName}`} />
          )
        }
        {choice.label}
      </Button>
    );
  }
}

TextButton.propTypes = {
  choice: PropTypes.shape().isRequired,
  withIcon: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectChoice: PropTypes.func.isRequired,
};

export default TextButton;
