import Dashboard from 'routes/Dashboard/Home';
import Page404 from 'routes/Page404/Page404';
import Login from 'routes/Login/Login';
import Configurator from 'routes/Configurator/Configurator';

export default {
  Dashboard,
  Page404,
  Login,
  Configurator,
};
