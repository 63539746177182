import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { selectWorkingWing } from 'actions/canvasActions';
import { ACTIVE_WING, PASSIVE_WING } from 'consts/consts';

const WingChooser = ({ selectedWorkingWing, dispatchSelectWorkingWing, t }) => (
  <div className="wing-chooser" style={{ padding: '30px 0 12px' }}>
    <Radio.Group
      value={selectedWorkingWing}
      buttonStyle="solid"
      onChange={(e) => {
        dispatchSelectWorkingWing(e.target.value);
      }}
    >
      <Radio.Button value={ACTIVE_WING}>{t('stepper.common.wingchooser.active')}</Radio.Button>
      <Radio.Button value={PASSIVE_WING}>{t('stepper.common.wingchooser.passive')}</Radio.Button>
    </Radio.Group>
  </div>
);

WingChooser.propTypes = {
  selectedWorkingWing: PropTypes.string.isRequired,
  dispatchSelectWorkingWing: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  selectedWorkingWing: store.selectedOptions.selectedWorkingWing,
});

const mapDispatchToProps = {
  dispatchSelectWorkingWing: selectWorkingWing,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WingChooser));
