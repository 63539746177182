import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falElottFutoKetszarnyu = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorPassiveWallOpeningWidth,
    doorWallOpeningHeight,
    selectedOpeningDirection,
  } = state;

  // Canvas width - TODO: Ez kell a középre igazításhoz
  const canvasWidth = 470;

  // Aktv ajtó
  const activeDoorWidth = doorActiveWallOpeningWidth - 5;

  // Passzív ajtó
  const passiveDoorWidth = doorPassiveWallOpeningWidth - 18;

  // Ajtólap külméret | Y - 35
  const doorWidth = activeDoorWidth + passiveDoorWidth;

  // Ajtólap külméret | X - 25
  const doorHeight = doorWallOpeningHeight - 25;

  // Ajtólap felső pozíciója
  const doorShapePositionTop = (doorWallOpeningHeight - 26) - (doorWallOpeningHeight - 15);

  // Ajtólap magassága
  const doorShapeHeight = (doorHeight - doorShapePositionTop) + 5;

  // Ajtókeret vastagsága
  const frameThickness = ((doorActiveWallOpeningWidth + config.falElottFuto.frameThickness[0]) - (doorActiveWallOpeningWidth - config.falElottFuto.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 425) - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  const doorLeftPosition = (2400 - doorWidth) / 2;

  // Karnis belméret | 2 x (Y + 26)
  const corniceWidth = 2 * ((activeDoorWidth + passiveDoorWidth) + 26);

  // Karnis magasság
  const corniceHeight = 140;

  // Karnis top pozíciója
  const cornicePositionTop = doorTopPosition - (corniceHeight / 2);

  const corniceShape = new fabric.Rect({
    name: 'corniceShape',
    doorElement: 'corniceShape',
    width: corniceHeight,
    height: corniceWidth,
    layerIndex: config.falElottFuto.layerOrders.corniceShapeLayerIndex,
    strokeWidth: 0,
    angle: 90,
    left: (doorLeftPosition - frameThickness) + (corniceWidth - canvasWidth),
    top: cornicePositionTop,
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 0,
      offsetY: 10,
      blur: 14,
    },
    fill: '#8a6930',
  });

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    width: activeDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.falElottFuto.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness + passiveDoorWidth
      : doorLeftPosition + frameThickness,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    fill: '#8a6930',
  });

  const doorShapePassive = new fabric.Rect({
    name: 'doorShapePassive',
    doorElement: 'doorShapePassive',
    width: passiveDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.falElottFuto.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness
      : doorLeftPosition + frameThickness + activeDoorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    fill: '#8a6930',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  const corniceShapeOuterShadowTop = new fabric.Rect({
    name: 'corniceShapeOuterShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth,
    top: cornicePositionTop,
    left: doorLeftPosition - frameThickness - canvasWidth,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 0,
      offsetY: -5,
      blur: 10,
    },
  });

  const corniceShapeOuterShadowLeft = new fabric.Rect({
    name: 'corniceShapeOuterShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth / 2,
    top: cornicePositionTop,
    left: doorLeftPosition - frameThickness - canvasWidth,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const corniceShapeOuterShadowRight = new fabric.Rect({
    name: 'corniceShapeOuterShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth / 2,
    top: cornicePositionTop,
    left: (doorLeftPosition - frameThickness) + ((corniceWidth / 2)) - canvasWidth,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    name: 'doorInnerShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 1,
      blur: 8,
    },
  });

  const twoDoorInnerShadowTop = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'twoDoorInnerShadowTop',
    layerIndex: 4,
    opacity: 1,
    height: doorHeight + frameThickness,
    width: doorWidth,
    top: doorTopPosition,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: -1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorShapeHeight) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    name: 'doorInnerShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    name: 'doorInnerShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + doorWidth,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition + doorShapePositionTop,
    width: doorWidth + (frameThickness * 2),
    height: doorShapeHeight + frameThickness,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  const corniceFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'corniceFrameShadow',
    layerIndex: 121,
    left: doorLeftPosition - frameThickness - canvasWidth,
    top: cornicePositionTop,
    width: corniceWidth,
    height: corniceHeight,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  corniceFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    corniceShape, doorShape, doorShapePassive, leftFrame, rightFrame, topFrame,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow, corniceFrameShadow, corniceShapeOuterShadowLeft, corniceShapeOuterShadowRight,
    corniceShapeOuterShadowTop, twoDoorInnerShadowTop,
  ];
};

export default falElottFutoKetszarnyu;
