import React from 'react';
import ThumbNail from 'assets/thumbnail.svg';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import './dashboard-header.scss';

const DashboardHeader = (props) => (
  <div className="dashboard-header-container">
    <div className="dashboard-header-content-container">
      <a aria-label="https://full.co.hu" href="https://full.co.hu">
        <ThumbNail />
      </a>
      <LanguageSelector {...props} />
    </div>
  </div>
);

export default DashboardHeader;
