const doorDesignConfig = {
  blende: 250,
  doorWallOpeningWidthExtra: 400,
  doorWallOpeningHeightExtra: 300,
  doorFrameShadow: {
    fill: '#818286',
    opacity: 0.1,
  },
  doorFrameOuterShadow: {
    color: 'rgba(129, 130, 134, 0.6)',
  },
  doorFrameInnerShadow: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  shadowBackgroundColor: '#818286',

  falcolt: {
    doorWidthOuterSize: 35,
    doorHeightOuterSize: 25,
    doorShapePositionTop: [26, 15],
    frameThickness: [67, 30],

    layerOrders: {
      doorShapeLayerIndex: 9,
      doorInnerShadowLayerIndex: 8,
      frameLayerIndex: 7,
      doorOuterShadowLayerIndex: 6,
      windowShapeLayerIndex: 8,
      windowFrameLayerIndex: 9,
      glassClampingLayerIndex: 10,
    },
  },

  stumpf: {
    doorWidthOuterSize: 60,
    doorHeightOuterSize: 40,
    frameThickness: [68, 52],

    layerOrders: {
      doorShapeLayerIndex: 6,
      doorInnerShadowLayerIndex: 8,
      frameLayerIndex: 10,
      doorOuterShadowLayerIndex: 9,
      windowShapeLayerIndex: 9,
      windowFrameLayerIndex: 10,
      glassClampingLayerIndex: 11,
    },
  },

  tokbanyilo: {
    doorWidthOuterSize: 96,
    doorHeightOuterSize: 55,
    frameThickness: [32, 88],

    layerOrders: {
      doorShapeLayerIndex: 6,
      doorInnerShadowLayerIndex: 8,
      frameLayerIndex: 10,
      doorOuterShadowLayerIndex: 9,
      windowShapeLayerIndex: 8,
      glassClampingLayerIndex: 9,
      caseSplitterLayerIndex: 9,
    },
  },

  falElottFuto: {
    doorWidthOuterSize: 20,
    doorHeightOuterSize: 25,
    frameThickness: [67, 30],

    layerOrders: {
      corniceShapeLayerIndex: 122,
      doorShapeLayerIndex: 10,
      doorInnerShadowLayerIndex: 9,
      frameLayerIndex: 8,
      doorOuterShadowLayerIndex: 6,
      windowShapeLayerIndex: 9,
      windowFrameLayerIndex: 10,
      glassClampingLayerIndex: 11,
    },
  },

  falbanFuto: {
    frameThickness: [125, 5],

    layerOrders: {
      doorCaseInnerLayerIndex: 10,
      doorShapeLayerIndex: 6,
      doorInnerShadowLayerIndex: 11,
      frameLayerIndex: 13,
      doorOuterShadowLayerIndex: 12,
    },
  },
};

export default doorDesignConfig;
