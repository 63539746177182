import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  SET_PROPERTIES,
  CONFIGURATOR_SELECT_DOOR_ACCESSORY,
  CONFIGURATOR_SELECT_DOOR_FAN,
} from 'consts/actionTypes';
import { DOOR_FAN } from 'consts/consts';
import { patterns } from 'models';
import { getGlassImage } from 'utils/images';
import { renderCanvas } from './canvasSagas';

const getAvailableOptionsStore = (store) => store.availableOptions;

export function* selectDoorAccessoryOld(action) {
  const { selectedDoorAccessory } = action.payload;

  yield put({
    type: SET_PROPERTIES,
    payload: {
      selectedDoorAccessory,
    },
  });

  yield call(renderCanvas);
}

export function* selectDoorAccessory(action) {
  try {
    const { selectedDoorAccessory } = action.payload;
    const image = patterns.getPattern(selectedDoorAccessory);

    // DOWNLOAD PATTERN IF NECESSARY
    if (!image) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedImage = availableOptionsStore.doorAccessory[selectedDoorAccessory];

      // DOWNLOAD PATTERN / SKIP IF IT'S A RAL COLOR
      if (selectedImage.relationships.fch_attached_image.data) {
        const selectedAccesoryImage = availableOptionsStore.doorAccessoryAttachedImage[selectedImage.relationships.fch_attached_image.data.id];
        const doorAccessoryImage = yield call(getGlassImage, selectedAccesoryImage.attributes.uri.url);
        patterns.setPatterns([
          { name: `${selectedDoorAccessory}`, value: doorAccessoryImage },
        ]);
      }
    }

    yield put({
      type: SET_PROPERTIES,
      payload: action.payload,
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorFan(action) {
  try {
    const { selectedDoorFanOption } = action.payload;
    const image = patterns.getPattern(DOOR_FAN);

    // DOWNLOAD PATTERN IF NECESSARY
    if (selectedDoorFanOption && !image) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedImage = availableOptionsStore.doorAccessory[DOOR_FAN];

      // DOWNLOAD PATTERN / SKIP IF IT'S A RAL COLOR
      if (selectedImage.relationships.fch_attached_image.data) {
        const selectedAccesoryImage = availableOptionsStore.doorAccessoryAttachedImage[selectedImage.relationships.fch_attached_image.data.id];
        const doorAccessoryImage = yield call(getGlassImage, selectedAccesoryImage.attributes.uri.url);
        patterns.setPatterns([
          { name: DOOR_FAN, value: doorAccessoryImage },
        ]);
      }
    }

    yield put({
      type: SET_PROPERTIES,
      payload: action.payload,
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export default [
  takeEvery(CONFIGURATOR_SELECT_DOOR_ACCESSORY, selectDoorAccessory),
  takeEvery(CONFIGURATOR_SELECT_DOOR_FAN, selectDoorFan),
];
