import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Login extends Component {
  componentDidMount() {
    document.title = 'Login | Full ajtótervező';
  }

  render() {
    const { t } = this.props;
    return (
      <div className="page page-login">
        <p>{t('pages.login.page_buttons_label.login')}</p>
      </div>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Login);
