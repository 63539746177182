import baseDoorProps from './baseDoor';

const initialState = {
  doorActiveWallOpeningWidth: 900,
  doorPassiveWallOpeningWidth: 600,
  doorWallOpeningHeight: 2100,
  doorBlendeHeight: 250,
  doorActiveWallOpeningWidthExtra: 400,
  doorWallOpeningHeightExtra: 300,
  selectedDoorAccessory: '6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0',
  canvasDoorAccessory: baseDoorProps.canvasDoorAccessory,
  isFetchingPdf: false,
  isFetchingJpg: false,
  isFetchingUrl: false,
  doorId: null,
  isFetchingDoor: false,
};

/*
  wallMultiplyImage: null,
  wallScreenImage: null,
  floorScreenImage: null,
  floorMultiplyImage: null,
  locationImage: null,
  selectedDoor: [],
  selectedActiveDoorPatterns: [],
  selectedPassiveDoorPatterns: [],
  selectedBaseGlassPattern: null,
  selectedActiveDoorGlasses: [],
  selectedPassiveDoorGlasses: [],
  selectedActiveDoorGlassBorders: [],
  selectedPassiveDoorGlassBorders: [],
  selectedDoorAccessory: null,
  selectedDoorFan: null,
  doorActivePatternDirection: 'V',
  doorPassivePatternDirection: 'H',
  doorActivePattern: '#8a6930',
  doorPassivePattern: '#8a6930',
  framePattern: '#8a6930',
  designPattern: '#8a6930',
  doorWindowLayerActivePattern: '#8a6930',
  doorWindowLayerPassivePattern: '#8a6930',
  doorActiveInlayPattern: '#8a6930',
  doorPassiveInlayPattern: '#8a6930',
  doorActiveInlay2Pattern: '#8a6930',
  doorPassiveInlay2Pattern: '#8a6930',
 */

export default initialState;
