import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { animationDelay, convertRalToHex } from 'utils/utils';
import './colorpalette.scss';

class ColorPalette extends React.Component {
  state = {};

  render() {
    const { index, colorList, t } = this.props;

    return (
      <div
        className="color-palette"
        style={{
          animationDelay: animationDelay(index),
        }}
      >
        <div className="configurator-block-title">
          {t('stepper.step_1.page_buttons_label.write_here_the_color')}
        </div>
        <div className="color-palette-colors">
          {
            colorList.map((color) => {
              const { selected } = this.props;
              const isSelectedColor = color.value === selected ? ' selected' : '';

              return (
                <Button
                  key={color.key}
                  className="palette-color"
                  onClick={() => {
                    const { changeSelectedColor, type } = this.props;
                    changeSelectedColor(type, color.value, color.key);
                  }}
                >
                  <div
                    className={`palette-color-preview${isSelectedColor}`}
                    style={{ backgroundColor: convertRalToHex(color.key) }}
                  />
                  <div className="palette-color-rar-text">RAL</div>
                  <div className="palette-color-rar-code">{color.key.substr(4, 4)}</div>
                </Button>
              );
            })
          }
        </div>
      </div>
    );
  }
}

ColorPalette.defaultProps = {
  index: 0,
  selected: null,
};

ColorPalette.propTypes = {
  index: PropTypes.number,
  selected: PropTypes.string,
  changeSelectedColor: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  colorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ColorPalette);
