export const ACTIVE_WING = 'ACTIVE_WING';
export const PASSIVE_WING = 'PASSIVE_WING';
export const RIGHT_OPENING_DIRECTION = '291bb030-7706-4e97-a686-9fd412f5ddd4';
export const TOKBANYILO_DOOR_DESIGN = '17128eb2-d81d-4650-8e19-769b3d78943d';
export const FESTETT_DOOR_FAMILY = '44983000-e1d8-496c-b47c-0248388f18b0';
export const DOOR_FAN = '248dc529-6c9b-4f62-963f-889299b68425';
export const COLOR_CPL = 'e8cba398-fee2-4c74-bbf4-6e5a10abe81f';
export const COLOR_RAL = '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04';

export const doorFrameElementNames = [
  'frame',
  'doorShapeBlende',
  'corniceShape',
  'caseSplitterShape',
];

export const doorDesignNames = {
  '5235e54e-8698-4d13-9f00-99098bf929e5': 'falcolt',
  '778dc6df-03a3-440c-869e-82e0936ed6b4': 'stumpf',
  '17128eb2-d81d-4650-8e19-769b3d78943d': 'tokbanyilo',
  'e5e8f1f5-bc60-4773-bd51-9f984da3f0da': 'fal-elott-futo',
  'ea7d7e0f-49a3-4569-95ad-3440a2489d56': 'falban-futo',
};

export const doorDesignExtraNames = {
  '2748d41f-7591-4dc3-9719-643a907fd04b': '-alap',
  '14b18b80-1e0d-43a2-8c33-54d70adecc74': '-fix-blende',
  '0f676369-6af5-4195-9e03-eff49e7d29d0': '-osztott-blende',
  '98b3d677-7bd9-4d2f-b145-b07e69815cc3': '-sorolt-oldalvilagito',
  '5f2f79b6-412c-46bb-befe-cc9cc1624445': '-sorolt-felulvilagito',
  '100539b5-679e-418a-a470-e3be9e65bc89': '-tokosztos-felulvilagito',
};

export const inlay2Instances = [
  'dca32ad3-dd32-42f3-93c9-5a8e9403c14e',
  'c4bbc13a-5840-4c2f-ba2f-b4f493808707',
  '67338c87-6d48-4418-b5cb-e14fd8e0c03a',
  'b308496f-f650-4d2a-b67d-293e64e54ba1',
  '447c018b-6f8e-4e13-8161-2a8ef183cb73',
  '6680d67f-c026-4315-900f-fa4a4ec1b9c4',
  'ad42bf47-782a-4f90-9993-f32d89a17bcb',
  '6e338929-d65c-48b6-a8dd-867b2e2f3262',
];

export const slidingDoorFamilies = [
  'ea7d7e0f-49a3-4569-95ad-3440a2489d56',
  'e5e8f1f5-bc60-4773-bd51-9f984da3f0da',
];

export const NODE_DOOR_FAMILY = '/node/door_family';
export const TAXONOMY_TERM_DOOR_LOCATION = '/taxonomy_term/door_location';
export const TAXONOMY_TERM_DOOR_WING_TYPE = '/taxonomy_term/door_wing_type';
export const TAXONOMY_TERM_DOOR_OPENING_TYPE = '/taxonomy_term/door_opening_type';
export const NODE_DOOR_DESIGN = '/node/door_design';
export const NODE_DOOR_DESIGN_EXTRA = '/node/door_design_extra';
export const TAXONOMY_TERM_DOOR_OPENING_DIRECTION = '/taxonomy_term/door_opening_direction';
export const NODE_DOOR_ACCESSORIES = '/node/door_accessories';
export const NODE_DOOR_PATTERN = '/node/door_pattern';
export const NODE_DOOR_PATTERN_INSTANCE = '/node/door_pattern_instance';
export const TAXONOMY_TERM_DOOR_GLAZING_TYPE = '/taxonomy_term/door_glazing_type';
export const NODE_DOOR_GLASS_PATTERN = '/node/door_glass_pattern';
export const TAXONOMY_TERM_AVAILABLE_COLORS = '/taxonomy_term/available_colors';
export const METAL_STRIPE_AVAILABLE_COLORS = '/taxonomy_term/metal_stripe_colors';
export const NODE_DOOR_GLAZING = '/node/door_glazing';
