import React from 'react';
import PropTypes from 'prop-types';
import { animationDelay } from 'utils/utils';
import TextButton from './TextButton/TextButton';
import ImageButton from './ImageButton/ImageButton';
import './typeselector.scss';

class TypeSelector extends React.Component {
  renderTextButtons = (choices) => {
    const { selectedChoice, withIcon, onChange } = this.props;
    return choices.map((choice) => (
      <TextButton
        key={choice.value}
        choice={choice}
        isSelected={selectedChoice === choice.value}
        selectChoice={(value) => { onChange(value); }}
        withIcon={withIcon}
      />
    ));
  };

  renderImageButtons = (choices) => {
    const { selectedChoice, onChange } = this.props;
    return Object.keys(choices).map((key) => (
      <ImageButton
        key={key}
        choice={choices[key]}
        isSelected={selectedChoice === choices[key].value}
        selectChoice={(value) => { onChange(value); }}
      />
    ));
  };

  renderChoices = (type, choices) => {
    switch (type) {
      case 'text': {
        return this.renderTextButtons(choices);
      }

      case 'image': {
        return this.renderImageButtons(choices);
      }

      default: return null;
    }
  }

  render() {
    const { isActive, index, title, choiceType, choices } = this.props;
    const isSelected = isActive !== true ? ' inactive' : '';

    return (
      <div
        className={`type-selector${isSelected}`}
        style={{
          animationDelay: animationDelay(index),
        }}
      >
        <div className="configurator-block-title">
          {title}
        </div>

        <div className="type-selector-choices">
          {this.renderChoices(choiceType, choices)}
        </div>
      </div>
    );
  }
}

TypeSelector.defaultProps = {
  index: 0,
  choices: [],
  choiceType: 'text',
  selectedChoice: null,
  isActive: true,
  withIcon: false,
};

TypeSelector.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string.isRequired,
  selectedChoice: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  choices: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    label: PropTypes.string,
    disabled: PropTypes.bool,
  })),
  choiceType: PropTypes.string,
  isActive: PropTypes.bool,
  withIcon: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default TypeSelector;
