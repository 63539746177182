import { takeEvery, call, put, delay, select } from 'redux-saga/effects';
import * as API from 'services/api';
import moment from 'moment';
import {
  CONFIGURATOR_GET_PDF_REQUESTED,
  CONFIGURATOR_GET_PDF_SUCCEEDED,
  CONFIGURATOR_GET_PDF_FAILED,
  CONFIGURATOR_GET_JPG_REQUESTED,
  CONFIGURATOR_GET_JPG_SUCCEEDED,
  CONFIGURATOR_GET_JPG_FAILED,
  CONFIGURATOR_GET_URL_REQUESTED,
  CONFIGURATOR_GET_URL_SUCCEEDED,
  CONFIGURATOR_GET_URL_FAILED,
} from 'consts/actionTypes';
import { frontCanvas } from 'models';
import { host } from 'services/config';

const getSelectedOptionsStore = (store) => store.selectedOptions;

export function* authorize() {
  try {
    const token = yield call(API.login);
    return token;
  } catch (error) {
    console.log(error); // eslint-disable-line
    throw error;
  }
}

export const getBase64ImageString = () => {
  const canvas = frontCanvas.getCanvas();
  const originalHeight = canvas.getHeight();
  const originalWidth = canvas.getWidth();

  // Prepare canvas for BASE64 image string
  canvas.setHeight(470);
  canvas.setWidth(470);
  const previewImage = canvas.toDataURL().substring(22);

  // Reset canvas sizes
  canvas.setHeight(originalHeight);
  canvas.setWidth(originalWidth);

  return previewImage;
};

const generateDoorData = (selectedStore, imageId) => {
  const {
    selectedDoorFamily,
    selectedLocation,
    selectedWallColor,
    selectedFloorColor,
    selectedDoorWingType,
    selectedDoorDesign,
    selectedDoorDesignExtra,
    selectedDoorOpeningDirection,
    selectedDoorPatternActive,
    selectedDoorPatternPassive,
    selectedDoorPatternInstanceActive,
    selectedDoorPatternInstancePassive,
    selectedWindowGlassPattern,
    selectedDoorGlazingTypeActive,
    selectedDoorGlazingActive,
    selectedDoorGlassPatternActive,
    selectedDoorGlazingTypePassive,
    selectedDoorGlazingPassive,
    selectedDoorGlassPatternPassive,
    selectedDoorFrameColor,
    selectedDoorBodyColorActive,
    selectedDoorBodyColorPassive,
    selectedDoorInlay1ColorActive,
    selectedDoorInlay1ColorPassive,
    selectedDoorInlay2ColorActive,
    selectedDoorInlay2ColorPassive,
    selectedDoorAccessory,
    selectedDoorActiveMetalStripeColor,
    selectedDoorPassiveMetalStripeColor,
  } = selectedStore;

  const relationships = {
    fch_door_family: {
      data: {
        type: 'node--door_family',
        id: selectedDoorFamily,
      },
    },
    fch_door_location: {
      data: {
        type: 'taxonomy_term--door_location',
        id: selectedLocation,
      },
    },
    fch_wall_color: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedWallColor,
      },
    },
    fch_floor_color: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedFloorColor,
      },
    },
    fch_door_wing_type: {
      data: {
        type: 'taxonomy_term--door_wing_type',
        id: selectedDoorWingType,
      },
    },
    fch_door_design: {
      data: {
        type: 'node--door_design',
        id: selectedDoorDesign,
      },
    },
    fch_design_extra: {
      data: {
        type: 'node--door_design_extra',
        id: selectedDoorDesignExtra,
      },
    },
    fch_door_opening_direction: {
      data: {
        type: 'taxonomy_term--door_opening_direction',
        id: selectedDoorOpeningDirection,
      },
    },
    fch_door_pattern: {
      data: {
        type: 'node--door_pattern',
        id: selectedDoorPatternActive,
      },
    },
    fch_door_pattern_passive: {
      data: {
        type: 'node--door_pattern',
        id: selectedDoorPatternPassive,
      },
    },
    fch_door_pattern_instance: {
      data: {
        type: 'node--door_pattern_instance',
        id: selectedDoorPatternInstanceActive,
      },
    },
    fch_door_pattern_instance_passiv: {
      data: {
        type: 'node--door_pattern_instance',
        id: selectedDoorPatternInstancePassive,
      },
    },
    fch_door_glass_pattern_sidelite: {
      data: {
        type: 'node--door_glass_pattern',
        id: selectedWindowGlassPattern,
      },
    },
    fch_door_glazing_type: {
      data: {
        type: 'taxonomy_term--door_glazing_type',
        id: selectedDoorGlazingTypeActive,
      },
    },
    fch_door_glazing: {
      data: {
        type: 'node--door_glazing',
        id: selectedDoorGlazingActive,
      },
    },
    fch_door_glass_pattern: {
      data: {
        type: 'node--door_glass_pattern',
        id: selectedDoorGlassPatternActive,
      },
    },
    fch_door_glazing_type_passive: {
      data: {
        type: 'taxonomy_term--door_glazing_type',
        id: selectedDoorGlazingTypePassive,
      },
    },
    fch_door_glazing_passive: {
      data: {
        type: 'node--door_glazing',
        id: selectedDoorGlazingPassive,
      },
    },
    fch_door_glass_pattern_passive: {
      data: {
        type: 'node--door_glass_pattern',
        id: selectedDoorGlassPatternPassive,
      },
    },
    fch_door_case_color: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorFrameColor,
      },
    },
    fch_door_basic_color: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorBodyColorActive,
      },
    },
    fch_door_basic_color_passive: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorBodyColorPassive,
      },
    },
    fch_intarsia_color: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorInlay1ColorActive,
      },
    },
    fch_intarsia_color_passive: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorInlay1ColorPassive,
      },
    },
    fch_molding_color: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorInlay2ColorActive,
      },
    },
    fch_molding_color_passive: {
      data: {
        type: 'taxonomy_term--available_colors',
        id: selectedDoorInlay2ColorPassive,
      },
    },
    fch_door_accessories: {
      data: {
        type: 'node--door_accessories',
        id: selectedDoorAccessory,
      },
    },
    fch_door_preview: {
      data: {
        type: 'file--image',
        id: imageId.uuid,
      },
    },
    fch_metal_stripe_color: {
      data: {
        type: 'taxonomy_term--metal_stripe_colors',
        id: selectedDoorActiveMetalStripeColor,
      },
    },
    fch_metal_stripe_color_passive: {
      data: {
        type: 'taxonomy_term--metal_stripe_colors',
        id: selectedDoorPassiveMetalStripeColor,
      },
    },
  };

  if (selectedDoorGlazingTypeActive === null) {
    delete relationships.fch_door_glazing;
    delete relationships.fch_door_glazing_type;
    delete relationships.fch_door_glass_pattern;
  }

  if (selectedDoorGlazingTypePassive === null) {
    delete relationships.fch_door_glazing_passive;
    delete relationships.fch_door_glazing_type_passive;
    delete relationships.fch_door_glass_pattern_passive;
  }

  return {
    data: {
      type: 'node--door',
      attributes: {
        title: `Új ajtó ${moment().format('YYYY-MM-DD')}`,
        fch_status: 1,
      },
      relationships,
    },
  };
};

export const downloadFile = (href) => {
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('target', '_blank');
  document.body.appendChild(link);
  link.click();
};

function* getPdfExport() {
  try {
    const store = yield select(getSelectedOptionsStore);
    const token = yield call(authorize);
    const imageId = yield call(API.savePdfPreview, getBase64ImageString(), token);
    const doorData = yield call(generateDoorData, store, imageId);
    const createdDoor = yield call(API.createDoor, doorData, token);
    const pdfResponse = yield call(API.getPdfUrl, createdDoor.data.id, token);
    downloadFile(`${host}${pdfResponse.data.pdf.uri}`);
    yield delay(2000);
    yield put({
      type: CONFIGURATOR_GET_PDF_SUCCEEDED,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: CONFIGURATOR_GET_PDF_FAILED,
    });
  }
}

function* getJpgExport() {
  try {
    const canvas = frontCanvas.getCanvas();
    const image = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    link.href = image;
    link.download = 'door.jpg';
    document.body.appendChild(link);
    link.click();
    yield delay(2000);
    yield put({
      type: CONFIGURATOR_GET_JPG_SUCCEEDED,
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: CONFIGURATOR_GET_JPG_FAILED,
    });
  }
}

function* getUrl() {
  try {
    const store = yield select(getSelectedOptionsStore);
    const token = yield call(authorize);
    const imageId = yield call(API.savePdfPreview, getBase64ImageString(), token);
    const doorData = yield call(generateDoorData, store, imageId);
    const createdDoor = yield call(API.saveDoor, doorData);
    yield delay(2000);
    yield put({
      type: CONFIGURATOR_GET_URL_SUCCEEDED,
      payload: {
        id: createdDoor.data.id,
      },
    });
  } catch (error) {
    console.log(error); // eslint-disable-line
    yield put({
      type: CONFIGURATOR_GET_URL_FAILED,
    });
  }
}

export default [
  takeEvery(CONFIGURATOR_GET_PDF_REQUESTED, getPdfExport),
  takeEvery(CONFIGURATOR_GET_JPG_REQUESTED, getJpgExport),
  takeEvery(CONFIGURATOR_GET_URL_REQUESTED, getUrl),
];
