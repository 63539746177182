import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  SET_PROPERTIES,
  CONFIGURATOR_SELECT_DOOR_FRAME_COLOR,
  CONFIGURATOR_SELECT_DOOR_BODY_COLOR,
  CONFIGURATOR_SELECT_DOOR_ACTIVE_METAL_STRIPE_COLOR,
  CONFIGURATOR_SELECT_DOOR_INLAY1_COLOR,
  CONFIGURATOR_SELECT_DOOR_INLAY2_COLOR, CONFIGURATOR_SELECT_DOOR_PASSIVE_METAL_STRIPE_COLOR,
} from 'consts/actionTypes';
import { patterns } from 'models';
import { getGlassImage } from 'utils/images';
import { ACTIVE_WING } from 'consts/consts';
import { renderCanvas } from './canvasSagas';

const getAvailableOptionsStore = (store) => store.availableOptions;

export function* selectDoorFrameColor(action) {
  try {
    const { selectedDoorFrameColor } = action.payload;
    const pattern = patterns.getPattern(selectedDoorFrameColor);

    // DOWNLOAD PATTERN IF NECESSARY
    if (!pattern) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedPattern = availableOptionsStore.doorAvailableColors[selectedDoorFrameColor];

      // DOWNLOAD PATTERN / SKIP IF IT'S A RAL COLOR
      if (selectedPattern.relationships.fch_horizontal_image.data) {
        const selectedPatternImageHorizontal = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_horizontal_image.data.id];
        const selectedPatternImageVertival = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_vertical_image.data.id];
        const newPatternH = yield call(getGlassImage, selectedPatternImageHorizontal.attributes.uri.url);
        const newPatternV = yield call(getGlassImage, selectedPatternImageVertival.attributes.uri.url);
        patterns.setPatterns([
          { name: `${selectedDoorFrameColor}-H`, value: newPatternH },
          { name: `${selectedDoorFrameColor}-V`, value: newPatternV },
        ]);
      }
    }

    yield put({
      type: SET_PROPERTIES,
      payload: action.payload,
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorBodyColor(action) {
  try {
    const { selectedDoorBodyColor } = action.payload;
    const pattern = patterns.getPattern(selectedDoorBodyColor);

    // DOWNLOAD PATTERN IF NECESSARY
    if (!pattern) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedPattern = availableOptionsStore.doorAvailableColors[selectedDoorBodyColor];

      // DOWNLOAD PATTERN / SKIP IF IT'S A RAL COLOR
      if (selectedPattern.relationships.fch_horizontal_image.data) {
        const selectedPatternImageHorizontal = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_horizontal_image.data.id];
        const selectedPatternImageVertival = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_vertical_image.data.id];
        const newPatternH = yield call(getGlassImage, selectedPatternImageHorizontal.attributes.uri.url);
        const newPatternV = yield call(getGlassImage, selectedPatternImageVertival.attributes.uri.url);
        patterns.setPatterns([
          { name: `${selectedDoorBodyColor}-H`, value: newPatternH },
          { name: `${selectedDoorBodyColor}-V`, value: newPatternV },
        ]);
      }
    }

    const getSelectedOptionsStore = (store) => store.selectedOptions;
    const { selectedWorkingWing } = yield select(getSelectedOptionsStore);
    const wingType = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorBodyColorActive' : 'selectedDoorBodyColorPassive';

    yield put({
      type: SET_PROPERTIES,
      payload: {
        [wingType]: selectedDoorBodyColor,
      },
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorActiveMetalStripeColor(action) {
  try {
    const { selectedDoorActiveMetalStripeColor } = action.payload;

    yield put({
      type: SET_PROPERTIES,
      payload: {
        selectedDoorActiveMetalStripeColor,
      },
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorPassiveMetalStripeColor(action) {
  try {
    const { selectedDoorPassiveMetalStripeColor } = action.payload;

    yield put({
      type: SET_PROPERTIES,
      payload: {
        selectedDoorPassiveMetalStripeColor,
      },
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorInlay1Color(action) {
  try {
    const { selectedDoorInlay1Color } = action.payload;
    const pattern = patterns.getPattern(selectedDoorInlay1Color);

    // DOWNLOAD PATTERN IF NECESSARY
    if (!pattern) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedPattern = availableOptionsStore.doorAvailableColors[selectedDoorInlay1Color];

      // DOWNLOAD PATTERN / SKIP IF IT'S A RAL COLOR
      if (selectedPattern.relationships.fch_horizontal_image.data) {
        const selectedPatternImageHorizontal = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_horizontal_image.data.id];
        const selectedPatternImageVertival = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_vertical_image.data.id];
        const newPatternH = yield call(getGlassImage, selectedPatternImageHorizontal.attributes.uri.url);
        const newPatternV = yield call(getGlassImage, selectedPatternImageVertival.attributes.uri.url);
        patterns.setPatterns([
          { name: `${selectedDoorInlay1Color}-H`, value: newPatternH },
          { name: `${selectedDoorInlay1Color}-V`, value: newPatternV },
        ]);
      }
    }

    const getSelectedOptionsStore = (store) => store.selectedOptions;
    const { selectedWorkingWing } = yield select(getSelectedOptionsStore);
    const wingType = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorInlay1ColorActive' : 'selectedDoorInlay1ColorPassive';

    yield put({
      type: SET_PROPERTIES,
      payload: {
        [wingType]: selectedDoorInlay1Color,
      },
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorInlay2Color(action) {
  try {
    const { selectedDoorInlay2Color } = action.payload;
    const pattern = patterns.getPattern(selectedDoorInlay2Color);

    // DOWNLOAD PATTERN IF NECESSARY
    if (!pattern) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedPattern = availableOptionsStore.doorAvailableColors[selectedDoorInlay2Color];

      // DOWNLOAD PATTERN / SKIP IF IT'S A RAL COLOR
      if (selectedPattern.relationships.fch_horizontal_image.data) {
        const selectedPatternImageHorizontal = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_horizontal_image.data.id];
        const selectedPatternImageVertival = availableOptionsStore.doorAvailableColorsAttachedImage[selectedPattern.relationships.fch_vertical_image.data.id];
        const newPatternH = yield call(getGlassImage, selectedPatternImageHorizontal.attributes.uri.url);
        const newPatternV = yield call(getGlassImage, selectedPatternImageVertival.attributes.uri.url);
        patterns.setPatterns([
          { name: `${selectedDoorInlay2Color}-H`, value: newPatternH },
          { name: `${selectedDoorInlay2Color}-V`, value: newPatternV },
        ]);
      }
    }

    const getSelectedOptionsStore = (store) => store.selectedOptions;
    const { selectedWorkingWing } = yield select(getSelectedOptionsStore);
    const wingType = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorInlay2ColorActive' : 'selectedDoorInlay2ColorPassive';

    yield put({
      type: SET_PROPERTIES,
      payload: {
        [wingType]: selectedDoorInlay2Color,
      },
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export default [
  takeEvery(CONFIGURATOR_SELECT_DOOR_FRAME_COLOR, selectDoorFrameColor),
  takeEvery(CONFIGURATOR_SELECT_DOOR_BODY_COLOR, selectDoorBodyColor),
  takeEvery(CONFIGURATOR_SELECT_DOOR_ACTIVE_METAL_STRIPE_COLOR, selectDoorActiveMetalStripeColor),
  takeEvery(CONFIGURATOR_SELECT_DOOR_PASSIVE_METAL_STRIPE_COLOR, selectDoorPassiveMetalStripeColor),
  takeEvery(CONFIGURATOR_SELECT_DOOR_INLAY1_COLOR, selectDoorInlay1Color),
  takeEvery(CONFIGURATOR_SELECT_DOOR_INLAY2_COLOR, selectDoorInlay2Color),
];
