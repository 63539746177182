import { fabric } from 'fabric';
import BigEval from 'bigeval';

export const replaceWidhtHeight = (string, width, height) => (
  string.replace('width', width).replace('height', height)
);

export const createPatternObject = (inputWidth, inputHeight, shapes) => {
  const Obj = new BigEval();
  const patternObject = JSON.parse(`{"shapes": ${shapes}}`);
  const width = inputWidth - 4;
  const height = inputHeight - 4;

  patternObject.shapes.forEach((obj) => {
    const object = obj;

    if (Object.prototype.hasOwnProperty.call(object, 'top')) {
      if (Number.isNaN(parseFloat(object.top))) {
        const topString = replaceWidhtHeight(object.top, width, height);
        object.top = Obj.exec(topString);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'left')) {
      if (Number.isNaN(parseFloat(object.left))) {
        const topString = replaceWidhtHeight(object.left, width, height);
        object.left = Obj.exec(topString);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'height')) {
      if (Number.isNaN(parseFloat(object.height))) {
        const topString = replaceWidhtHeight(object.height, width, height);
        object.height = Obj.exec(topString);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'width')) {
      if (Number.isNaN(parseFloat(object.width))) {
        const topString = replaceWidhtHeight(object.width, width, height);
        object.width = Obj.exec(topString);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'a')) {
      if (Number.isNaN(parseFloat(object.a))) {
        object.a = Obj.exec(object.a);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'm')) {
      if (Number.isNaN(parseFloat(object.m))) {
        object.a = Obj.exec(object.m);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'z')) {
      if (Number.isNaN(parseFloat(object.z))) {
        object.a = Obj.exec(object.z);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'angle')) {
      if (Number.isNaN(parseFloat(object.angle))) {
        object.a = Obj.exec(object.angle);
      }
    }

    if (Object.prototype.hasOwnProperty.call(object, 'points')) {
      object.points = object.points.map((point) => ({
        x: Number.isNaN(parseFloat(point.x)) ? Obj.exec(replaceWidhtHeight(point.x, width, height)) : point.x,
        y: Number.isNaN(parseFloat(point.y)) ? Obj.exec(replaceWidhtHeight(point.y, width, height)) : point.y,
      }));
    }
  });

  return patternObject;
};

export const createFabricRect = (obj, pattern, layerIndex) => {
  const { height, width, left } = obj;
  const angle = obj.angle || 0;

  const rect = new fabric.Rect({
    layerIndex,
    width,
    height,
    angle,
    left,
    top: obj.top,
    rx: obj.rx || 0,
    ry: obj.ry || 0,
    fill: pattern,
  });

  return rect;
};

export const createFabricCircle = (obj, pattern, layerIndex) => {
  const glassPattern = pattern;
  glassPattern.offsetY = pattern.offsetY + obj.radius;

  const circle = new fabric.Circle({
    layerIndex,
    radius: obj.radius,
    left: obj.left,
    top: obj.top,
    fill: pattern,
  });

  return circle;
};

export const createFabricPolygon = (obj, pattern, layerIndex) => {
  const polygon = new fabric.Polyline(obj.points, {
    layerIndex,
    left: obj.left,
    top: obj.top,
    strokeLineJoin: 'miter',
    strokeWidth: 0,
    fill: pattern,
  });

  return polygon;
};

export const createFabricLine = (obj, pattern, layerIndex) => {
  const line = new fabric.Rect({
    layerIndex,
    width: obj.width,
    height: obj.height,
    angle: obj.angle || 0,
    left: obj.left,
    top: obj.top,
    rx: obj.rx || 0,
    ry: obj.ry || 0,
    fill: pattern,
  });

  return line;
};

export const createRectBorder = (left, top, windowHeight, windowWidth, pattern) => {
  const frameThickness = 25;
  const height = windowHeight + 6;
  const width = windowWidth + 6;

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: height },
      { x: frameThickness, y: height - frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left - 3,
      top: top - 3,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: height },
      { x: 0, y: height - frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left + width - frameThickness - 3,
      top: top - 3,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: width },
      { x: frameThickness, y: width - frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left + width - 3,
      top: top - 3,
      angle: 90,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const bottomFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: width },
      { x: frameThickness, y: width - frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'bottomDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left - 3,
      top: top + height - 3,
      angle: 270,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  return [leftFrame, topFrame, rightFrame, bottomFrame];
};

export const createPolyBorderDiagonal = (left, top, windowHeight, windowWidth, pattern) => {
  const frameThickness = 25;
  const height = windowHeight * Math.sin(45 * (180 / Math.PI));
  const width = windowWidth * Math.sin(45 * (180 / Math.PI));

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: height },
      { x: frameThickness, y: height - frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left - 3,
      top: top - 3,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: height },
      { x: 0, y: height - frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left + width - frameThickness - 3,
      top: top - 3,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: width },
      { x: frameThickness, y: width - frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left + width - 3,
      top: top - 3,
      angle: 90,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const bottomFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: width },
      { x: frameThickness, y: width - frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'bottomDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: left - 3,
      top: top + height - 3,
      angle: 270,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
    },
  );

  const borderSlide = Math.sqrt((frameThickness ** 2) + (frameThickness ** 2));
  const group = new fabric.Group([leftFrame, topFrame, rightFrame, bottomFrame], {
    angle: 45,
    centeredRotation: true,
    top: top - borderSlide,
    left: left + (width * Math.sin(45 * (180 / Math.PI))) - (2 * borderSlide),
  });

  return group;
};

export const createPolyBorder = (shape, pattern) => {
  const { m, a, z } = shape;
  const h = 25;
  const flipped = shape.points[0].x === 0;

  // q section
  const b = Math.sqrt((z ** 2) + (m ** 2));
  const alpha = 90 - Math.atan(z / m) * (180 / Math.PI);
  const q = h / Math.tan(alpha / 2);

  // R section
  const beta = 180 - alpha;
  const omega = 90 - (beta / 2);
  const r = Math.tan(omega) * h;

  const rightFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 }, // Q
      { x: 0, y: b }, // R
      { x: h, y: b + r }, // S
      { x: h, y: -q }, // P
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 81,
      left: flipped
        ? shape.left + shape.width - z
        : shape.left + shape.width + r,
      top: flipped
        ? shape.top - r
        : shape.top - q,
      angle: flipped ? alpha - 90 : 90 - alpha,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
      flipY: flipped,
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 }, // Q
      { x: r, y: h }, // R
      { x: a + r, y: h }, // S
      { x: a + r + q, y: 0 }, // P
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 80,
      left: shape.left + shape.points[0].x - (flipped ? h : r),
      top: shape.top + shape.points[0].y - h,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
      flipX: flipped,
    },
  );

  const leftFramePromise = new Promise((resolve) => {
    rightFrame.clone((cloned) => {
      const leftFrame = cloned;
      leftFrame.flipX = true;
      leftFrame.flipY = !flipped;
      leftFrame.left = shape.left + shape.points[0].x - (flipped ? q : r);
      leftFrame.top = shape.top + shape.points[0].y - h;
      leftFrame.fill = pattern;
      // leftFrame.angle = flipped ? rightFrame.angle - 90 : rightFrame.angle;
      resolve(leftFrame);
    });
  });

  const bottomFramePromise = new Promise((resolve) => {
    topFrame.clone((cloned) => {
      const bottomFrame = cloned;
      bottomFrame.flipX = !flipped;
      bottomFrame.flipY = true;
      bottomFrame.left = shape.left + (flipped ? (z - r) : -q);
      bottomFrame.top = shape.top + shape.points[1].y;
      bottomFrame.fill = pattern;
      resolve(bottomFrame);
    });
  });

  return Promise.all([leftFramePromise, bottomFramePromise]).then((data) => ([rightFrame, topFrame, data[0], data[1]]));
};

export const createPolyBorderRotated = (shape, pattern) => {
  const { a, m, z } = shape;
  const h = 25;

  // q section
  const b = Math.sqrt((z ** 2) + (m ** 2));
  const alpha = 90 - Math.atan(z / m) * (180 / Math.PI);
  const q = h / Math.tan((alpha / 2) * (Math.PI / 180));

  // R section
  const beta = 180 - alpha;
  const omega = 90 - (beta / 2);
  const r = Math.tan(omega * (Math.PI / 180)) * h;

  const topFrame = new fabric.Polygon(
    [
      { x: h, y: -q }, // P
      { x: 0, y: 0 }, // Q
      { x: 0, y: b }, // R
      { x: h, y: b + r }, // S
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 81,
      left: shape.left + shape.width + h,
      top: shape.top - q,
      angle: alpha,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
      flipX: true,
    },
  );

  const bottomFrame = new fabric.Polygon(
    [
      { x: h, y: -q }, // P
      { x: 0, y: 0 }, // Q
      { x: 0, y: b }, // R
      { x: h, y: b + r }, // S
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 81,
      left: shape.left + shape.width + r,
      top: shape.top + a,
      angle: alpha,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
      flipY: true,
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: h, y: -r }, // P
      { x: 0, y: 0 }, // Q
      { x: 0, y: a }, // R
      { x: h, y: a + q }, // S
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 81,
      left: shape.left + shape.width,
      top: shape.top - q,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
      flipY: true,
    },
  );

  const leftFrame = new fabric.Polygon(
    [
      { x: h, y: -q }, // P
      { x: 0, y: 0 }, // Q
      { x: 0, y: a }, // R
      { x: h, y: a + r }, // S
    ], {
      name: 'topDoorWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: 81,
      left: shape.left - h,
      top: shape.top + z - r,
      angle: 0,
      fill: pattern,
      strokeWidth: 1,
      stroke: 'black',
      flipY: true,
      flipX: true,
    },
  );

  return [topFrame, bottomFrame, rightFrame, leftFrame];
};

export const createCircleBorder = (shape, pattern) => {
  const border = createFabricCircle(shape, pattern, 39);
  border.radius += 25;
  border.strokeWidth = 1;
  border.stroke = 'black';
  return [border];
};

export const createRectBorderRounded = (shape, pattern) => {
  const borderShapePromise = new Promise((resolve) => {
    shape.clone((cloned) => {
      const border = createFabricRect(cloned, pattern, 39);
      border.strokeWidth = 1.5;
      border.stroke = 'black';
      border.width += 50;
      border.height += 50;
      border.left -= 25;
      border.top -= 25;
      border.ry += 25;
      resolve(border);
    });
  });

  return Promise.all([borderShapePromise]).then((data) => (data));
};

export const createPattern = (patternImage, repeat, offset = {}, sizes = null) => {
  const patternSourceCanvas = new fabric.StaticCanvas();
  patternSourceCanvas.add(patternImage);

  const pattern = new fabric.Pattern({
    source: () => {
      patternSourceCanvas.setDimensions({
        width: sizes !== null && sizes.width ? sizes.width : patternImage.getScaledWidth(),
        height: sizes !== null && sizes.height ? sizes.height : patternImage.getScaledHeight(),
      });
      patternSourceCanvas.renderAll();
      return patternSourceCanvas.getElement();
    },
    repeat,
    offsetX: offset.x || 0,
    offsetY: offset.y || 0,
  });

  return pattern;
};

export const createPatternCanvas = (patternSourceCanvas, handleImage, offsetX, offsetY) => {
  const pattern = new fabric.Pattern({
    source: () => {
      patternSourceCanvas.setDimensions({
        width: handleImage.getScaledWidth(),
        height: handleImage.getScaledHeight(),
      });
      patternSourceCanvas.renderAll();
      return patternSourceCanvas.getElement();
    },
    repeat: 'no-repeat',
    offsetX,
    offsetY,
  });

  return pattern;
};

export const createWindowShape = (obj, alignObject, pattern) => {
  let shape;

  switch (obj.type) {
    case 'Rect': {
      shape = createFabricRect(obj, pattern, 40);
      shape.top += alignObject.top + 2;
      shape.left = alignObject.patternDirection === 'H'
        ? (alignObject.left - alignObject.height) + shape.left + 4
        : alignObject.left + shape.left + 2;
      shape.isGlazing = obj.darkness === 'glass';
      shape.frameType = obj.frameType || 'rect';
      break;
    }

    case 'Line': {
      shape = createFabricLine(obj, pattern, 41);
      shape.top += alignObject.top;
      shape.left += alignObject.left;
      shape.isGlazing = obj.darkness === 'glass';
      break;
    }

    case 'Polygon': {
      shape = createFabricPolygon(obj, pattern, 40);
      shape.a = obj.a;
      shape.m = obj.m;
      shape.z = obj.z;
      shape.angle = obj.angle || 0;
      shape.top += alignObject.top;
      shape.left += alignObject.left;
      shape.isGlazing = obj.darkness === 'glass';
      shape.frameType = obj.frameType;
      break;
    }

    case 'Circle': {
      shape = createFabricCircle(obj, pattern, 40);
      const alignPosition = alignObject.getPointByOrigin('left', 'top');
      shape.top = alignPosition.y + shape.top - (shape.height / 2);
      shape.left = alignPosition.x + shape.left - (shape.width / 2); // eslint-disable-line
      shape.isGlazing = obj.darkness === 'glass';
      shape.frameType = 'circle';
      break;
    }

    default: return new fabric.Object();
  }

  return shape;
};

export const createPatternShape = (shapes, alignObject, pattern) => (
  shapes.map((obj) => {
    let shape;
    const leftCorrigation = 1;

    switch (obj.type) {
      case 'Rect': {
        shape = createFabricRect(obj, pattern, alignObject.layerIndex + 1);
        shape.top += alignObject.top;
        shape.left += alignObject.patternDirection === 'H'
          ? (alignObject.left - leftCorrigation) - alignObject.height
          : (alignObject.left - leftCorrigation);
        shape.isDesignPettern = obj.darkness !== 'dark';
        shape.inlay = obj.inlay === 1;
        shape.inlay2 = obj.inlay === 2;
        break;
      }

      case 'Polygon': {
        shape = createFabricPolygon(obj, pattern, 30);
        shape.top += alignObject.top;
        shape.left += alignObject.patternDirection === 'H' // alignObject.left;
          ? (alignObject.left - leftCorrigation) - alignObject.height
          : (alignObject.left - leftCorrigation);
        shape.isDesignPettern = obj.darkness !== 'dark';
        shape.inlay2 = obj.inlay === 2;
        break;
      }

      default: return null;
    }

    return shape;
  })
);
