import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  SET_PROPERTIES,
  CONFIGURATOR_SELECT_WALL_COLOR,
  CONFIGURATOR_SELECT_FLOOR_COLOR,
  CONFIGURATOR_SELECT_DOOR_FAMILY,
  CONFIGURATOR_SELECT_LOCATION,
} from 'consts/actionTypes';
import {
  FESTETT_DOOR_FAMILY,
  COLOR_RAL, COLOR_CPL,
} from 'consts/consts';
import { renderCanvas } from './canvasSagas';

const getSelectedOptionsStore = (store) => store.selectedOptions;

export function* selectDoorFamily(action) {
  const { selectedDoorFamily } = yield select(getSelectedOptionsStore);

  const payload = {
    ...action.payload,
    selectedDoorFrameColor: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorBodyColorActive: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorBodyColorPassive: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorInlay1ColorActive: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorInlay1ColorPassive: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorInlay2ColorActive: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorInlay2ColorPassive: selectedDoorFamily === FESTETT_DOOR_FAMILY ? COLOR_RAL : COLOR_CPL,
    selectedDoorPatternActive: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
    selectedDoorPatternPassive: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
    selectedDoorPatternInstanceActive: '9840cc76-0629-4264-8ece-aa919ba5f9ec',
    selectedDoorPatternInstancePassive: '9840cc76-0629-4264-8ece-aa919ba5f9ec',
  };

  yield put({
    type: SET_PROPERTIES,
    payload,
  });

  yield call(renderCanvas);
}

export function* selectLocation(action) {
  yield put({
    type: SET_PROPERTIES,
    payload: action.payload,
  });

  yield call(renderCanvas);
}

export function* selectWallColor(action) {
  yield put({
    type: SET_PROPERTIES,
    payload: action.payload,
  });

  yield call(renderCanvas);
}

export function* selectFloorColor(action) {
  yield put({
    type: SET_PROPERTIES,
    payload: action.payload,
  });

  yield call(renderCanvas);
}

export default [
  takeEvery(CONFIGURATOR_SELECT_DOOR_FAMILY, selectDoorFamily),
  takeEvery(CONFIGURATOR_SELECT_LOCATION, selectLocation),
  takeEvery(CONFIGURATOR_SELECT_WALL_COLOR, selectWallColor),
  takeEvery(CONFIGURATOR_SELECT_FLOOR_COLOR, selectFloorColor),
];
