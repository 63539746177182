import { fabric } from 'fabric';
import { host } from 'services/config';

export const getAccessoryImage = (imageName) => (
  new Promise((resolve) => {
    const randomString = `?${Math.random().toString(36).substring(7)}`;
    fabric.Image.fromURL(`${host}${imageName}${randomString}`, (img) => {
      const handleImage = img;
      handleImage.crossOrigin = 'Anonymous';
      handleImage.scale(0.8);
      handleImage.layerIndex = 100;

      resolve(handleImage);
    }, {
      crossOrigin: 'Anonymous',
    });
  })
);

export const getPatternImage = (imageName, doorActiveWallOpeningWidth, sizes = null) => (
  new Promise((resolve, reject) => {
    const randomString = `?${Math.random().toString(36).substring(7)}`;
    fabric.Image.fromURL(`${host}${imageName}${randomString}`, (img) => {
      if (img.height === 0 || img.width === 0) {
        reject();
      }

      const handleImage = img;
      handleImage.crossOrigin = 'Anonymous';
      handleImage.scaleToWidth(doorActiveWallOpeningWidth);
      const patternSourceCanvas = new fabric.StaticCanvas();
      patternSourceCanvas.add(handleImage);

      const pattern = new fabric.Pattern({
        source: () => {
          patternSourceCanvas.setDimensions({
            width: sizes !== null ? sizes.width : handleImage.getScaledWidth(),
            height: sizes !== null ? sizes.height : handleImage.getScaledHeight(),
          });
          patternSourceCanvas.renderAll();
          return patternSourceCanvas.getElement();
        },
        repeat: 'repeat',
      });

      resolve(pattern);
    }, {
      crossOrigin: 'Anonymous',
    });
  })
);

export const getGlassImage = (imageName) => (
  new Promise((resolve, reject) => {
    const randomString = `?${Math.random().toString(36).substring(7)}`;
    fabric.Image.fromURL(`${host}${imageName}${randomString}`, (img) => {
      if (img.height === 0 || img.width === 0) {
        reject();
      }

      const handleImage = img;
      handleImage.crossOrigin = 'Anonymous';

      resolve(handleImage);
    }, {
      crossOrigin: 'Anonymous',
    });
  })
);

export const getGlassImageOld = (imageName) => (
  new Promise((resolve, reject) => {
    const randomString = `?${Math.random().toString(36).substring(7)}`;
    fabric.Image.fromURL(`${host}${imageName}${randomString}`, (img) => {
      if (img.height === 0 || img.width === 0) {
        reject();
      }

      const handleImage = img;
      handleImage.scale(2.2);
      handleImage.crossOrigin = 'Anonymous';
      const patternSourceCanvas = new fabric.StaticCanvas();
      patternSourceCanvas.add(handleImage);

      const pattern = new fabric.Pattern({
        source: () => {
          patternSourceCanvas.setDimensions({
            width: handleImage.getScaledWidth(),
            height: handleImage.getScaledHeight(),
          });
          patternSourceCanvas.renderAll();
          return patternSourceCanvas.getElement();
        },
        repeat: 'no-repeat',
      });

      resolve(pattern);
    }, {
      crossOrigin: 'Anonymous',
    });
  })
);
