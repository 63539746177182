import axios from 'axios';
import { snakeCase, camelCase, mapKeys } from 'lodash';
import httpService from 'services/interceptor';
import qs from 'qs';
import { host } from 'services/config';

export const convertRequestAttributes = (data) => {
  try {
    if (data && data.data && data.data.attributes) {
      const transformedData = data;
      transformedData.data.attributes = mapKeys(transformedData.data.attributes,
        (value, key) => snakeCase(key));
      return JSON.stringify(transformedData);
    }
    return JSON.stringify(data);
  } catch (e) {
    return data;
  }
};

export const convertResponseAttributes = (response) => {
  try {
    const data = JSON.parse(response);
    if (data.data && data.data.attributes) {
      const transformedData = data;
      transformedData.data.attributes = mapKeys(transformedData.data.attributes,
        (value, key) => camelCase(key));
      return transformedData;
    }
    return data;
  } catch (e) {
    return response;
  }
};

const api = axios.create({
  transformRequest: [convertRequestAttributes],
  transformResponse: [convertResponseAttributes],
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
  },
});

export const getData = (url) => api.get(url).then((response) => response.data.data);

export const postData = (url, dataType, attributes) => (
  api.post(url, {
    data: {
      type: dataType,
      attributes,
    },
  }).then((response) => (response.data.data))
);

export const updateData = (url, type, attributes) => (
  api.update(url, {
    data: {
      type,
      attributes,
    },
  }).then((response) => (response.data.data))
);

const setHeader = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`;
};

export const getConfiguratorComponent = (url) => (
  api.get(url).then((response) => response.data)
);

export const login = () => (
  axios.post(`${host}/oauth/token`, qs.stringify({
    grant_type: 'password',
    client_id: '6768a693-0a20-4763-b4a2-c125590f6289',
    client_secret: 'UserS_oqiwuezrt',
    username: 'frontendapp@cheppers.com',
    password: 'pGqfy60gNphlcGt1',
  })).then((response) => (response.data))
);

export const getDoor = (id) => (
  axios({
    method: 'GET',
    url: `${host}/jsonapi/node/door/${id}`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  }).then((response) => (response.data.data))
);

export const createDoor = (data, token) => (
  axios({
    method: 'POST',
    url: `${host}/jsonapi/node/door`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${token.access_token}`, // eslint-disable-line
    },
    data: JSON.stringify({
      ...data,
    }),
  }).then((response) => (response.data))
);

export const saveDoor = (data) => (
  axios({
    method: 'POST',
    url: `${host}/jsonapi/node/door`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
    data: {
      data: {
        type: 'node--door',
        attributes: data.data.attributes,
        relationships: data.data.relationships,
      },
    },
  }).then((response) => (response.data))
);

export const savePdfPreview = (base64, token) => (
  axios({
    method: 'POST',
    url: `${host}/fch/getfileid`,
    headers: {
      'Content-Type': 'application/vnd.api+json',
      Authorization: `Bearer ${token.access_token}`,
    },
    data: JSON.stringify({
      data: `image/jpeg;base64,${base64}`,
    }),
  }).then((response) => response.data)
);

export const getPdfUrl = (door, token) => (
  axios({
    method: 'POST',
    url: `${host}/fch/export/basic`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access_token}`,
    },
    data: JSON.stringify({
      door,
    }),
  }).then((response) => response.data)
);

httpService.setupInterceptors(api, setHeader);
