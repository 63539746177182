import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falElottFutoSoroltOldalvilagito = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
    doorActiveWallOpeningWidthExtra,
    selectedOpeningDirection,
    selectedBaseGlassPattern,
  } = state;

  const isRightOpening = selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4';

  // Ajtólap külméret | Y - 35
  const doorWidth = doorActiveWallOpeningWidth - config.falElottFuto.doorWidthOuterSize;

  // Ajtólap külméret | X - 25
  const doorHeight = doorWallOpeningHeight - config.falElottFuto.doorHeightOuterSize;

  // Látszó üvegméret | Y2 - 128
  const windowShapeWidth = doorActiveWallOpeningWidthExtra - 128;

  // Pontos üvegméret | X - 91 !!! Falnyílás magassággal számolok mert ki lesz takarva !!!
  const windowShapeHeight = doorWallOpeningHeight;

  // Üvegszorító léc szélesség oldalsó világítóhoz
  const glassClampingWidth = (doorActiveWallOpeningWidthExtra - 128) - (doorActiveWallOpeningWidthExtra - 150);

  // Ajtólap felső pozíciója
  const doorShapePositionTop = (doorWallOpeningHeight - 26) - (doorWallOpeningHeight - 15);

  // Ajtólap magassága
  const doorShapeHeight = (doorHeight - doorShapePositionTop) + 5;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 67) - (Ajtótok tokmag külméret - 30)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + 67) - (doorActiveWallOpeningWidth - 30)) / 2;

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 425) - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  const doorLeftPosition = (2400 - doorActiveWallOpeningWidth - windowShapeWidth) / 2;

  // Karnis belméret | 2 x (Y + 26)
  const corniceWidth = 2 * (doorActiveWallOpeningWidth + 26);

  // Karnis magasság
  const corniceHeight = 140;

  // Karnis top pozíciója
  const cornicePositionTop = doorTopPosition - (corniceHeight / 2);

  // Swap positions
  const doorSwap = isRightOpening ? 0 : windowShapeWidth + (frameThickness * 2) + 1;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    patternDirection: doorActivePatternDirection,
    width: doorActivePatternDirection === 'H' ? doorShapeHeight : doorWidth,
    height: doorActivePatternDirection === 'H' ? doorWidth : doorShapeHeight,
    layerIndex: config.falElottFuto.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorActivePatternDirection === 'H'
      ? doorLeftPosition + frameThickness + doorWidth + doorSwap
      : doorLeftPosition + frameThickness + doorSwap,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    fill: '#8a6930',
    angle: doorActivePatternDirection === 'H' ? 90 : 0,
  });

  const corniceShape = new fabric.Rect({
    name: 'corniceShape',
    doorElement: 'corniceShape',
    width: corniceHeight,
    height: corniceWidth,
    layerIndex: config.falElottFuto.layerOrders.corniceShapeLayerIndex,
    strokeWidth: 0,
    angle: 90,
    left: isRightOpening
      ? (doorLeftPosition - frameThickness) + corniceWidth
      : (doorLeftPosition) + doorShape.width + (3 * frameThickness) + doorSwap,
    top: cornicePositionTop,
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 0,
      offsetY: 10,
      blur: 14,
    },
    fill: '#8a6930',
  });

  const windowShape = new fabric.Rect({
    name: 'windowShape',
    doorElement: 'windowShape',
    width: windowShapeWidth,
    height: windowShapeHeight - (frameThickness / 2),
    layerIndex: config.falElottFuto.layerOrders.windowShapeLayerIndex,
    strokeWidth: 0,
    left: isRightOpening
      ? doorLeftPosition + doorWidth + (frameThickness * 3)
      : doorLeftPosition + frameThickness,
    top: doorTopPosition + (frameThickness / 2),
    fill: selectedBaseGlassPattern || '#4f4f4f',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorSwap,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2) + doorSwap,
      top: doorTopPosition,
      angle: 90,
      fill: '#674a18',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness + doorSwap,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  // Oldalvilágító keretek

  const bottomSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: frameThickness },
      { x: 0, y: windowShapeWidth + frameThickness },
      { x: frameThickness, y: windowShapeWidth + (frameThickness * 2) },
      { x: frameThickness, y: 0 },
    ], {
      name: 'bottomSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.windowFrameLayerIndex,
      left: isRightOpening
        ? doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 66
        : doorLeftPosition + windowShapeWidth + (frameThickness * 2),
      top: (doorTopPosition + doorWallOpeningHeight) - 24,
      angle: 90,
      fill: '#674a18',
    },
  );

  const leftSideWindowFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.windowFrameLayerIndex,
      left: isRightOpening
        ? doorLeftPosition + doorWidth + (frameThickness * 2)
        : doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  const rightSideWindowFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightSideWindowFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.windowFrameLayerIndex,
      left: isRightOpening
        ? doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + (frameThickness / 3)
        : doorLeftPosition + windowShapeWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  // Üvegszorítók az oldalsó világítóhoz

  const bottomSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: glassClampingWidth },
      { x: 0, y: (windowShapeWidth - glassClampingWidth) + 10 },
      { x: glassClampingWidth, y: windowShapeWidth + 10 },
      { x: glassClampingWidth, y: 0 },
    ], {
      name: 'bottomSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.glassClampingLayerIndex,
      left: isRightOpening
        ? doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + 20
        : doorLeftPosition + frameThickness + windowShapeWidth,
      top: (doorTopPosition + doorWallOpeningHeight) - (glassClampingWidth * 2),
      angle: 90,
      fill: '#674a18',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: 5,
        blur: 10,
      },
    },
  );

  const leftSideGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: windowShapeHeight - 65 },
      { x: glassClampingWidth, y: (windowShapeHeight - 65) - glassClampingWidth },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'leftSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.glassClampingLayerIndex,
      left: isRightOpening
        ? doorLeftPosition + doorWidth + (frameThickness * 2) + (glassClampingWidth * 2)
        : doorLeftPosition + frameThickness,
      top: doorTopPosition + (glassClampingWidth * 2),
      angle: 0,
      fill: '#674a18',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: -5,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  const rightSideGlassClamping = new fabric.Polygon(
    [
      { x: glassClampingWidth, y: 0 },
      { x: glassClampingWidth, y: (windowShapeHeight - glassClampingWidth) - 45 },
      { x: 0, y: (windowShapeHeight - (glassClampingWidth * 2)) - 45 },
      { x: 0, y: glassClampingWidth },
    ], {
      name: 'rightSideGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falElottFuto.layerOrders.glassClampingLayerIndex,
      left: isRightOpening
        ? (((doorLeftPosition + doorWidth) + doorActiveWallOpeningWidthExtra) + (frameThickness / 3)) - (glassClampingWidth)
        : doorLeftPosition + windowShapeWidth + glassClampingWidth,
      top: doorTopPosition + (glassClampingWidth * 2),
      angle: 0,
      fill: '#674a18',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: 5,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: doorTopPosition,
    left: isRightOpening ? doorLeftPosition : doorLeftPosition + doorSwap,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + doorActiveWallOpeningWidthExtra + (frameThickness / 3),
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  const corniceShapeOuterShadowTop = new fabric.Rect({
    name: 'corniceShapeOuterShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth,
    top: cornicePositionTop,
    left: corniceShape.left - corniceShape.height, // height and not width! (angle = 90)
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: 'rgba(0, 0, 0, 0.2)',
      offsetX: 0,
      offsetY: -5,
      blur: 10,
    },
  });

  const corniceShapeOuterShadowLeft = new fabric.Rect({
    name: 'corniceShapeOuterShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth / 2,
    top: cornicePositionTop,
    left: corniceShape.left - corniceShape.height, // height and not width! (angle = 90)
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const corniceShapeOuterShadowRight = new fabric.Rect({
    name: 'corniceShapeOuterShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: corniceHeight,
    width: corniceWidth / 2,
    top: cornicePositionTop,
    left: corniceShape.left - (corniceShape.height / 2), // height and not width! (angle = 90)
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    name: 'doorInnerShadowTop',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness + doorSwap,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorShapeHeight) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness + doorSwap,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    name: 'doorInnerShadowLeft',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness + doorSwap,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    name: 'doorInnerShadowRight',
    layerIndex: config.falElottFuto.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + doorWidth + doorSwap,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition + doorShapePositionTop + 5,
    width: (doorWidth + frameThickness + doorActiveWallOpeningWidthExtra) + 20,
    height: (doorShapeHeight + frameThickness) - 5,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  const corniceFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'corniceFrameShadow',
    layerIndex: 121,
    left: corniceShape.left - corniceShape.height, // height and not width! (angle = 90),
    top: cornicePositionTop,
    width: corniceWidth,
    height: corniceHeight,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  corniceFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    corniceShape, doorShape, leftFrame, rightFrame,
    topFrame, bottomSideWindowFrame, leftSideWindowFrame, rightSideWindowFrame,
    bottomSideGlassClamping, leftSideGlassClamping, rightSideGlassClamping,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow, corniceFrameShadow, corniceShapeOuterShadowLeft, corniceShapeOuterShadowRight,
    corniceShapeOuterShadowTop, windowShape,
  ];
};

export default falElottFutoSoroltOldalvilagito;
