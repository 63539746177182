import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

export const LanguageSelector = ({ language, onClick }) => (
  <div className="language-selector-container">
    <div
      tabIndex={0}
      role="button"
      className={`language${language === 'hu' ? ' selected' : ''}`}
      onClick={() => { onClick('hu'); }}
    >
      HU
    </div>
    <div
      tabIndex={0}
      role="button"
      className={`language${language === 'en' ? ' selected' : ''}`}
      onClick={() => { onClick('en'); }}
    >
      ENG
    </div>
  </div>
);

LanguageSelector.propTypes = {
  language: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default withTranslation()(LanguageSelector);
