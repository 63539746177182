import { ACTIVE_WING } from 'consts/consts';

const initialState = {
  // STEP -
  selectedDoorType: 'falcolt-alap', // TODO: remove
  selectedWorkingWing: ACTIVE_WING,

  // STEP01
  selectedDoorFamily: '99765159-902e-442b-a677-3f3da2613e9b',
  selectedLocation: 'e4435593-8cd9-4414-8991-1241e35914b9',
  selectedWallColor: 'e8cba398-fee2-4c74-bbf4-6e5a10abe81f',
  selectedFloorColor: 'ab975b14-a0e0-4512-9b70-11eca236de2f',

  // STEP02
  selectedDoorWingType: 'e668fcdb-b4b5-4857-b106-6d500275d6a7',
  selectedDoorDesign: '5235e54e-8698-4d13-9f00-99098bf929e5',
  selectedDoorDesignExtra: '2748d41f-7591-4dc3-9719-643a907fd04b',
  selectedDoorOpeningDirection: '291bb030-7706-4e97-a686-9fd412f5ddd4',
  doorActiveWallOpeningWidth: 900,
  doorPassiveWallOpeningWidth: 600,
  doorWallOpeningHeight: 2100,

  // STEP 03
  selectedDoorPatternActive: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
  selectedDoorPatternPassive: 'f8ce5cbd-a10c-4b51-9bce-e69268d88cde',
  selectedDoorPatternInstanceActive: '9840cc76-0629-4264-8ece-aa919ba5f9ec',
  selectedDoorPatternInstancePassive: '9840cc76-0629-4264-8ece-aa919ba5f9ec',

  // STEP 04
  workingDoorGlazingTypeActive: null,
  workingDoorGlazingTypePassive: null,
  selectedWindowGlassPattern: '9cccb1f4-86b8-4b31-9ff2-47d23b0539a4',
  selectedDoorGlazingTypeActive: null,
  selectedDoorGlazingActive: null,
  selectedDoorGlassPatternActive: '9cccb1f4-86b8-4b31-9ff2-47d23b0539a4',
  selectedDoorGlazingTypePassive: null,
  selectedDoorGlazingPassive: null,
  selectedDoorGlassPatternPassive: '9cccb1f4-86b8-4b31-9ff2-47d23b0539a4',

  // STEP 05
  selectedDoorFrameColor: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
  selectedDoorBodyColorActive: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
  selectedDoorBodyColorPassive: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
  selectedDoorActiveMetalStripeColor: '016ac8d6-531d-4157-8b0e-48a82f73537c',
  selectedDoorPassiveMetalStripeColor: '016ac8d6-531d-4157-8b0e-48a82f73537c',
  selectedDoorInlay1ColorActive: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
  selectedDoorInlay1ColorPassive: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
  selectedDoorInlay2ColorActive: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',
  selectedDoorInlay2ColorPassive: '5a6fe425-ee0b-494a-a8a1-02aad1a7ac04',

  // STEP 06
  selectedDoorAccessory: '6b55dffa-98fc-4bb8-bcf2-62b7f5a4bdb0',
  selectedDoorFanOption: false,
};

export default initialState;
