import React from 'react';
import Spinner from 'react-spinkit';
import './componentspinner.scss';

export const ComponentSpinner = () => (
  <Spinner
    name="ball-beat"
    className="component-spinner"
    color="#2049fc"
  />
);

export default ComponentSpinner;
