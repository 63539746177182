import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';
import { history } from 'services/config';
import { changeLanguage } from 'actions/appActions';
import DashboardHeader from 'components/DashboardHeader/DashboardHeader';
import HomePicture from 'styles/images/home.svg';
import './home.scss';

const HomePage = ({ language, dispatchChangeLanguage, t }) => (
  <div className="page page-home">
    <DashboardHeader
      language={language}
      onClick={(lang) => {
        dispatchChangeLanguage(lang);
      }}
    />
    <div className="content-container">
      <div className="container">
        <Row type="flex" justify="center">
          <Col className="col col-left" xs={24} sm={14}>
            <div className="home-info-box">
              <ol>
                <li>{t('pages.home.step1')}</li>
                <li>{t('pages.home.step2')}</li>
                <li>{t('pages.home.step3')}</li>
              </ol>
            </div>
          </Col>
          <Col className="col col-right" xs={24} sm={10}>
            <div className="home-img-box">
              <HomePicture />
            </div>
          </Col>
          <Col className="col col-buttons" span={24}>
            <div className="button-wrap">
              <Button
                type="primary"
                onClick={() => {
                  history.push('/configurator');
                }}
              >
                {t('pages.home.button')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

const mapStateToProps = (store) => ({
  language: store.app.language,
});

const mapDispatchToProps = {
  dispatchChangeLanguage: changeLanguage,
};

HomePage.propTypes = {
  language: PropTypes.string.isRequired,
  dispatchChangeLanguage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomePage));
