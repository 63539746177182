import {
  SELECT_OPTION,
  SET_PROPERTIES,
} from 'consts/actionTypes';
import initialState from './initialState';

const selectedOptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_OPTION:
      return {
        ...state,
        [action.payload.property]: action.payload.value,
      };

    case SET_PROPERTIES:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default selectedOptionsReducer;
