import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falcoltKetszarnyu = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorPassiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorBlendeHeight,
    selectedOpeningDirection,
    selectedBaseGlassPattern,
  } = state;

  const doorWallOpeningHeightExtra = doorBlendeHeight;

  // Felülvilágító - Pontos üvegméret X2 - 39
  const topWindowHeight = doorWallOpeningHeightExtra - 39;

  // Üvegszorító léc szélesség felső világítóhoz
  const glassClampingWidth = (doorWallOpeningHeightExtra - 39) - (doorWallOpeningHeightExtra - 60);

  // Tokosztó
  const caseSplitter = glassClampingWidth * 3;

  // Aktv ajtó
  const activeDoorWidth = doorActiveWallOpeningWidth - 15;

  // Passzív ajtó
  const passiveDoorWidth = doorPassiveWallOpeningWidth - 28;

  // Ajtólap külméret
  const doorWidth = activeDoorWidth + passiveDoorWidth;

  // Ajtólap külméret | X1 - 25
  const doorHeight = (doorWallOpeningHeight - 25) + topWindowHeight + caseSplitter;

  // Ajtólap felső pozíciója
  const doorShapePositionTop = (doorWallOpeningHeight - config.falcolt.doorShapePositionTop[0]) - (doorWallOpeningHeight - config.falcolt.doorShapePositionTop[1]); // eslint-disable-line

  // Ajtólap magassága
  const doorShapeHeight = ((doorHeight - doorShapePositionTop) + 5) - topWindowHeight - caseSplitter;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 67) - (Ajtótok tokmag külméret - 30)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + 62) - (doorActiveWallOpeningWidth - 58)) / 2;

  // Ajtó felső pozíciója
  // (doorWallOpeningHeight - 2100) = felső korrekció
  const doorTopPosition = (frameThickness + 405) - topWindowHeight - caseSplitter - (doorWallOpeningHeight - 2100);

  // Ajtó bal pozíciója
  const doorLeftPosition = (2400 - doorWidth) / 2;

  // Layer sorrendek - Eltérő a config!!!
  const windowShapeLayerIndex = 7;
  const glassClampingLayerIndex = 8;
  const caseSplitterLayerIndex = 8;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    width: activeDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.falcolt.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness + passiveDoorWidth
      : doorLeftPosition + frameThickness,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop + topWindowHeight + caseSplitter,
    fill: '#8a6930',
  });

  const doorShapePassive = new fabric.Rect({
    name: 'doorShapePassive',
    doorElement: 'doorShapePassive',
    width: passiveDoorWidth,
    height: doorShapeHeight,
    layerIndex: config.falcolt.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: selectedOpeningDirection === '291bb030-7706-4e97-a686-9fd412f5ddd4'
      ? doorLeftPosition + frameThickness
      : doorLeftPosition + frameThickness + activeDoorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop + topWindowHeight + caseSplitter,
    fill: '#8a6930',
  });

  const caseSplitterShape = new fabric.Rect({
    name: 'caseSplitterShape',
    doorElement: 'caseSplitterShape',
    width: caseSplitter,
    height: doorWidth,
    layerIndex: caseSplitterLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + doorWidth + frameThickness,
    top: doorTopPosition + frameThickness + topWindowHeight,
    fill: '#674a18',
    angle: 90,
  });

  const windowShape = new fabric.Rect({
    name: 'windowShape',
    doorElement: 'windowShape',
    width: doorWidth,
    height: topWindowHeight,
    layerIndex: windowShapeLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + frameThickness,
    top: doorTopPosition + frameThickness,
    fill: selectedBaseGlassPattern || '#4f4f4f',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#8a6930',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#8a6930',
    },
  );

  // Üvegszorító felülvilágítóhoz

  const topGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: (doorWidth - (glassClampingWidth)) + 20 },
      { x: glassClampingWidth, y: (doorWidth - (glassClampingWidth * 2)) + 20 },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'topGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: (doorTopPosition + frameThickness),
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: 2,
        blur: 10,
      },
    },
  );

  const bottomGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: glassClampingWidth },
      { x: 0, y: (doorWidth - glassClampingWidth) },
      { x: glassClampingWidth, y: doorWidth },
      { x: glassClampingWidth, y: 0 },
    ], {
      name: 'bottomGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition + (frameThickness / 2) + topWindowHeight + 7,
      angle: 90,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 0,
        offsetY: -2,
        blur: 10,
      },
    },
  );

  const leftGlassClamping = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: topWindowHeight },
      { x: glassClampingWidth, y: (topWindowHeight - glassClampingWidth) },
      { x: glassClampingWidth, y: glassClampingWidth },
    ], {
      name: 'leftGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: doorLeftPosition + frameThickness,
      top: doorTopPosition + (glassClampingWidth * 2) + 17,
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.4)',
        offsetX: 2,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  const rightGlassClamping = new fabric.Polygon(
    [
      { x: glassClampingWidth, y: 0 },
      { x: glassClampingWidth, y: (topWindowHeight - glassClampingWidth) + 19 },
      { x: 0, y: (topWindowHeight - (glassClampingWidth * 2)) + 19 },
      { x: 0, y: glassClampingWidth },
    ], {
      name: 'rightGlassClamping',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: glassClampingLayerIndex,
      left: ((doorLeftPosition + doorWidth) + (frameThickness)) - (glassClampingWidth),
      top: doorTopPosition + (glassClampingWidth * 2) + 17,
      angle: 0,
      fill: '#b98328',
      shadow: {
        color: 'rgba(0, 0, 0, 0.6)',
        offsetX: -2,
        offsetY: 0,
        blur: 10,
      },
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    name: 'doorInnerShadowTop',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: doorShapeHeight,
    width: doorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop + topWindowHeight + caseSplitter,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorShapeHeight) + doorShapePositionTop + topWindowHeight + caseSplitter,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    name: 'doorInnerShadowLeft',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop + topWindowHeight + caseSplitter,
    left: doorLeftPosition + frameThickness,
    height: doorHeight - topWindowHeight - caseSplitter,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    name: 'doorInnerShadowRight',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight - topWindowHeight - caseSplitter,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop + topWindowHeight + caseSplitter,
    left: doorLeftPosition + doorWidth,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition + doorShapePositionTop,
    width: doorWidth + (frameThickness * 2),
    height: doorShapeHeight + frameThickness + topWindowHeight + caseSplitter,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, doorShapePassive,
    windowShape, caseSplitterShape,
    leftFrame, rightFrame, topFrame,
    topGlassClamping, bottomGlassClamping, leftGlassClamping, rightGlassClamping,
    doorFrameOuterShadowTop, doorFrameOuterShadowLeft, doorFrameOuterShadowRight,
    doorInnerShadowTop, doorInnerShadowBottom, doorInnerShadowLeft, doorInnerShadowRight,
    doorFrameShadow,
  ];
};

export default falcoltKetszarnyu;
