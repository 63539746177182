import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';
import './colorselector.scss';

const ColorSelector = ({ colorList, selected, onSelect }) => (
  <div className="color-selector__container">
    {
         colorList.map((color) => {
           const isSelectedColor = selected === color.id;

           return (
             <Button
               key={color.id}
               className={`color-selector__item ${isSelectedColor ? 'color-selector__item-selected' : ''}`}
               onClick={() => onSelect(color.id)}
               style={{ backgroundColor: color.value }}
             />
           );
         })
        }
  </div>
);

ColorSelector.propTypes = {
  selected: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  colorList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default withTranslation()(ColorSelector);
