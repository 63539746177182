import { takeEvery, put, call, select } from 'redux-saga/effects';
import { getGlassImage } from 'utils/images';
import {
  SET_PROPERTIES,
  CONFIGURATOR_CHANGE_WINDOW_GLASS_PATTERN,
  CONFIGURATOR_SELECT_DOOR_GLAZING_TYPE,
  CONFIGURATOR_SELECT_DOOR_GLAZING,
  CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN,
} from 'consts/actionTypes';
import { patterns } from 'models';
import { ACTIVE_WING } from 'consts/consts';
import { renderCanvas } from './canvasSagas';

const getAvailableOptionsStore = (store) => store.availableOptions;

export function* selectWindowGlassPattern(action) {
  try {
    const { selectedWindowGlassPattern } = action.payload;
    const pattern = patterns.getPattern(selectedWindowGlassPattern);

    if (!pattern) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedGlass = availableOptionsStore.doorGlassPattern[selectedWindowGlassPattern];
      const selectedGlassImage = availableOptionsStore.doorGlassPatternAttachedImage[selectedGlass.relationships.fch_attached_image.data.id];
      const newPattern = yield call(getGlassImage, selectedGlassImage.attributes.uri.url);
      patterns.setPatterns([{ name: selectedWindowGlassPattern, value: newPattern }]);
    }

    yield put({
      type: SET_PROPERTIES,
      payload: action.payload,
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorGlassPattern(action) {
  try {
    const { selectedDoorGlassPattern } = action.payload;
    const pattern = patterns.getPattern(selectedDoorGlassPattern);

    if (!pattern) {
      const availableOptionsStore = yield select(getAvailableOptionsStore);
      const selectedGlass = availableOptionsStore.doorGlassPattern[selectedDoorGlassPattern];
      const selectedGlassImage = availableOptionsStore.doorGlassPatternAttachedImage[selectedGlass.relationships.fch_attached_image.data.id];
      const newPattern = yield call(getGlassImage, selectedGlassImage.attributes.uri.url);
      patterns.setPatterns([{ name: selectedDoorGlassPattern, value: newPattern }]);
    }

    const getSelectedOptionsStore = (store) => store.selectedOptions;
    const { selectedWorkingWing } = yield select(getSelectedOptionsStore);
    const wingType = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorGlassPatternActive' : 'selectedDoorGlassPatternPassive';

    yield put({
      type: SET_PROPERTIES,
      payload: {
        [wingType]: action.payload.selectedDoorGlassPattern,
      },
    });

    yield call(renderCanvas);
  } catch (error) {
    console.log(error); // eslint-disable-line
  }
}

export function* selectDoorGlazingType(action) {
  const getSelectedOptionsStore = (store) => store.selectedOptions;
  const { selectedWorkingWing } = yield select(getSelectedOptionsStore);
  const wingType = selectedWorkingWing === ACTIVE_WING ? 'workingDoorGlazingTypeActive' : 'workingDoorGlazingTypePassive';
  const selectedDoorGlazing = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorGlazingActive' : 'selectedDoorGlazingPassive';
  const selectedDoorGlazingType = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorGlazingTypeActive' : 'selectedDoorGlazingTypePassive';
  const payload = {
    [wingType]: action.payload.selectedDoorGlazingType,
  };

  if (!payload.workingDoorGlazingTypeActive) {
    payload[selectedDoorGlazing] = null;
    payload[selectedDoorGlazingType] = null;
  }

  yield put({
    type: SET_PROPERTIES,
    payload,
  });

  yield call(renderCanvas);
}

export function* selectDoorGlazing(action) {
  const getSelectedOptionsStore = (store) => store.selectedOptions;
  const { selectedWorkingWing, workingDoorGlazingTypeActive, workingDoorGlazingTypePassive } = yield select(getSelectedOptionsStore);
  const wingType = selectedWorkingWing === ACTIVE_WING ? 'selectedDoorGlazingActive' : 'selectedDoorGlazingPassive';

  yield put({
    type: SET_PROPERTIES,
    payload: {
      [wingType]: action.payload.selectedDoorGlazing,
      selectedDoorGlazingTypeActive: workingDoorGlazingTypeActive,
      selectedDoorGlazingTypePassive: workingDoorGlazingTypePassive,
    },
  });

  yield call(renderCanvas);
}

export default [
  takeEvery(CONFIGURATOR_CHANGE_WINDOW_GLASS_PATTERN, selectWindowGlassPattern),
  takeEvery(CONFIGURATOR_SELECT_DOOR_GLAZING_TYPE, selectDoorGlazingType),
  takeEvery(CONFIGURATOR_SELECT_DOOR_GLAZING, selectDoorGlazing),
  takeEvery(CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN, selectDoorGlassPattern),
];
