import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const host = 'https://configurator.backend.full.co.hu';

class ImageButton extends React.Component {
  handleClick = () => {
    const { selectChoice, choice } = this.props;
    selectChoice(choice.value);
  }

  render() {
    const { isSelected, choice } = this.props;
    const selectedClass = isSelected ? ' selected' : '';

    return (
      <Button
        className={`button-selector${selectedClass}`}
        onClick={this.handleClick}
        style={{
          backgroundImage: `url("${host}${choice.url}")`,
        }}
      />
    );
  }
}

ImageButton.propTypes = {
  choice: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectChoice: PropTypes.func.isRequired,
};

export default ImageButton;
