import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { openLoadModal, initCanvas, selectStep, loadDoor } from 'actions/canvasActions';
import { changeLanguage } from 'actions/appActions';
import Stepper from 'components/Stepper/Stepper';
import DashboardHeader from 'components/DashboardHeader/DashboardHeader';
import Step01 from './steps/Step01';
import Step02 from './steps/Step02';
import Step03 from './steps/Step03';
import Step04 from './steps/Step04';
import Step05 from './steps/Step05';
import Step06 from './steps/Step06';
import Step07 from './steps/Step07';
import './configurator.scss';

class Configurator extends Component {
  constructor(props) {
    super(props);

    const { match: { params: { id } }, dispatchOpenLoadModal, dispatchLoadDoor } = props;
    if (id) {
      dispatchOpenLoadModal();
      dispatchLoadDoor(id);
    }
  }

  componentDidMount() {
    this.initCanvas('frontCanvas');
  }

  initCanvas = (canvasId) => {
    const { dispatchInitCanvas } = this.props;
    dispatchInitCanvas(canvasId);
  }

  render() {
    const { currentStep, isFetchingDoor, dispatchSelectStep, t, dispatchChangeLanguage, language } = this.props;

    return (
      <div className="page page-configurator">
        <Modal
          title="Betöltés"
          visible={isFetchingDoor}
          footer={null}
          closable={false}
        >
          <p>Az ajtó betöltése folyamatban van...</p>
        </Modal>

        <DashboardHeader
          language={language}
          onClick={(lang) => {
            dispatchChangeLanguage(lang);
          }}
        />
        <div className="content-container">
          <div className="sidebar sidebar-configurator">
            <Stepper collapsed />
          </div>

          <div className="configurator-container">
            <div className="configurator-container-header">
              <div className="step-title">
                <span>{`0${currentStep} `}</span>
                <span>{t(`stepper.step_${currentStep}.title`)}</span>
              </div>
              <div className="step-action">
                {
                  currentStep <= 6
                  && (
                    <Button
                      className="btn btn-next"
                      onClick={() => {
                        dispatchSelectStep(currentStep + 1);
                      }}
                    >
                      {
                        currentStep === 6 ? t('pages.configurator.endButton') : t('pages.configurator.nextButton')
                      }
                    </Button>
                  )
                }
              </div>
            </div>

            <div className="configurator-container-body">
              <canvas id="frontCanvas" width="470" height="490" style={{ filter: `blur(${isFetchingDoor ? 5 : 0}px)`, opacity: isFetchingDoor ? 0.25 : 1 }} />

              <div className="panel">
                {currentStep === 1 && <Step01 />}
                {currentStep === 2 && <Step02 />}
                {currentStep === 3 && <Step03 />}
                {currentStep === 4 && <Step04 />}
                {currentStep === 5 && <Step05 />}
                {currentStep === 6 && <Step06 />}
                {currentStep === 7 && <Step07 />}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Configurator.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  language: PropTypes.string.isRequired,
  isFetchingDoor: PropTypes.bool.isRequired,
  dispatchChangeLanguage: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  dispatchOpenLoadModal: PropTypes.func.isRequired,
  dispatchLoadDoor: PropTypes.func.isRequired,
  dispatchInitCanvas: PropTypes.func.isRequired,
  dispatchSelectStep: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Configurator.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: null,
    }),
  }),
};

const mapStateToProps = (state) => ({
  currentStep: state.configurator.currentStep,
  isFetchingDoor: state.canvas.isFetchingDoor,
  language: state.app.language,
});

const mapDispatchToProps = {
  dispatchOpenLoadModal: openLoadModal,
  dispatchLoadDoor: loadDoor,
  dispatchInitCanvas: initCanvas,
  dispatchSelectStep: selectStep,
  dispatchChangeLanguage: changeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Configurator));
