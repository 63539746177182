import React from 'react';
import PropTypes from 'prop-types';
import { convertRalToHex } from 'utils/utils';

export const StepperRalColor = ({ title, ralColor }) => {
  const changeComponentColor = ralColor == null ? 'not-filled' : 'filled';

  return (
    <div className={`stepper-component stepper-component-ral-color ${changeComponentColor}`}>
      <div className="stepper-component-title">{title}</div>
      <div className="stepper-component-content">
        <div
          className="stepper-component-box"
          style={{
            backgroundColor: convertRalToHex(ralColor),
          }}
        />
        <span className="text-ral">RAL</span>
        <span>{ralColor.split(' ')[1]}</span>
      </div>
    </div>
  );
};

StepperRalColor.defaultProps = {
  ralColor: '',
};

StepperRalColor.propTypes = {
  title: PropTypes.string.isRequired,
  ralColor: PropTypes.string,
};

export default StepperRalColor;
