import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { getPdf, getJpg, getUrl } from 'actions/canvasActions';

const Step07 = ({
  isFetchingPdf, isFetchingJpg, isFetchingUrl, doorId,
  dispatchGetPdf, dispatchGetJpg, dispatchGetUrl,
  t,
}) => (
  <div className="steps step-07" style={{ display: 'flex', flexDirection: 'column' }}>
    <Button
      type="primary"
      onClick={dispatchGetPdf}
      loading={isFetchingPdf}
      disabled={isFetchingPdf}
      style={{ minWidth: 240, textAlign: 'center', marginBottom: 20 }}
    >
      {t('stepper.step_7.page_buttons_label.download_pdf')}
    </Button>
    <Button
      type="primary"
      onClick={dispatchGetJpg}
      loading={isFetchingJpg}
      disabled={isFetchingJpg}
      style={{ minWidth: 240, textAlign: 'center', marginBottom: 20 }}
    >
      {t('stepper.step_7.page_buttons_label.download_jpg')}
    </Button>
    {
      !doorId
      && (
        <Button
          type="primary"
          onClick={dispatchGetUrl}
          loading={isFetchingUrl}
          disabled={isFetchingUrl}
          style={{ minWidth: 240, textAlign: 'center' }}
        >
          {t('stepper.step_7.page_buttons_label.create_link')}
        </Button>
      )
    }
    {
      doorId
      && (
        <div>
          <div style={{ fontSize: '18px', fontFamily: 'Roboto', marginBottom: '6px' }}>Az ajtó megosztási linkje</div>
          <input
            style={{ border: 'none', padding: '12px 18px', width: '100%' }}
            value={`${process.env.REACT_APP_SITE_HOSTNAME}/configurator/${doorId}`}
          />
        </div>
      )
    }
  </div>
);

Step07.propTypes = {
  doorId: PropTypes.string,
  isFetchingPdf: PropTypes.bool.isRequired,
  isFetchingJpg: PropTypes.bool.isRequired,
  isFetchingUrl: PropTypes.bool.isRequired,
  dispatchGetPdf: PropTypes.func.isRequired,
  dispatchGetJpg: PropTypes.func.isRequired,
  dispatchGetUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Step07.defaultProps = {
  doorId: null,
};

const mapStateToProps = (store) => ({
  isFetchingPdf: store.canvas.isFetchingPdf,
  isFetchingJpg: store.canvas.isFetchingJpg,
  isFetchingUrl: store.canvas.isFetchingUrl,
  doorId: store.canvas.doorId,
});

const mapDispatchToProps = {
  dispatchGetPdf: getPdf,
  dispatchGetJpg: getJpg,
  dispatchGetUrl: getUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step07));
