import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getConfiguratorComponent, selectDoorAccessory, selectDoorFan } from 'actions/canvasActions';
import { NODE_DOOR_ACCESSORIES, slidingDoorFamilies } from 'consts/consts';
import Spinner from 'components/Spinner/ComponentSpinner';
import TypeSelector from 'components/TypeSelector/TypeSelector';

class Step06 extends Component {
  componentDidMount() {
    const { dispatchGetConfiguratorInstanceComponent } = this.props;
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_ACCESSORIES, '&include=fch_attached_image');
  }

  selectDoorAccessory = (selectedDoorAccessory) => {
    const { dispatchSelectDoorAccessory } = this.props;
    dispatchSelectDoorAccessory(selectedDoorAccessory);
  }

  selectDoorFan = (value) => {
    const { dispatchSelectDoorFan } = this.props;
    dispatchSelectDoorFan(value);
  }

  render() {
    const {
      doorAccessoryOptions,
      doorAccessoryAttachedImageOptions,
      selectedDoorAccessory,
      selectedDoorDesign,
      selectedDoorFanOption,
      t,
    } = this.props;

    if (!Object.keys(doorAccessoryOptions).length) {
      return <Spinner />;
    }

    const isSlidingDoor = slidingDoorFamilies.includes(selectedDoorDesign);

    const filteredDoorAccessoryOptions = Object.keys(doorAccessoryOptions).filter((key) => (
      isSlidingDoor
        ? doorAccessoryOptions[key].relationships.fch_door_accessories_type.data.id === 'b99ac9b6-5f61-4c04-9d39-70691a3b530d'
        : doorAccessoryOptions[key].relationships.fch_door_accessories_type.data.id === 'a9987f34-c654-47ba-aa16-1c401cf2e15c'
    ));

    return (
      <div className="steps step-06">
        {
          Object.keys(filteredDoorAccessoryOptions).length !== 0
          && (
            <TypeSelector
              index={1}
              title={t('stepper.step_6.children.handle')}
              choices={
                filteredDoorAccessoryOptions
                  .filter((key) => (
                    doorAccessoryOptions[key].relationships.fch_door_accessories_type.data.id !== '54ca6f20-f857-42bd-8f50-52e2a4d13b6a'
                  ))
                  .map((key) => ({
                    value: key,
                    url: doorAccessoryAttachedImageOptions[doorAccessoryOptions[key].relationships.fch_attached_image.data.id].attributes.uri.url,
                  }))
              }
              choiceType="image"
              selectedChoice={selectedDoorAccessory}
              onChange={this.selectDoorAccessory}
            />
          )
        }
        {
          /*
          !isSlidingDoor && Object.keys(filteredDoorAccessoryOptions).length !== 0
          && (
            <TypeSelector
              index={2}
              title={t('stepper.step_6.children.fan')}
              choices={
                Object.keys(doorAccessoryOptions)
                  .filter((key) => (doorAccessoryOptions[key].relationships.fch_door_accessories_type.data.id === '54ca6f20-f857-42bd-8f50-52e2a4d13b6a'))
                  .map((key) => ({
                    value: key,
                    url: doorAccessoryAttachedImageOptions[doorAccessoryOptions[key].relationships.fch_attached_image.data.id].attributes.uri.url,
                  }))
              }
              choiceType="image"
              selectedChoice={selectedDoorAccessory}
              onChange={this.selectDoorAccessory}
            />
          )
          */
        }
        {
          Object.keys(filteredDoorAccessoryOptions).length !== 0
          && (
            <TypeSelector
              index={2}
              title={t('stepper.step_6.children.fan')}
              choices={[
                { value: false, label: 'Nem' },
                { value: true, label: 'Igen' },
              ]}
              selectedChoice={selectedDoorFanOption}
              onChange={this.selectDoorFan}
            />
          )
        }
      </div>
    );
  }
}

Step06.propTypes = {
  doorAccessoryOptions: PropTypes.shape({}).isRequired,
  doorAccessoryAttachedImageOptions: PropTypes.shape({}).isRequired,
  selectedDoorDesign: PropTypes.string.isRequired,
  selectedDoorAccessory: PropTypes.string.isRequired,
  selectedDoorFanOption: PropTypes.bool.isRequired,
  dispatchGetConfiguratorInstanceComponent: PropTypes.func.isRequired,
  dispatchSelectDoorAccessory: PropTypes.func.isRequired,
  dispatchSelectDoorFan: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  doorAccessoryOptions: store.availableOptions.doorAccessory,
  doorAccessoryAttachedImageOptions: store.availableOptions.doorAccessoryAttachedImage,
  selectedDoorAccessory: store.selectedOptions.selectedDoorAccessory,
  selectedDoorDesign: store.selectedOptions.selectedDoorDesign,
  selectedDoorFanOption: store.selectedOptions.selectedDoorFanOption,
});

const mapDispatchToProps = {
  dispatchGetConfiguratorInstanceComponent: getConfiguratorComponent,
  dispatchSelectDoorAccessory: selectDoorAccessory,
  dispatchSelectDoorFan: selectDoorFan,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step06));
