import {
  APP_CHANGE_LANGUAGE,
} from 'consts/actionTypes';
import initialState from './initialState';

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload.language,
      };

    default:
      return state;
  }
};

export default appReducer;
