import { all } from 'redux-saga/effects';
import canvasSagas from './canvasSagas';
import step01Sagas from './step01Sagas';
import step02Sagas from './step02Sagas';
import step03Sagas from './step03Sagas';
import step04Sagas from './step04Sagas';
import step05Sagas from './step05Sagas';
import step06Sagas from './step06Sagas';
import step07Sagas from './step07Sagas';
import commonActionSagas from './commonActionSagas';
import loadDoorSagas from './loadDoorSagas';
import { appSagas } from './appSagas';

export default function* rootSaga() {
  yield all([
    ...canvasSagas,
    ...step01Sagas,
    ...step02Sagas,
    ...step03Sagas,
    ...step04Sagas,
    ...step05Sagas,
    ...step06Sagas,
    ...step07Sagas,
    ...commonActionSagas,
    ...loadDoorSagas,
    ...appSagas,
  ]);
}
