import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { find } from 'lodash';
import {
  getConfiguratorComponent,
  selectWindowGlassPattern,
  selectDoorGlazingType,
  selectDoorGlazing,
  selectDoorGlassPattern,
} from 'actions/canvasActions';
import {
  TAXONOMY_TERM_DOOR_GLAZING_TYPE,
  NODE_DOOR_GLASS_PATTERN,
  NODE_DOOR_GLAZING,
  ACTIVE_WING, PASSIVE_WING,
} from 'consts/consts';
import Spinner from 'components/Spinner/ComponentSpinner';
import WingChooser from 'components/WingChooser/WingChooser';
import TypeSelector from 'components/TypeSelector/TypeSelector';
import PatternPalette from 'components/PatternPalette/PatternPalette';

class Step04 extends Component {
  componentDidMount() {
    const { dispatchGetConfiguratorInstanceComponent } = this.props;
    dispatchGetConfiguratorInstanceComponent(TAXONOMY_TERM_DOOR_GLAZING_TYPE);
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_GLASS_PATTERN, '&include=fch_attached_image,fch_preview_image');
  }

  selectWindowGlassPattern = (windowGlassPattern) => {
    const { dispatchSelectWindowGlassPattern } = this.props;
    dispatchSelectWindowGlassPattern(windowGlassPattern);
  }

  selectDoorGlazingType = (selectedDoorGlazingType) => {
    const { dispatchSelectDoorGlazingType, dispatchGetConfiguratorInstanceComponent } = this.props;
    dispatchSelectDoorGlazingType(selectedDoorGlazingType);
    dispatchGetConfiguratorInstanceComponent(NODE_DOOR_GLAZING, `&filter[fch_door_glazing_type.id][value]=${selectedDoorGlazingType}&include=fch_attached_image`);
  }

  selectDoorGlazing = (selectedDoorGlazing) => {
    const { dispatchSelectDoorGlazing } = this.props;
    dispatchSelectDoorGlazing(selectedDoorGlazing);
  }

  selectDoorGlassPattern = (selectedDoorGlassPattern) => {
    const { dispatchSelectDoorGlassPattern } = this.props;
    dispatchSelectDoorGlassPattern(selectedDoorGlassPattern);
  }

  render() {
    const {
      doorGlassPatternOptions, doorGlazingTypeOptions, doorGlazingOptions,
      doorGlazingAttachedImageOptions, doorGlassPatternAttachedImageOptions,
      selectedWorkingWing, selectedDoorWingType, selectedDoorDesignExtra,
      selectedWindowGlassPattern, t,
      workingDoorGlazingTypeActive, selectedDoorGlazingActive, selectedDoorGlassPatternActive,
      workingDoorGlazingTypePassive, selectedDoorGlazingPassive, selectedDoorGlassPatternPassive,
    } = this.props;

    if (
      !Object.keys(doorGlassPatternOptions).length
      || !Object.keys(doorGlazingTypeOptions).length
    ) {
      return <Spinner />;
    }

    return (
      <div className="steps step-02">
        {
          (selectedDoorDesignExtra === '98b3d677-7bd9-4d2f-b145-b07e69815cc3'
            || selectedDoorDesignExtra === '5f2f79b6-412c-46bb-befe-cc9cc1624445'
            || selectedDoorDesignExtra === '100539b5-679e-418a-a470-e3be9e65bc89')
          && (
            <>
              <div className="configurator-block-title">{t('stepper.step_4.page_buttons_label.door_base_glazing_pattern')}</div>
              <PatternPalette
                patternOptions={Object.keys(doorGlassPatternOptions).map((key) => (doorGlassPatternOptions[key]))}
                attachedImages={doorGlassPatternAttachedImageOptions}
                selectedOption={selectedWindowGlassPattern}
                onSelect={this.selectWindowGlassPattern}
              />
            </>
          )
        }
        {
          selectedDoorWingType === '88a0b4b0-c577-40e2-b966-16160c6c4127'
          && (
            <WingChooser />
          )
        }

        <TypeSelector
          index={1}
          title={t('stepper.step_4.page_buttons_label.door_glazing_type')}
          choices={[
            { value: null, label: t('stepper.step_4.non_glazing_pattern') },
            ...Object.keys(doorGlazingTypeOptions).map((key) => ({
              value: key,
              label: doorGlazingTypeOptions[key].attributes.name,
            })),
          ]}
          selectedChoice={selectedWorkingWing === ACTIVE_WING ? workingDoorGlazingTypeActive : workingDoorGlazingTypePassive}
          onChange={this.selectDoorGlazingType}
        />

        {
          (selectedWorkingWing === ACTIVE_WING && workingDoorGlazingTypeActive) || (selectedWorkingWing === PASSIVE_WING && workingDoorGlazingTypePassive)
            ? (
              <>
                {
                  (
                    (selectedWorkingWing === ACTIVE_WING && workingDoorGlazingTypeActive !== null)
                    || (selectedWorkingWing === PASSIVE_WING && workingDoorGlazingTypePassive)
                  )
                  && (
                    <TypeSelector
                      index={2}
                      title={t('stepper.step_4.page_buttons_label.door_glazing_shape')}
                      choices={
                        Object.keys(doorGlazingOptions)
                          .filter((key) => (
                            find(doorGlazingOptions[key].relationships.fch_door_glazing_type.data, {
                              id: selectedWorkingWing === ACTIVE_WING ? workingDoorGlazingTypeActive : workingDoorGlazingTypePassive,
                            })
                          ))
                          .map((key) => ({
                            value: key,
                            url: doorGlazingAttachedImageOptions[doorGlazingOptions[key].relationships.fch_attached_image.data.id].attributes.uri.url,
                          }))
                      }
                      choiceType="image"
                      selectedChoice={selectedWorkingWing === ACTIVE_WING ? selectedDoorGlazingActive : selectedDoorGlazingPassive}
                      onChange={this.selectDoorGlazing}
                    />
                  )
                }
                <div className="configurator-block-title">{t('stepper.step_4.page_buttons_label.door_glazing_pattern')}</div>
                <PatternPalette
                  patternOptions={Object.keys(doorGlassPatternOptions).map((key) => (doorGlassPatternOptions[key]))}
                  attachedImages={doorGlassPatternAttachedImageOptions}
                  selectedOption={selectedWorkingWing === ACTIVE_WING ? selectedDoorGlassPatternActive : selectedDoorGlassPatternPassive}
                  onSelect={this.selectDoorGlassPattern}
                />
              </>
            )
            : null
        }
      </div>
    );
  }
}

Step04.propTypes = {
  doorGlassPatternOptions: PropTypes.shape({}).isRequired,
  doorGlazingTypeOptions: PropTypes.shape({}).isRequired,
  doorGlazingOptions: PropTypes.shape({}).isRequired,
  doorGlazingAttachedImageOptions: PropTypes.shape({}).isRequired,
  doorGlassPatternAttachedImageOptions: PropTypes.shape({}).isRequired,
  selectedWorkingWing: PropTypes.string.isRequired,
  selectedDoorWingType: PropTypes.string.isRequired,
  selectedDoorDesignExtra: PropTypes.string.isRequired,
  selectedWindowGlassPattern: PropTypes.string.isRequired,
  workingDoorGlazingTypeActive: PropTypes.string,
  selectedDoorGlazingActive: PropTypes.string,
  selectedDoorGlassPatternActive: PropTypes.string.isRequired,
  workingDoorGlazingTypePassive: PropTypes.string,
  selectedDoorGlazingPassive: PropTypes.string,
  selectedDoorGlassPatternPassive: PropTypes.string.isRequired,
  dispatchSelectWindowGlassPattern: PropTypes.func.isRequired,
  dispatchSelectDoorGlazingType: PropTypes.func.isRequired,
  dispatchSelectDoorGlazing: PropTypes.func.isRequired,
  dispatchSelectDoorGlassPattern: PropTypes.func.isRequired,
  dispatchGetConfiguratorInstanceComponent: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Step04.defaultProps = {
  workingDoorGlazingTypeActive: null,
  selectedDoorGlazingActive: null,
  workingDoorGlazingTypePassive: null,
  selectedDoorGlazingPassive: null,
};

const mapStateToProps = (store) => ({
  selectedWorkingWing: store.selectedOptions.selectedWorkingWing,
  selectedDoorWingType: store.selectedOptions.selectedDoorWingType,
  selectedDoorDesignExtra: store.selectedOptions.selectedDoorDesignExtra,
  selectedWindowGlassPattern: store.selectedOptions.selectedWindowGlassPattern,
  workingDoorGlazingTypeActive: store.selectedOptions.workingDoorGlazingTypeActive,
  selectedDoorGlazingActive: store.selectedOptions.selectedDoorGlazingActive,
  selectedDoorGlassPatternActive: store.selectedOptions.selectedDoorGlassPatternActive,
  workingDoorGlazingTypePassive: store.selectedOptions.workingDoorGlazingTypePassive,
  selectedDoorGlazingPassive: store.selectedOptions.selectedDoorGlazingPassive,
  selectedDoorGlassPatternPassive: store.selectedOptions.selectedDoorGlassPatternPassive,
  doorGlassPatternOptions: store.availableOptions.doorGlassPattern,
  doorGlassPatternAttachedImageOptions: store.availableOptions.doorGlassPatternAttachedImage,
  doorGlazingTypeOptions: store.availableOptions.doorGlazingType,
  doorGlazingOptions: store.availableOptions.doorGlazing,
  doorGlazingAttachedImageOptions: store.availableOptions.doorGlazingAttachedImage,
});

const mapDispatchToProps = {
  dispatchSelectWindowGlassPattern: selectWindowGlassPattern,
  dispatchSelectDoorGlazingType: selectDoorGlazingType,
  dispatchSelectDoorGlazing: selectDoorGlazing,
  dispatchSelectDoorGlassPattern: selectDoorGlassPattern,
  dispatchGetConfiguratorInstanceComponent: getConfiguratorComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Step04));
