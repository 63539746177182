// CANVAS ACTIONS
export const CANVAS_INIT_CANVAS = 'CANVAS_INIT_CANVAS';
export const CANVAS_STORE_OBJECTS = 'CANVAS_STORE_OBJECTS';
export const CANVAS_INITIALIZED = 'CANVAS_INITIALIZED';
export const CONFIGURATOR_SELECT_LOCATION = 'CONFIGURATOR_SELECT_LOCATION';
export const CONFIGURATOR_SELECT_STEP = 'CONFIGURATOR_SELECT_STEP';
export const DOOR_SET_ATTRIBUTE = 'DOOR_SET_ATTRIBUTE';
export const SELECT_OPTION = 'SELECT_OPTION';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const DOOR_LOAD_DOOR_REQUESTED = 'DOOR_LOAD_DOOR_REQUESTED';
export const DOOR_LOAD_DOOR_SUCCEEDED = 'DOOR_LOAD_DOOR_SUCCEEDED';
export const DOOR_LOAD_DOOR_FAILED = 'DOOR_LOAD_DOOR_FAILED';
export const DOOR_LOAD_BEGIN = 'DOOR_LOAD_BEGIN';
export const DOOR_LOAD_END = 'DOOR_LOAD_END';

// STEP 01
export const CONFIGURATOR_SELECT_WALL_COLOR = 'CONFIGURATOR_SELECT_WALL_COLOR';
export const CONFIGURATOR_SELECT_FLOOR_COLOR = 'CONFIGURATOR_SELECT_FLOOR_COLOR';

// STEP 02
export const CONFIGURATOR_SELECT_DOOR_FAMILY = 'CONFIGURATOR_SELECT_DOOR_FAMILY';
export const CONFIGURATOR_SELECT_DOOR_WING_TYPE = 'CONFIGURATOR_SELECT_DOOR_WING_TYPE';
export const CONFIGURATOR_SELECT_DOOR_OPENING_DIRECTION = 'CONFIGURATOR_SELECT_DOOR_OPENING_DIRECTION';
export const CONFIGURATOR_SELECT_DOOR_DESIGN = 'CONFIGURATOR_SELECT_DOOR_DESIGN';
export const CONFIGURATOR_SELECT_DOOR_DESIGN_EXTRA = 'CONFIGURATOR_SELECT_DOOR_DESIGN_EXTRA';
export const CONFIGURATOR_CHANGE_DOOR_DIMENSIONS = 'CONFIGURATOR_CHANGE_DOOR_DIMENSIONS';
export const CONFIGURATOR_CHANGE_WINDOW_GLASS_PATTERN = 'CONFIGURATOR_CHANGE_WINDOW_GLASS_PATTERN';
export const CONFIGURATOR_SELECT_DOOR_GLAZING_TYPE = 'CONFIGURATOR_SELECT_DOOR_GLAZING_TYPE';
export const CONFIGURATOR_SELECT_DOOR_GLAZING = 'CONFIGURATOR_SELECT_DOOR_GLAZING';
export const CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN = 'CONFIGURATOR_SELECT_DOOR_GLASS_PATTERN';
export const CONFIGURATOR_SELECT_DOOR_FRAME_COLOR = 'CONFIGURATOR_SELECT_DOOR_FRAME_COLOR';
export const CONFIGURATOR_SELECT_DOOR_BODY_COLOR = 'CONFIGURATOR_SELECT_DOOR_BODY_COLOR';
export const CONFIGURATOR_SELECT_DOOR_ACTIVE_METAL_STRIPE_COLOR = 'CONFIGURATOR_SELECT_DOOR_ACTIVE_METAL_STRIPE_COLOR';
export const CONFIGURATOR_SELECT_DOOR_PASSIVE_METAL_STRIPE_COLOR = 'CONFIGURATOR_SELECT_DOOR_PASSIVE_METAL_STRIPE_COLOR';
export const CONFIGURATOR_SELECT_DOOR_INLAY1_COLOR = 'CONFIGURATOR_SELECT_DOOR_INLAY1_COLOR';
export const CONFIGURATOR_SELECT_DOOR_INLAY2_COLOR = 'CONFIGURATOR_SELECT_DOOR_INLAY2_COLOR';
export const CONFIGURATOR_SELECT_WORKING_WING = 'CONFIGURATOR_SELECT_WORKING_WING';
export const CONFIGURATOR_SELECT_DOOR_PATTERN = 'CONFIGURATOR_SELECT_DOOR_PATTERN';
export const CONFIGURATOR_SELECT_DOOR_PATTERN_INSTANCE = 'CONFIGURATOR_SELECT_DOOR_PATTERN_INSTANCE';
export const CONFIGURATOR_COMPONENT_REQUESTED = 'CONFIGURATOR_COMPONENT_REQUESTED';
export const CONFIGURATOR_COMPONENT_SUCCEEDED = 'CONFIGURATOR_COMPONENT_SUCCEEDED';
export const CONFIGURATOR_COMPONENT_FAILED = 'CONFIGURATOR_COMPONENT_FAILED';

// STEP 06
export const CONFIGURATOR_SELECT_DOOR_ACCESSORY = 'CONFIGURATOR_SELECT_DOOR_ACCESSORY';
export const CONFIGURATOR_SELECT_DOOR_FAN = 'CONFIGURATOR_SELECT_DOOR_FAN';

// STEP 07
export const CONFIGURATOR_GET_PDF_REQUESTED = 'CONFIGURATOR_GET_PDF_REQUESTED';
export const CONFIGURATOR_GET_PDF_SUCCEEDED = 'CONFIGURATOR_GET_PDF_SUCCEEDED';
export const CONFIGURATOR_GET_PDF_FAILED = 'CONFIGURATOR_GET_PDF_FAILED';
export const CONFIGURATOR_GET_JPG_REQUESTED = 'CONFIGURATOR_GET_JPG_REQUESTED';
export const CONFIGURATOR_GET_JPG_SUCCEEDED = 'CONFIGURATOR_GET_JPG_SUCCEEDED';
export const CONFIGURATOR_GET_JPG_FAILED = 'CONFIGURATOR_GET_JPG_FAILED';
export const CONFIGURATOR_GET_URL_REQUESTED = 'CONFIGURATOR_GET_URL_REQUESTED';
export const CONFIGURATOR_GET_URL_SUCCEEDED = 'CONFIGURATOR_GET_URL_SUCCEEDED';
export const CONFIGURATOR_GET_URL_FAILED = 'CONFIGURATOR_GET_URL_FAILED';

// APP ACTIONS
export const APP_CHANGE_LANGUAGE = 'APP_CHANGE_LANGUAGE';
