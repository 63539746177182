import { fabric } from 'fabric';
import config from './_doorDesignConfig';

const falcoltOsztottBlende = (state) => {
  const {
    doorActiveWallOpeningWidth,
    doorWallOpeningHeight,
    doorActivePatternDirection,
    doorBlendeHeight,
  } = state;

  // Blende falcméret (X2 - 3)
  const blendeFalcSize = doorBlendeHeight - 3;

  // Falnyílás méret magasság | X
  const doorWallHeight = doorWallOpeningHeight + doorBlendeHeight;

  // Ajtólap külméret | Y - 35
  const doorWidth = doorActiveWallOpeningWidth - config.falcolt.doorWidthOuterSize;

  // Ajtólap külméret | X - 25
  const doorHeight = doorWallHeight - config.falcolt.doorHeightOuterSize;

  // Ajtólap felső pozíciója
  const doorShapePositionTop = (doorWallHeight - config.falcolt.doorShapePositionTop[0]) - (doorWallHeight - config.falcolt.doorShapePositionTop[1]); // eslint-disable-line

  // Ajtólap magassága
  const doorShapeHeight = ((doorHeight - doorShapePositionTop) - doorBlendeHeight) + 5;

  // Ajtókeret vastagsága | ((Ajtótok tokborítás külméret + 67) - (Ajtótok tokmag külméret - 30)) / 2
  const frameThickness = ((doorActiveWallOpeningWidth + config.falcolt.frameThickness[0]) - (doorActiveWallOpeningWidth - config.falcolt.frameThickness[1])) / 2; // eslint-disable-line

  // Ajtó felső pozíciója
  const doorTopPosition = (frameThickness + 425) - doorBlendeHeight + (2100 - doorWallOpeningHeight);

  // Ajtó bal pozíciója
  // 2400 = canvas normál méret
  const doorLeftPosition = (2400 - doorActiveWallOpeningWidth) / 2;

  const doorShape = new fabric.Rect({
    name: 'doorShape',
    doorElement: 'doorShape',
    patternDirection: doorActivePatternDirection,
    width: doorActivePatternDirection === 'H' ? doorShapeHeight : doorWidth,
    height: doorActivePatternDirection === 'H' ? doorWidth : doorShapeHeight,
    layerIndex: config.falcolt.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorActivePatternDirection === 'H' ? doorLeftPosition + frameThickness + doorWidth : doorLeftPosition + frameThickness,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop + doorBlendeHeight,
    fill: '#8a6930',
    angle: doorActivePatternDirection === 'H' ? 90 : 0,
  });

  const doorShapeBlende = new fabric.Rect({
    name: 'doorShapeBlende',
    doorElement: 'doorShapeBlende',
    width: doorWidth,
    height: blendeFalcSize,
    layerIndex: config.falcolt.layerOrders.doorShapeLayerIndex,
    strokeWidth: 0,
    left: doorLeftPosition + frameThickness,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    fill: '#8a6930',
  });

  const leftFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorHeight + frameThickness },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'leftFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  const topFrame = new fabric.Polygon(
    [
      { x: 0, y: 0 },
      { x: 0, y: doorWidth + (frameThickness * 2) },
      { x: frameThickness, y: doorWidth + frameThickness },
      { x: frameThickness, y: frameThickness },
    ], {
      name: 'topFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + (frameThickness * 2),
      top: doorTopPosition,
      angle: 90,
      fill: '#674a18',
    },
  );

  const rightFrame = new fabric.Polygon(
    [
      { x: frameThickness, y: 0 },
      { x: frameThickness, y: doorHeight + frameThickness },
      { x: 0, y: doorHeight + frameThickness },
      { x: 0, y: frameThickness },
    ], {
      name: 'rightFrame',
      isDesignExtra: true,
      doorElement: 'frame',
      layerIndex: config.falcolt.layerOrders.frameLayerIndex,
      left: doorLeftPosition + doorWidth + frameThickness,
      top: doorTopPosition,
      angle: 0,
      fill: '#674a18',
    },
  );

  // Outher shadows

  const doorFrameOuterShadowTop = new fabric.Rect({
    name: 'doorFrameOuterShadowTop',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    height: frameThickness,
    width: doorWidth + (frameThickness * 2),
    top: doorTopPosition,
    left: doorLeftPosition,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 0,
      offsetY: -5,
      blur: 14,
    },
  });

  const doorFrameOuterShadowLeft = new fabric.Rect({
    name: 'doorFrameOuterShadowLeft',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    top: doorTopPosition,
    left: doorLeftPosition + 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 14,
    },
  });

  const doorFrameOuterShadowRight = new fabric.Rect({
    name: 'doorFrameOuterShadowRight',
    layerIndex: config.falcolt.layerOrders.doorOuterShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight + frameThickness,
    width: frameThickness,
    top: doorTopPosition,
    left: doorLeftPosition + doorWidth + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameOuterShadow.color,
      offsetX: 20,
      offsetY: 0,
      blur: 12,
    },
  });

  // Inner Shadows

  const doorInnerShadowTop = new fabric.Rect({
    name: 'doorInnerShadowTop',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    height: frameThickness + doorBlendeHeight,
    width: doorWidth,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 1,
      blur: 8,
    },
  });

  const doorInnerShadowBottom = new fabric.Rect({
    name: 'doorInnerShadowBottom',
    layerIndex: 5,
    opacity: 1,
    height: frameThickness,
    width: doorWidth,
    top: (doorTopPosition + doorShapeHeight) + doorShapePositionTop + doorBlendeHeight,
    left: doorLeftPosition + frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 0,
      offsetY: 5,
      blur: 4,
    },
  });

  const doorInnerShadowLeft = new fabric.Rect({
    name: 'doorInnerShadowLeft',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + frameThickness,
    height: doorHeight,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: -10,
      offsetY: 0,
      blur: 16,
    },
  });

  const doorInnerShadowRight = new fabric.Rect({
    name: 'doorInnerShadowRight',
    layerIndex: config.falcolt.layerOrders.doorInnerShadowLayerIndex,
    opacity: 1,
    angle: 0,
    height: doorHeight,
    top: (doorTopPosition + frameThickness) + doorShapePositionTop,
    left: doorLeftPosition + doorWidth,
    width: frameThickness,
    backgroundColor: config.shadowBackgroundColor,
    shadow: {
      color: config.doorFrameInnerShadow.color,
      offsetX: 18,
      offsetY: 0,
      blur: 12,
    },
  });

  const doorFrameShadow = new fabric.Rect({
    groupClass: 'fullDoorGroup',
    name: 'doorFrameShadow',
    layerIndex: 120,
    left: doorLeftPosition,
    top: doorTopPosition + doorShapePositionTop,
    width: doorWidth + (frameThickness * 2),
    height: doorShapeHeight + frameThickness + doorBlendeHeight,
    opacity: config.doorFrameShadow.opacity,
    fill: config.doorFrameShadow.fill,
  });

  // Gradiens a doorFrameShadowhoz

  doorFrameShadow.setGradient('fill', {
    type: 'linear',
    x1: -doorFrameShadow.width + 100,
    y1: (-doorFrameShadow.width + 100) / 2,
    x2: doorFrameShadow.width,
    y2: 0,
    colorStops: {
      0: '#FFFFF9',
      1: '#000000',
    },
  });

  return [
    doorShape, doorShapeBlende,
    leftFrame, topFrame, rightFrame,
    doorFrameOuterShadowLeft, doorFrameOuterShadowTop, doorFrameOuterShadowRight,
    doorInnerShadowLeft, doorInnerShadowTop, doorInnerShadowRight, doorInnerShadowBottom,
    doorFrameShadow,
  ];
};

export default falcoltOsztottBlende;
